import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import "../../css/transaction.scss";
import "../../css/transaction_m.scss";

import { Select } from "antd";

const Option = Select.Option;

export default class CoinSelectBox extends Component {
  state = {
    dataType: this.props.codeType
  };

  renderOption = () => {
    const { type, coinCode, exceptERC, exceptCoinType } = this.props;
    let coinList = reactLocalStorage.getObject("coinList");

    let result = [];
    //지갑 모으기, 모으기 내역에서 리플 제거
    if (exceptCoinType) {
      coinList = coinList.filter(
        coin => !exceptCoinType.includes(coin.coinType)
      );
    }
    for (let i = 0; i < coinList.length; i++) {
      if (type) {
        for (let j = 0; j < coinCode.length; j++) {
          if (coinList[i].coinType === coinCode[j]) {
            result.push(
              <Option value={coinList[i].coinType} key={`${coinList[i]}${i}`}>
                {coinList[i].name}
              </Option>
            );
          }
        }
      } else if (exceptERC) {
        if (
          coinList[i].coinType !== 1 &&
          coinList[i].coinType !== 2 &&
          coinList[i].coinType !== 3
        )
          result.push(
            <Option value={coinList[i].coinType} key={`${coinList[i]}${i}`}>
              {coinList[i].name}
            </Option>
          );
      } else {
        result.push(
          <Option value={coinList[i].coinType} 
                  key={`${coinList[i]}${i}`}
                  className="selOption"
                  >
            <img className="selImg" src={coinList[i].image} />
            <span className="selName">{coinList[i].name}</span>
          </Option>
        );
      }
    }

    return result;
  };

  render() {
    const { size, mode, style, placeholder, value, onChange } = this.props;
    return (
      <Select
        className="selBox"
        {...this.props}
        size={size}
        mode={mode}
        style={{ width: "120px", ...style }}
        placeholder={placeholder}
        value={value}
        onChange={value => {
          onChange(value, "coinType");
        }}>
        {this.renderOption()}
      </Select>
    );
  }
}
