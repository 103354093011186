import React, { Component, useState  } from "react";

import { Form, Table, DatePicker, Input, Button, Pagination, Select } from "antd";

import "../css/signUp.scss";
import "../css/main.scss";
import "../css/transaction.scss";
import "../css/transaction_m.scss";
import { Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { reactLocalStorage } from "reactjs-localstorage";
import { httpGet, httpUrl, httpPost } from "../api/httpClient";
import { formatDate, startDateFormat, endDateFormat, amountFormat } from "../util/util";
import CoinSelectBox from "../components/common/CoinSelectBox";
import {Modal} from "../components/common/Modal";
import  MyTransaction from './MyTransaction';
import { value } from "../res/string_en";
import Language from '../lib/util/language';
import Const from '../const';
import { withAlert } from 'react-alert'
import Coin from '../lib/coin/coin';

const Search = Input.Search;
const { RangePicker } = DatePicker;

class Transaction extends Component {
  ormRef = React.createRef();

  constructor(props) {
    super(props);
    this.string = Language.getString();
    this.state = {
      fromCoinType: '',
      toCoinType: '',
      status: '',
      exchangeList: [],
      pagination: {
        total: 0,
        current: 1
      },
      myTransaction : 0,

      modalOpen: 0,
      fromRegCoinType: '',
      toRegCoinType: '',
      selCoinAmount: '',
      getCoinAmount: '',
      exchangeFee: 0,
      exchangeCancelFee: 0,
      feeAmount: 0,

      //test
      // modalOpen: 4,
      // fromRegCoinType: 101,
      // toRegCoinType: 102,
      // selCoinAmount: 5,
      // getCoinAmount: 2,
      // exchangeFee: 0.01,
      // exchangeCancelFee: 0.02,
      // feeAmount: 1,

    };
  }

  componentDidMount() {
    this.getExchangeList();
    httpGet(httpUrl.exchangeSetting, [])
      .then(result => {
        this.setState({exchangeFee: result.data.fee, exchangeCancelFee: result.data.cancelFee})
      })
      .catch();
  }
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    }, () => this.getExchangeList());
  };

  handlePageChange = (page, pageSize) => {
    const pager = { ...this.state.pagination };
    pager.current = page;
    pager.total = pageSize;
    this.setState({
      pagination: pager,
    }, () => this.getExchangeList());
  };


  initialLoad = () => {
    this.setState({
      exchangeList: [],
      fromCoinType: '',
      toCoinType: '',
      fromRegCoinType: '',
      toRegCoinType: '',
      status: '',
      pagination: {
        total: 0,
        current: 1
      },
      myTransaction: 0,
      selCoinAmount: '',
      getCoinAmount: '',
      exchangeFee: 0,
      exchangeCancelFee: 0,
      feeAmount: 0,
    }, () => {
      this.getExchangeList();
    })
  }
  reload = () => {
    this.getExchangeList();
  }
  getExchangeList = () => {
    let pageNum = this.state.pagination.current;
    let { fromCoinType, toCoinType, status } = this.state;
    httpGet(httpUrl.exchangeList, [10, pageNum, fromCoinType, toCoinType, status], {}).then((res) => {
      const pagination = { ...this.state.pagination };
      pagination.current = res.data.currentPage;
      pagination.total = res.data.totalCount;
      // console.log(res.data.exchanges);
      this.setState({
        exchangeList: res.data.exchanges,
        pagination,
      });
    });
  };

  registExchange = () => {
    httpPost(httpUrl.exchangeRegist, [], {
      fromCoinType: this.state.fromRegCoinType,
      fromAmount: this.state.selCoinAmount*Coin.getCoinUnit(this.state.fromRegCoinType).base,
      toCoinType: this.state.toRegCoinType,
      toAmount: this.state.getCoinAmount*Coin.getCoinUnit(this.state.toRegCoinType).base,
    }).then((result)=>{

      if (result.data == "SUCCESS") {
        this.closeModal();
        this.initialLoad();
      }
      else if (result.data == "NO_BALANCE") this.props.alert.show(this.string.exchangeRegistNoBalance);
      else this.props.alert.show(this.string.exchangeRegistFail);

    });
  }
  applyExchange = () => {
    this.closeModal();
    httpPost(httpUrl.exchangeApply, [this.selectedIdxForApply], {}).then((result) => {
      if (result.data == "SUCCESS") {
        this.initialLoad();
      }
      else if (result.data == "NO_BALANCE") this.props.alert.show(this.string.exchangeApplyNoBalance);
      else if (result.data == "SELF") this.props.alert.show(this.string.exchangeSelfFail);
      else if (result.data == "INVALID") this.props.alert.show(this.string.exchangeInvaild);
      else this.props.alert.show(this.string.exchangeRegistFail);
    }).catch(() => {
    });
  }
  cancelExchange = () => {
    this.closeModal();
    httpPost(httpUrl.exchangeCancel, [this.selectedIdxForCancel], {}).then((result) => {
      if(result.data) {
        this.initialLoad();
      }
      else this.props.alert.show(this.string.serverFail)
    }).catch(() => {
    });
  }
  changeMyTransaction = () => {
    this.setState({ myTransaction: 0 });
  } 

  customAlert = () => {
    this.props.customAlert()
  }

  openTransaction = (idx) => {
    this.selectedIdxForApply = idx;
    this.setState({ modalOpen: 1 })
  }

  openCancel = (idx) => {
    this.selectedIdxForCancel = idx;
    this.setState({ modalOpen: 2 })
  }

  openSelReg = () => {
    if (!this.props.loginInfo.idx) {
      this.props.alert.show(this.string.needLogin2);
      return;
    }
    this.setState({ modalOpen: 3,
      selCoinAmount: '',
      getCoinAmount: ''})
  }

  openSelRegDetail = () => {
    if (this.state.fromRegCoinType == '' || this.state.toRegCoinType == '') {
      this.props.alert.show(this.string.selectCoin);
      return;
    }
    if (this.state.fromRegCoinType == this.state.toRegCoinType) {
      this.props.alert.show(this.string.exchangeSameCoinFail);
      return;
    }
    if (this.state.selCoinAmount == null || this.state.selCoinAmount == '' || this.state.getCoinAmount == null || this.state.getCoinAmount == '') {
      this.props.alert.show(this.string.exchangeCoinInputAmount);
      return;
    }

    this.setState({ modalOpen: 4 })
  }

  closeModal = () => {
    this.setState({ modalOpen: 0 })
  }


  render() {
    const string = this.string;
    const { exchangeList } = this.state;
    let statusString = {
      PENDING: string.exchangeStatusPending,
      CANCEL: string.exchangeStatusCancel,
      MATCH: string.exchangeStatusMatch,
    };
    const exchangeStatus = [
      {code: '', name: string.viewAll},
      {code: 'PENDING', name: string.exchangeStatusPending},
      {code: 'MATCH', name: string.exchangeStatusMatch},
      {code: 'CANCEL', name: string.exchangeStatusCancel},
    ]
    const { length: count } = this.state.exchangeList;

    return (
        <div className="transactionContent">
          {this.state.myTransaction == 0 && (
            <>
          {/* <div className="headerPart">
            <span className="tranListText">거래 목록</span>
            <img className="underArrowTransaction" src={require('../img/transaction/underArrow.png')}/>
          </div> */}
              <div className="bodyPart">
              <div className="leftMenu">
                <div className="leftTabBox">
                <p className="leftTabText">{string.exchangeStatus}</p>
                <Select
                  className="leftSelectBox"
                  placeholder={string.viewAll}
                  onChange={(value) => {
                    this.setState({ status: value });
                  }}
                >
                {exchangeStatus.map(data => {
                  return (
                    <option className="selName" value={data.code}>{data.name}</option>
                  );
                })}
                </Select>
                </div>
                <div className="leftTabBox">
                <p className="leftTabText">{string.exchangeSendValue}</p>
                <CoinSelectBox
                      className="leftSelectBox"
                      placeholder={string.viewAll}
                      onChange={(value) => {
                      this.setState({ fromCoinType: value });
                      }}
                      value={this.state.fromCoinType == '' ? undefined : this.state.fromCoinType}
                      style={{ width: "160px" }}
                />
                </div>
                <div className="leftTabBox">
                <p className="leftTabText">{string.exchangeBuyCoin}</p>
                <CoinSelectBox
                      className="leftSelectBox"
                      placeholder={string.viewAll}
                      onChange={(value) => {
                      this.setState({ toCoinType: value });
                      }}
                      value={this.state.toCoinType == '' ? undefined : this.state.toCoinType}
                      style={{ width: "160px" }}
                />
                </div>
                <div className="mainColorBtn" onClick={()=>this.reload()}>
                  {string.search}
                </div>
              </div>
              <div className="rightContent">
                <div className="btnTab">
                  <div className="mainColorBtnLarge" style={{ marginRight: '10px'}}
                      onClick={() => {
                        // this.customAlert();
                        this.openSelReg();
                      }}
                  > 
                    {string.exchangeRegist}  {/* 판매등록 */}
                  </div>
                  <div className="grayColorBtnLarge"
                      onClick={() => {
                        if (!this.props.loginInfo.idx) {
                          this.props.alert.show(this.string.needLogin2);
                          return;
                        }
                        this.setState({ myTransaction: 1 });
                      }}
                  >
                    {string.exchangeMyList}  {/* 나의내역 */}
                  </div>
                </div>                                                                                                                                                                                                                                                                         
                <div className="tableTr">
                  <div className="innerLine"></div>
                  <div className="trContent">
                    <div className="selCoinText">{string.exchangeSendValue}</div>
                    <div className="buyCoinText">{string.exchangeReceiveValue}</div>
                    <div className="tcText">{string.exchangeStatus}</div>
                  </div>
                  <div className="innerLine"></div>
                </div>
                <div className="tableTd">
                  {this.state.exchangeList.map((value, index) => {
                    let fromCoinInfo = this.props.coinList.find(x => x.coinType == value.fromCoinType);
                    let toCoinInfo = this.props.coinList.find(x => x.coinType == value.toCoinType);
                    return (
                      <>              
                        <div className="transactionDetailBox">
                          <div className="transcatioin-container">
                            <div className="sellCoinContainer">
                              <div className="symbolWrapper">
                                <img className="coinImage" src={this.props.coinList.find((coin) => coin.coinType === value.fromCoinType).image}/>
                              </div>
                              <div className="coinBox">
                                <p className="coinName">
                                  {this.props.coinList.find((coin) => coin.coinType === value.fromCoinType).name}
                                </p>
                                <p className="coinAmount">
                                  {amountFormat(value.fromAmount, value.fromCoinType)}
                                  <span className="coinSymbol">
                                  {this.props.coinList.find((coin) => coin.coinType === value.fromCoinType).symbol}
                                </span>
                                </p>
                         
                                <p className="coinKo">
                                  ({amountFormat(fromCoinInfo.price * value.fromAmount, value.fromCoinType)}{string.localCoin})
                                </p>
                              </div>
                            </div>

                            <div className="arrowBox">
                              <img className="arrow" src={require('../img/transaction/transactionDetailArrow.png')}/>
                            </div>

                            <div className="sellCoinContainer">
                              <div className="symbolWrapper">
                                <img className="coinImage" src={this.props.coinList.find((coin) => coin.coinType === value.toCoinType).image}/>
                              </div>
                              <div className="coinBox">
                                <p className="coinName">
                                {this.props.coinList.find((coin) => coin.coinType === value.toCoinType).name}
                                </p>
                                <p className="coinAmount">
                                  {amountFormat(value.toAmount, value.toCoinType)}
                                  <span className="coinSymbol">
                                  {this.props.coinList.find((coin) => coin.coinType === value.toCoinType).symbol}
                                  </span>
                                </p>                               
                                <p className="coinKo">
                                  ({amountFormat(toCoinInfo.price * value.toAmount, value.toCoinType)}{string.localCoin})
                                </p>
                              </div>
                            </div>
                          </div>   
                          <div className="btn-container"> 
                            {value.status == "PENDING" && this.props.loginInfo.idx == value.fromUserIdx && (
                                <div className="doCancelTransactionBox"
                                    onClick={() => {
                                      this.openCancel(value.idx)
                                      // this.customAlert();
                                      // this.cancelExchange(value.idx, amountFormat(value.fromAmount, value.fromCoinType), fromCoinInfo.symbol);
                                    }}>
                                    {string.doCancel}
                                </div>
                            )}
                            {value.status == "PENDING" && this.props.loginInfo.idx != value.fromUserIdx && (                          
                                <div className="doTransactionBox"
                                    onClick={() => {
                                      // this.customAlert();
                                      if (!this.props.loginInfo.idx) {
                                        this.props.alert.show(this.string.needLogin2);
                                        return;
                                      }
                                      this.openTransaction(value.idx);
                                    }}>
                                      {string.exchangeCoinConfirmDlgTtl}
                                </div>                             
                            )}
                            {value.status == "MATCH" && (
                              <div className="completeTransactionBox">{string.exchangeStatusMatch}</div>
                            )}
    
                            {value.status == "CANCEL" && (
                              <div className="cancelTransactionBox">{string.exchangeStatusCancel}</div>
                            )}
                          </div>                    
                        </div>

                        <div className="registData">
                              <p className="registDate">  {string.registDate} : {formatDate(value.createDate)}</p>
                              <p className="registUser">  {string.register} : {value.fromUserId}</p>
                          </div>
                        {/* 모달창 - 종류는 state값으로 modalOpen */}

                        <Modal open={ this.state.modalOpen == 1 || this.state.modalOpen == 2 
                                || this.state.modalOpen == 3 || this.state.modalOpen == 4} 
                                close={ this.closeModal }>
                                  {this.state.modalOpen == 1 && (
                                    <div className="doTransaction">
                                      <div><img className="exMark" src={require('../img/transaction/exMark.png')}/></div>
                                      <div className="warnText">{string.exchangeApplyMessage}</div>
                                      <div className="buyConfirmBtn">  
                                        <div className="grayColorBtnLarge" style={{margin: '0px 20px'}} onClick={this.closeModal}>{string.cancel}</div>
                                        <div className="mainColorBtnLarge" style={{margin: '0px 20px'}} onClick={()=>this.applyExchange()}>{string.ok}</div>
                                      </div>
                                    </div>
                                  )}
                                  {this.state.modalOpen == 2 && (
                                    <div className="doTransaction">
                                      <img className="exMark" src={require('../img/transaction/exMark.png')}/><br></br>
                                      <span className="warnText">{string.exchangeCancelMessage}</span>
                                      <div className="buyConfirmBtn">  
                                        <div className="grayColorBtnLarge" style={{margin: '0px 20px'}} onClick={this.closeModal}>{string.cancel}</div>
                                        <div className="mainColorBtnLarge" style={{margin: '0px 20px'}} onClick={()=>this.cancelExchange()}>{string.ok}</div>
                                      </div>
                                    </div>
                                  )}
                                  {this.state.modalOpen == 3 && (
                                      <div className="selCoinBox">  
                                      <div className="regCelCoinTextBox">
                                       {string.exchangeSendValue}
                                      </div>
                                      <div>
                                        <CoinSelectBox
                                              className="coinSelBox"
                                              placeholder={string.viewAll}
                                              onChange={(value) => {
                                              this.setState({ fromRegCoinType: value });
                                              }}
                                              value={this.state.fromRegCoinType == '' ? undefined : this.state.fromRegCoinType}
                                        />
              
                                        
                                        <Input className="selCoinAmount"
                                            name="selCA"
                                            placeholder={string.enterAmount}
                                            onChange={(e)=>{
                                              this.setState({selCoinAmount: e.target.value,
                                                feeAmount: e.target.value * this.state.exchangeFee,
                                              })
                                            }}
                                            value={this.state.selCoinAmount}
                                        />
                                    </div>
                                      <div className="regCelCoinTextBox">
                                        {string.exchangeReceiveValue}
                                      </div> 
                                      <CoinSelectBox
                                            className="coinSelBox"
                                            placeholder={string.viewAll}
                                            onChange={(value) => {
                                            this.setState({ toRegCoinType: value });
                                            }}
                                            value={this.state.toRegCoinType == '' ? undefined : this.state.toRegCoinType}
                                      />
                                    
                                      <Input className="getCoinAmount"
                                          name="getCA"
                                          placeholder={string.enterAmount}
                                          onChange={(e)=>{
                                            this.setState({getCoinAmount: e.target.value})
                                          }}
                                          value={this.state.getCoinAmount}
                                      />
                                  
                                      <span className="coinRegRuleText">
                                      {string.exchangePolicy01}({this.state.exchangeFee*100}%)<br></br>
                                      {string.exchangePolicy02}({this.state.exchangeCancelFee*100}%)<br></br>
                                      {string.exchangePolicy03}</span>
                                      <div className="regSelBtnDiv" onClick={this.openSelRegDetail}> 
                                          {string.exchangeRegist}
                                      </div>
                                    </div>
                                  )}

                                  {this.state.modalOpen == 4 && (
                                    <>
                                    <button className="backBtn2" onClick={this.openSelReg}></button>
                                    <div className="selRegDetail">
                                      <img className="exMark" src={require('../img/transaction/exMark.png')}/>
                                      <div className="warnText">{string.exchangeCoinConfirmMessage}</div>
                                      <div className="coinTrans">
                                          <div className="selCoinTrans">
                                            <span className="coinTransText">{string.exchangeSendValue}</span><br></br>
                                            <div className="selDiv">
                                              <img className="transDetailImage" src={this.props.coinList.find((coin) => coin.coinType === this.state.fromRegCoinType).image}/>
                                              <div className="transDetailKind">
                                                <div><span className="transDetailName">{this.props.coinList.find((coin) => coin.coinType === this.state.fromRegCoinType).name}</span></div>
                                                <span className="transDetailSymbol">{this.props.coinList.find((coin) => coin.coinType === this.state.fromRegCoinType).symbol}</span>
                                              </div>
                                              <span className="transDetailCost">{this.state.selCoinAmount}</span>
                                            </div>
                                          </div>
                                          <div className="getCoinTrans">
                                            <span className="coinTransText">{string.exchangeReceiveValue}</span>
                                            <div className="getDiv">
                                              <img className="transDetailImage" src={this.props.coinList.find((coin) => coin.coinType === this.state.toRegCoinType).image}/>
                                              <div className="transDetailKind">
                                                <div><span className="transDetailName">{this.props.coinList.find((coin) => coin.coinType === this.state.toRegCoinType).name}</span></div>
                                                <span className="transDetailSymbol">{this.props.coinList.find((coin) => coin.coinType === this.state.toRegCoinType).symbol}</span>
                                              </div>
                                              <span className="transDetailCost">{this.state.getCoinAmount}</span>
                                            </div>
                                          </div>
                                      </div>
                                      <div className="showFeeAmount">
                                        {string.fee}&nbsp;&nbsp;
                                        {this.state.selCoinAmount * this.state.exchangeFee}&nbsp;
                                        {this.props.coinList.find((coin) => coin.coinType === this.state.fromRegCoinType).symbol}
                                      </div>
                                      <div className="warnText">
                                        {string.exchangeCoinConfirmMessageWarn}
                                      </div>

                                      <div className="selRegConfirmBtn">  
                                        <div className="grayColorBtnLarge" onClick={this.closeModal}>{string.cancel}</div>
                                        <div className="mainColorBtnLarge" onClick={this.registExchange}>{string.ok}</div>
                                      </div>
                                    </div>
                                    </>
                                  )}
                                </Modal>
                    </>

                    )
                  })}
                  
                </div>
              </div>
              <Pagination
              onChange={this.handlePageChange}
              defaultCurrent={1}
              pageSize={10}
              total={this.state.pagination.total} />
            </div>
            </>
          ) ||
          (
            <MyTransaction changeMyTransaction={this.changeMyTransaction}
                          customAlert={this.customAlert} />
          )}
          
        </div>
    );
  }
}

const mapStateToProps = state => {
  const { coinList, loginInfo } = state;
  return { coinList, loginInfo };
};


export default withAlert()(withRouter(connect(mapStateToProps, null)(Transaction)));