import React, { Component } from 'react';
import { httpGet, httpUrl } from '../api/httpClient';
import Language from '../lib/util/language';
import { Form, Table, DatePicker, Input, Button, Pagination, Modal } from "antd";

class Notice extends Component {
  constructor(props) {
    super(props);
    this.string = Language.getString();

    this.state = {
      tableData: [],
      noticeNo: this.props.noticeNo,
      pagination: {
        total: 0,
        current: 1
      },
    };
  }

  componentDidMount() {
    this.getNoticeList();
  }
  getNoticeList = () => {
    httpGet(httpUrl.noticeList, [10, this.state.pagination.current, 'en'])
      .then(result => {
        result = result.data;
        const pagination = { ...this.state.pagination };
        pagination.current = result.currentPage;
        pagination.total = result.totalCount;
        this.setState({
          tableData: result.noticeList,
          pagination,
        });
      })
      .catch();
  };

  toggleNotice = idx => {
    if (this.state.noticeNo == idx) this.setState({ noticeNo: 0 });
    else this.setState({ noticeNo: idx });
  };
  handlePageChange = (page, pageSize) => {
    const pager = { ...this.state.pagination };
    pager.current = page;
    this.setState({
      pagination: pager,
    }, () => this.getNoticeList());
  };

  render() {
    const string = this.string;
    return (
      <div className="currency-content">
          <div className="currency-top-title">
              {string.myinfoNotice}
          </div>
          <div className="divider"></div>
          <div className="">
            
          {this.state.tableData.length > 0 ? (
            this.state.tableData.map((rowData, index) => {
              let categoryText = string.noticeCategory[rowData.category];
              return (
                <div className="notice-item-container" onClick={()=>this.setState({noticeNo: rowData.idx})}>
                  <div className="notice-item-title">
                    {rowData.title}
                  </div>
                  <div className="notice-item-sub">
                    <div className="notice-item-category">
                        {categoryText}
                    </div>
                    <div className="notice-item-date">
                      {rowData.createDate.substr(0, 10)}
                    </div>
                  </div>

                  {this.state.noticeNo == rowData.idx && (
                    <div className="notice-item-content" dangerouslySetInnerHTML={ {__html: rowData.content} }/>
                  )}

                </div>
              );
            })) : (
              <div></div>
            )}
            <div className="pagination">
            <Pagination
              onChange={this.handlePageChange}
              defaultCurrent={1}
              pageSize={10}
              total={this.state.pagination.total} />
            </div>
          </div>

      </div>
    );
  }
}

export default Notice;
