import Axios from "axios";
import util from "util";
import Const from "../const";
import { reactLocalStorage } from "reactjs-localstorage";
let loadingCount = 0;

global.language = "ko";
global.lanList = ["ko", "en", "ja", "zh"];

const serverUrl =
  Const.serverProtocol + "://" + Const.serverIp + ":" + Const.serverPort;

const makeUrl = (url, params) => {
  console.log("url :"+url);
  console.log("params :"+params);
  console.log("params len :"+params.length);
  var result = serverUrl + url;
  if (params === null) return result;
  params.forEach((param) => {
    result = util.format(result, param);
  });
  return result;
};

const httpExec = (method, url, data) => {
  loadingCount++;
  if (loadingCount === 1)
    global.document.getElementById("loadingSpinner").style.display = "block";

  return new Promise((resolve, reject) => {
    Axios({
      method: method,
      url: url,
      data: data,
      withCredentials: true,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        resolve(response.data);
        loadingCount--;
        if (loadingCount === 0)
          global.document.getElementById("loadingSpinner").style.display =
            "none";
      })
      .catch((error) => {
        // console.log(JSON.stringify(error, null, 4));
        if (error.message.includes("401")) {
          alert("Login required.");
          reactLocalStorage.remove(Const.appCode + "#userinfo");
          global.location.href = "/";
        }
        reject(error);
        loadingCount--;
        /* if (loadingCount === 0)
          global.document.getElementById("loadingSpinner").style.display =
            "none"; */
      });
  });
};

const httpGet = (url, params, data) => {
  return httpExec("GET", makeUrl(url, params), data);
};

const httpPut = (url, params, data) => {
  return httpExec("PUT", makeUrl(url, params), data);
};

const httpPost = (url, params, data) => {
  return httpExec("POST", makeUrl(url, params), data);
};

const httpDownload = (url, params, data) => {
  return new Promise((resolve, reject) => {
    Axios({
      method: "GET",
      url: makeUrl(url, params),
      data: data,
      withCredentials: true,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      responseType: "arraybuffer",
    })
      .then((response) => {
        var blob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        resolve(blob);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const httpUrl = {
  serviceStatus: '/system/status',
  systemListBlockchain: '/system/list/blockchain?language=%s',

  signUp: '/user/signup',

  login: '/login',
  logout: '/logout',
  
  getStakingSettings: '/staking/setting',
  stake: '/staking/stake',
  unstake: '/staking/unstake',
  getStakingBalance: '/staking/balance',

  globalSetting: '/system/setting',
  balance: '/wallet/balance/%s',
  walletSend: '/wallet/send',
  broadcastTx: '/broadcastTx',

  transLog: '/wallet/history/transfer?pageSize=%s&pageNum=%s&coinType=%s',
  exchangeLog: '/wallet/trade/log?pageSize=%s&pageNum=%s&coinType=%s',
  payLog: '/franchise/select/purchase/log?pageSize=%s&pageNum=%s&coinType=%s',
  txInput: '/txInput/%s?p1=%s',
  myCoinList: '/myCoinList',
  addCoin: '/wallet/add/coin',
  removeCoin: '/removeCoin/%s',
  coinFee: '/coin/coin/fee/%s',
  serverWallet: '/serverwallet',

  foundation: '/system/list/foundation/%s',

  trade: '/wallet/trade',
  walletTradeHistory: '/wallet/trade/history?pageSize=%s&pageNum=%s',

  createSerial: '/serial/create',
  recoverSerial: '/serial/recover',

  currencies: '/coin/tickers',
  currenciesMin: '/coin/tickers/min',

  noticeList: '/notice/list?pageSize=%s&pageNum=%s&language=%s',
  noticeMainList: '/notice/main',
  noticeAllList: '/notice/all',

  eventList: '/event/list?pageSize=%s&pageNum=%s&language=en',
  myEventList: '/event/mylist?pageSize=%s&pageNum=%s',

  exchangeSetting:'/exchange/setting',
  exchangeList:'/exchange/list?pageSize=%s&pageNum=%s&fromCoinType=%s&toCoinType=%s&status=%s',
  exchangeRegist:'/exchange/regist',
  exchangeCancel:'/exchange/cancel/%s',
  exchangeApply:'/exchange/apply/%s',
  exchangeMyList:'/exchange/mylist?pageSize=%s&pageNum=%s&actionType=%s',

  faqList: '/faq/list?pageSize=%s&pageNum=%s&language=%s',

  userFindId: '/user/find/id',
  userFindPassword: '/user/find/password',
  changePassword: '/user/change/password',
  findSecPassword: '/user/find/securitypassword',
  changeSecPassword: '/user/change/security/password',

  walletOtpGetKey: '/wallet/otp/get/key',
  walletOtpVerify: '/wallet/otp/verified?key=%s',
  walletOtpChangeKey: '/wallet/otp/change/key',

  pushSetting: '/push/flag/%s',

  linkDAppList: '/link/dapp/list?language=%s',

  userSelectAddressbook: '/user/select/addressbook?pageSize=%s&pageNum=%s&coinType=%s',
  userInsertAddressbook: '/user/insert/addressbook',
  userUpdateAddressbook: '/user/update/addressbook',
  userDeleteAddressbook: '/user/delete/addressbook',

  currencyExchangeRate: '/currency/exchageRate',

  // userPhoneauthGenerate: '/user/phoneauth/generate/%s',
  // userPhoneauthCheck: '/user/phoneauth/check',
  userPhoneauthGenerate: '/sms/dispatch',
  userPhoneauthCheck: '/sms/verify?token=%s&code=%s',

  userResendEmailSignup: '/user/resend/email/signup/%s',
  userCheckEmail: '/user/check/email/%s',
  userFindPassword :'/user/password/modify/email/%s',
  userFindId:'/user/find/id2',

  walletCheckFee: '/wallet/check/fee2?coinType=%s&address=%s',
  userUpdateNickname: '/user/update/nick/%s',
  userToggleHidebalance: '/user/toggle/hidebalance',

  franchisePurchase : '/franchise/purchase',
  userUpdateBank: '/user/update/bank',

  userCheckRecommender: '/user/check/recommender/%s',
  franchisePurchaseOffline: '/franchise/purchase/offline',

  franchiseLastPaymentList: '/franchise/select/purchase/franchise/log?pageSize=5&pageNum=1&coinType=%s',
  franchiseCancelPayment: '/franchise/purchase/cancle',

  franchisePurchaseOnline: "/franchise/purchase/online",

  depositBank: '/deposit/bank',
  depositRequest: '/deposit/request',
  depositHist: '/deposit/hist',
};

const imageType = ["image/jpeg", "image/png", "image/bmp"];

export {
  serverUrl,
  httpExec,
  makeUrl,
  httpGet,
  httpUrl,
  httpPut,
  httpPost,
  httpDownload,
  imageType,
};
