export default {

  appTitle: 'OTCマーケット',
  /*********メニュー**********/
  menuCurrency: '相場',
  menuMywallet: 'マイウォレット',
  menuEvent: 'イベント',
  menuTrade: '取引',
  menuTradeSend: '送る',
  menuTradeInfo: '取引内訳',
  menuExchange: '交換',
  menuPayment: '決済',
  menuBuyback: 'バイバック',
  menuDApp: 'DApp',
  menuMyInfo: '私の情報',
  menuExchangeInfo: '交換内訳',

  /********* 加入・ログイン **********/
  login: 'ウォレットに入る',
  autoLogin: '自動ログイン',
  saveLoginId: 'ID保存',
  findId: 'IDを探す',
  findPassword: 'パスワードを探す',
  findIdPassword: 'ID・PWを探す',
  signUp: '会員加入',
  phone: '携帯電話番号',
  inputPhone: '電話番号入力',
  certiCode: '認証番号',
  sendCertiCode: '認証番号発送',
  checkCertiCode: '認証番号確認',
  inputCertiCode: '認証番号入力',
  inputRecommendCode: '推薦人コード入力 (選択)',
  // inputRecommendCodeComment: '推薦人を入力すると200 UUTTエアドロップ',

  mnemonicTitle: 'ニーモニックコードを得ることは、ウォレットの資産所有権を持つこと同じです。',
  mnemonicContent1: '万一貴殿の携帯電話、ID、パスワードを紛失した場合、ニーモニックコードは貴殿のウォレット資産を復旧させることができます。',
  mnemonicContent2: '紙とペンを使ってニーモニックコードを書き取ります。',
  mnemonicContent3: '安全なところにちゃんと保管します。',
  mnemonicContent4: 'ニーモニックコードをメール、写真アルバム、SNS等のオンライン環境に保存·共有しないでください。',
  readAgreement: '上記事項をちゃんと読みました。',
  readViewAgreement: '内容を確認された場合、確認チェックをお願いします。',

  signUpErrorTitle: 'ウォレット生成失敗',
  signUpErrorIdDuplication: '既に登録されているeメールです。 eメールを確認してください。',
  signUpErrorInvalidEmailType: 'eメール形式が正しくありません。 もう一度ご確認ください。',
  signUpErrorMaxPhone: '電話番号当たりの登録可能アカウント数を超過しました。',
  signUpErrorMessage: 'ウォレット生成に失敗しました。 しばらくしてからもう一度お試しください。',
  mnemonicDescription: 'ニーモニックコードを得ることは、ウォレットの資産所有権を持つこと同じです。',
  mnemonicDescriptionDetail: [
    'ID、パスワードを紛失した場合は、ニーモニックコードを利用してウォレット資産が復旧させることができます。',
    '紙とペンを使ってニーモニックコードを作成してください。',
    'ニーモニックコードは安全な場所に保管してください。',
    'ニーモニックコードをメール、写真アルバム、SNS等のオンライン環境に保存·共有しないでください。',
  ],
  iAgree: '上の事項をちゃんと読みました',
  captureProhibited: 'キャプチャ禁止',
  captureProhibitedDescription: [
    'キャプチャーして共有・保存しないでください。',
    '第三者の悪意あるアプローチにより、資産の損失を招きかねません。',
  ],
  welcomeToWallet: 'OTCマーケットへようこそ、歓迎します。',
  getStarted: 'すぐ始める',
  findPasswordEmailSent: 'パスワード変更のためのeメールが送信されました。 eメールでリンクをクリックしてパスワードを変更してください。',

  /*********MNEMONIC**********/
  mnemonicGuideFindIdPw: [
    // 'ウォレット生成時に発行された復旧単語を順番通りに入力してください。',
    '加入時に登録した携帯電話番号で認証を進めてください。',
    'ID検索が完了しました。 パスワード変更が必要な場合、下にパスワードを入力してください。',
    'パスワード変更が完了しました。ログインを進めてください。',
  ],
  mnemonicGuideFindSecPw: [
    // 'ウォレット生成時に発行された復旧単語を順番通り入力してください。',
    '加入時に登録した携帯電話番号で認証を進めてください。',
    '新しいセキュリティパスワードを入力してください。',
    'セキュリティパスワード変更が完了しました。',
  ],
  mnemonicGuideChangePw: [
    '現在のパスワードと新しいパスワードを入力してください。',
    'パスワード変更が完了しました。',
  ],

  mnemonicGuideChangeSecPw: [
    '現在のセキュリティパスワード4桁と新しいセキュリティパスワード4桁を入力してください。',
    'セキュリティパスワード変更が完了しました。',
  ],
  mnemonicGuideChangeOtp: [
    //'ウォレット生成時に発行された復旧単語を順番通り入力してください。',
    '加入時に登録した携帯電話番号で認証を進めてください。',
  ],
  mnemonicError: 'ID探しに失敗しました。',
  mnemonicErrorWrongSms: '文字認証に失敗しました。',
  mnemonicErrorWrongUser: '該当する利用者がいません。',
  mnemonicErrorWrongMnemonic: '認証情報が正しくありません。',
  mnemonicErrorFailFindSecPassword: 'セキュリティパスワード探しに失敗しました。',
  changeOtpFailed: 'OTP変更に失敗しました。',

  /*********共通**********/
  unquoted: '非上場',
  address: 'アドレス',
  destinationTag: 'DestinationTag',
  destinationTagShort: 'Dest.Tag',
  error: 'エラー',
  errorMessage: 'エラーが発生しました。 しばらくしてからもう一度お試しください。',
  coin: 'コイン',
  myCoin: '保有コイン',
  coinType: 'コイン種類',
  kind: '種',
  value: '金額',
  myValue: '保有残高',
  totalValue: '現在の総額',
  fee: '手数料',
  localCoinUnit: ['ウォン', 'USD', 'CNY', 'JPY'],
  localCoin: 'ウォン',
  localCoinBig: '兆',
  faster: '早い',
  normal: '普通',
  prev: '以前',
  next: '次',
  time: '時間',
  type: '区分',
  target: '対象',
  targetAddress: '送るアドレス',
  sendAmount: '送る数量',
  exchangeAmount: '交換数量',
  send: '送る',
  sent: '出金',
  sending: '出金中',
  sendingAddress: '出金アドレス',
  receive: '受け取る',
  received: '入金',
  receiving: '入金中',
  receivedAddress: '入金アドレス',
  save: '保存',
  name: '名前',
  setting: '設定',
  security: 'セキュリティセンター',
  intro: '会社紹介',
  cs: 'カスタマーセンタ',
  submit: '提出',
  failed: '失敗',
  unuse: '未使用',
  use: '使用',
  ok: '確認',
  cancel: '取消',
  close: '閉じる',
  back: '戻る',
  copy: 'アドレスコピー',
  copy2: 'コピー',
  share: '共有',
  enterPrice: '金額入力',
  enterPriceTitle: '数量を入力する',
  max: '最大',
  reset: 'リセット',
  updated: '更新',
  default: '基本',
  paste: '貼り付け',

  captureQr: 'QR撮影',
  captureQrTitle: 'QRコードを撮影する',
  captureQrDetail: 'QRコードを撮影して、コインを送信する対象アドレスを設定することができます。 QRコードが正確に撮影できるようにカメラを動かしてください。 QRコードが認識されると自動的に撮影は終了します。',
  serverFail: 'リクエストに失敗しました。 しばらくしてからもう一度お試しください。',
  extraCount: '外%s件',
  email: 'eメール確認',
  email2: 'eメール',
  tradeCoin: 'コイン選択',
  needLogin: 'ログインが必要な画面です。',
  find: '探す',
  walletAddress: 'ウォレットアドレス',
  start: 'すぐ始める',
  inputOption: '選択事項',
  memo: 'メモ',
  otp: 'OTP',
  detail: '詳細情報',
  completed: '完了',
  oneMoreBackToExit: 'もう一度押すと終了します。',
  txId: 'TxID',

  /********* 国 **********/
  country: '国',
  korea: '大韓民国',
  korean: '韓国語',
  krw1: 'ウォン',
  krw2: 'ウォン貨',
  china: '中国',
  // chinese: '中国語',
  chinese: '中国語',
  cny1: '元',
  cny2: '中国元',
  japan: '日本',
  // japanese: '日本語',
  japanese: '日本語',
  jpy1: '円',
  jpy2: '円貨',
  usa: 'アメリカ',
  // english: '英語',
  english: '英語',
  usd1: 'ドル',
  usd2: 'ドル貨',
  eur1: 'ユーロ',
  eur2: 'ユーロ貨',
  // Vietnam: 'ベトナム語',
  Vietnam: 'ベトナム語',
  usd1: 'ドル',
  usd2: 'ドル貨',
  eur1: 'ユーロ',
  eur2: 'ユーロ貨',

  setLanguage: '言語設定',
  setCurrency: '通貨設定',

  /*********パスワード**********/
  password: 'パスワード',
  passwordConfirm: 'パスワード確認',
  inputPassword: 'ウォレットパスワード',
  inputPasswordConfirm: 'ウォレットパスワード確認',
  inputPasswordDetail: 'お持ちのウォレットのパスワードを入力してください。',
  inputPasswordRule: '英文、数字、特殊文字を組み合わせて8~15桁入力',
  inputCurrentPassword: '現在のウォレットのパスワード入力',
  inputNewPassword: '新しいウォレットのパスワード入力',
  inputNewPasswordConfirm: '新しいウォレットのパスワード確認',
  inputCurrentSecPassword: '現在のセキュリティパスワード入力(4桁)',
  inputNewSecPassword: '新しいセキュリティパスワード入力(4桁)',
  inputNewSecPasswordConfirm: '新しいセキュリティパスワード確認(4桁)',
  inputFingerDetail: 'スマートフォンに登録されている指紋を認証してください。',
  secPassword: 'セキュリティパスワード',
  shortTermSecPassword: 'セキュリティパスワード',
  inputSecPassword: 'セキュリティパスワード入力(4桁)',
  secPasswordConfirm: 'セキュリティパスワードの確認',
  inputSecPasswordConfirm: 'セキュリティパスワード確認(4桁)',
  inputOtpCode: 'OTP入力',
  loginFailedTitle: 'ログイン失敗',
  loginFailedWrongId: '正しくないIDです。',
  loginFailedWrongPassword: '正しくないパスワードです。',
  loginFailedExpiredId: '使用中止されたIDです。',
  loginFailedEmailConfirm: 'メール承認されていません。 eメール承認後ご利用ください。',
  loginFailedMessage: 'ログインに失敗しました。 しばらくしてからもう一度お試しください。',
  fingerCheckExplain1: '指紋使用登録',
  fingerCheckExplain2: '(次から指紋でログインします。)',
  usefingerAuth: '指紋認証使用',
  fingerAuth: '指紋認証',
  fingerRequire: '指紋を認識してください。',
  fingerFail: '指紋認証に失敗しました。',
  fingerNoData: '認証情報がありません。 もう一度ログインしてください。',
  setupOtp: 'OTP設定',
  changePassword: 'パスワード変更',
  changeSecPassword: 'セキュリティパスワード変更',
  findSecPassword: 'セキュリティパスワードを探す',
  setupOtpDetail: '次のキーを使ってGoogle OTP を設定してください。 コイン送金等の重要機能実行時にOTP認証が必要です。 確認を押すとOTPキーがコピーされます。',
  otpCopied: 'OTPキーがコピーされました。',
  copyOtpKey: 'OTPキーをコピーする。',
  otpAlreadySet: '既にOTPが設定されています。',
  invalidOtp: 'OTP情報が正しくありません。',
  changeOtp: 'OTP変更',
  changePasswordFailed: 'パスワード変更に失敗しました。',
  changePasswordFailedSamePassword: '既存パスワードと新しいパスワードが同じです。',
  changePasswordFailedInvalidPassword: 'パスワードが正しくありません。',
  changeSecPasswordFailed: 'セキュリティパスワード変更に失敗しました。',
  changeSecPasswordFailedSamePassword: '既存セキュリティパスワードと新しいセキュリティパスワードが同じです。',
  changeSecPasswordFailedInvalidPassword: 'セキュリティパスワードが正しくありません。',
  otpSetupDescription: 'OTPはウォレット出金時にセキュリティを強化する6桁の数字です。',
  downloadOtp1: 'アップストアでGoogle OTPをダウンロードしてください。',
  downloadOtp2: 'アップストアでAuthyをダウンロードしてください。',
  downloadOtp3: 'アップストアでGoogle OTP、またはAuthyをダウンロードしてください。',
  startOtpSetup: '認証開始',
  inputOtpCodeDescription: '認証コードを入力してください。',
  submit: '提出',
  otpSetupFinished: 'OTP設定が完了しました。',
  otpSetupFinishedDescription: '連動されたOTP変更後72時間は、出金が禁止されます。',
  otpSetupFinishedDescription1: '連動されたOTP変更後',
  otpSetupFinishedDescription2: '72時間',
  otpSetupFinishedDescription3: 'は出金禁止されます。',

  /*********タギング**********/
  requestTagging: '',
  requestTaggingDetail: '',
  nfcOffMessage1: '',
  nfcOffMessage2: '',
  nfcNotSupported: '',

  /*********お知らせ**********/
  // noticeCategory: ['', 'お知らせ', 'イベント', 'ニュース'],
  noticeCategory: { NOTE: 'お知らせ', EVNT: 'イベント', NEWS: 'ニュース' },

  /*********相場**********/
  changePercent: '騰落',
  totalAmountValue: '時価総額',
  criteriaCoinmarketcap: '(コインマーケットキャップ基準)',
  whitePaper: '白書',
  website: 'ウェブサイト',
  community: 'コミュニティ',
  country: '国',
  preparing: '内容を準備しております。',

  /*********マイウォレット**********/
  mywalletNoCoins: '保有しているコインがありません。',
  mywalletRemoveCoin: '選択したコインを未使用に変更しますか。',
  mywalletAddressCopied: 'アドレスがコピーされました。',
  mywalletReceive: '次のアドレスでコインを受け取ることができます。アドレスをコピーするかQRコードを撮影してください。',
  mywalletBlbSend: '送る',
  mywalletBlbSendDetail: 'ブラップスコインは出金できません。コインが使えるサービスを準備しています。詳細については、今後お知らせにてご確認いただけます。',
  mywalletPossesionCoinCheck: '保有しているコインのみ見る。',
  mywalletCausion: 'このアドレスのコインと同じコインのみ入金できます。 誤ってこのアドレスに他のコインを送る場合、コインを見つけられません。',
  mywalletCausionBtc: 'ビットコイン(BTC)、ビットコインキャッシュ(BCH)、ビットコインゴールド(BCG)はそれぞれ異なる暗号通貨であるため、出金時には十分ご注意ください。',
  mywalletCausionEth: 'イーサリアム(ETH)、イーサリアムクラシック(ETC)はそれぞれ異なる暗号通貨であるため、 入出金時には十分ご注意ください。',
  mywalletCausionXrp: 'リップルはコインの特性上、ウォレットに20XRP以上を保有している場合のみ出金できて、常に残高を20XRP以上に維持しなければなりません。',
  transactionInfo: '取引内訳',
  inputAddress: 'アドレス入力',
  inputAmount: '数量入力',
  depositCompleted: '入金完了',
  outerWallet: '外部ウォレット',
  memoOption: 'メモ(選択)',
  qrNoCoin: 'QRにコイン情報が含まれていません。',

  /*********取引**********/
  selectCoin: 'コインを選択してください。',
  sendCoinInputAmount: '金額を入力してください。',
  sendCoinInputAmountLow: '最低出金コイン数は0.000001です。',
  sendCoinInputFee: '手数料を入力してください。',
  sendCoinInputAddress: 'アドレスを入力してください。',
  sendCoinInputAddress2: 'アドレス(有効なアドレスを入力してください。)',
  sendCoinLowBalance: '残高が足りません',
  // sendCoinLowBalanceXrp: '(最低20XRP保有必要)',
  sendCoinLowBalanceXrp: '',
  sendCoinLowFee: '手数料が足りません',
  sendCoinInputError: '送金情報生成に失敗しました。',
  sendCoinSuccess: '送金を完了しました。',
  sendCoinNeedConfirm: '注意事項を確認してチェックしてください。',
  sendCoinConfirmDlgTtl: '送る',
  sendCoinConfirmMessage: '下記のようにコインを送金します。 一度送金されたら取消できません。送金しますか。',
  sendCoinWarnMessage: '依頼後は取消できません！',

  sendCoinCautionConfirmDescriptionEx: [
    '金額と住所が正しいかもう一度ご確認ください。',
    'コイン金額は小数点6桁を基本として管理し、',
    '状況によって、0.以下の金額は誤差が生じることがあります。',
    'ブロックチェーンの特性上、送った後取消できます。',
  ],
  sendCoinCautionConfirm: '送金留意事項の確認',
  sendCoinCautionConfirm2: '留意事項に同意します。',
  sendCoinCautionFee: 'ブロックチェーン転送のため、約0.01ETH相当の手数料が発生します。',
  sendCoinCautionConfirmBtc: 'ビットコイン(BTC)、ビットコインキャッシュ(BCH)、ビットコインゴールド(BCG)はそれぞれ異なる暗号通貨であるため、入出金時には十分ご注意ください。',
  sendCoinCautionConfirmEth: 'イーサリアム(ETH)、イーサリアムクラシック(ETC)はそれぞれ異なる暗号通貨 であるため、入出金時には十分ご注意ください。',
  sendCoinCautionConfirmXrp: 'リップル(XRP)は必ずウォレットに20XRP以上を保有しなければなりません。 したがって、出金の時に保有残高の内20XRPを除いた残りの金額のみ出金できます。',
  sendCoinCautionConfirmErc20: 'イーサリアムトークン転送時に使われるイーサ手数料は、ネットワークの状況によって設定した値より多いか少ない場合があります。 したがって、出金時に必要な手数料の3倍位のイーサリアムを保有していなければなりません。',
  sendCoinCautionConfirmDescription: [
    'コインの金額は小数点6桁を基本として管理し、状況によっては0.0001以下の金額に誤差が生じることがあります。',
    '送信後、送信が完了するまで1時間以上の時間かかることがあり、送信状態は取引内訳でご確認いただけます。',
    '他の取引所のウォレットに入出金する場合は、当該取引所の政策に影響されることがあります。',
    '暗号通貨の特性上、送信後は取消できません。',
    '送る前に必ずアドレスを確認してください。',
    'このコインと同じコインのアドレスにのみ送信できます。 誤って他のコインのアドレスに送った場合は、コインを見つけることができません。',
    'ブロックチェーンの過負荷による入出金遅延問題はOTCマーケットでは処理できません。',
  ],
  sendCoinXrpUseTag: 'タグ使用',
  sendCoinFailedTitle: 'コイン転送失敗',
  sendCoinFailed: 'コイン送信に失敗しました。 しばらくしてからもう一度お試しください。',
  sendCoinFailedInvalidAddress: '送信するアドレスが正しくありません。',
  sendCoinFailedInsufficientBalance: '残高が足りません。',
  sendCoinFailedLocked: 'コインにLOCKがかかっています。',
  sendCoinFailedInvalidOtp: 'OTP情報が正しくありません。',
  sendCoinFailedInvalidSecPassword: 'セキュリティパスワードが正しくありません。',
  sendCoinFailedNoSecPassword: 'セキュリティパスワード4桁を入力してください。',
  sendCoinFailedInvalidDesTag: '存在しないDestination Tagです。',
  sendCoinFailedOtpRequired: 'OTPなしで送信できる1日限度額を超過しました。',
  sendCoinFailedLimitExceed: '1日限度額を超過しました。',
  sendCoinSuccessTitle: 'コイン転送リクエスト完了',
  includeAmount: '金額反映',
  includedAmount: 'QRに含まれた入金金額',
  includeAmountCaution: '金額が反映されたQRコードは他のウォレットでは互換できません。',
  inputDestinationTag: 'Destination Tag入力(選択)',

  /*********取引内訳**********/
  noCoins: '取引情報がありません。',

  /*********決済**********/
  makePayment: '決済する',
  paymentCautionConfirm: '決済留意事項確認',
  paymentCautionConfirmDescription: [
    'コインの金額は小数点6桁を基本として管理し、状況によっては0.0001以下の金額に誤差が生じることがあります。',
    '決済後、送信が完了するまで1時間以上かかることがあり、送信状態は取引内訳でご確認いただけます。',
    '暗号通貨の特性上、決済後はキャンセルできません。',
    '決済前に必ずアドレスと金額を確認してください。',
    'ブロックチェーンの過負荷による入出金遅延問題はOTCマーケットでは処理できません。',
  ],
  cancelPayment: '決済を取消しますか。',

  /*********交換**********/
  exchangeCoinInputAmount: '数量を入力してください。',
  exchangeCoinLowBalance: '残高が足りません。',
  exchangeCautionConfirm: '交換留意事項の確認',
  exchangeCoinLowBalanceXrp: '(最低20XRP保有必要)',
  exchangeCoinNeedConfirm: '注意事項を確認してチェックしてください。',
  exchangeCoinInputError: '交換情報生成に失敗しました。',
  exchangeCoinSuccess: '交換を依頼しました。',
  exchangeCautionConfirmDescription: [
    'ビットタイガーコインとの交換比率に基づきコインを交換します。',
    'OTCマーケットではコイン金額を小数点6桁を基本として管理し、状況によって0.0001以下の金額には誤差が発生することがあります。',
    '交換完了まで1時間以上かかることがあり、交換状態は取引内訳でご確認いただけます。',
    '暗号通貨の特性上、交換した後に取り消せません。',
    'ブロックチェーンの過負荷による遅延問題は、OTCマーケットでは処理できません。',
  ],
  exchange: '交換',
  exchangeInfo: '交換内訳',
  exchangeSendValue: '送る金額',
  exchangeReceiveValue: '受け取る金額',
  exchangeCoinConfirmDlgTtl: '交換する',
  exchangeCoinConfirmMessage: '次のようにコインを交換します。 一度交換すると取消できません。 交換しますか。',

  /*********アドレス帳**********/
  manageAddressBook: 'アドレス帳管理',
  addressBook: 'アドレス帳',
  noRegisteredAddress: 'アドレス帳に登録された内訳がありません。',
  addAddressSuccess: 'アドレス帳に登録されました。',
  deleteAddress: 'アドレス帳削除',
  deleteAddressDescription: 'アドレス帳で項目を削除しますか。',
  deleteAddressSuccess: 'アドレス帳から削除されました。',
  inputAddressbookMemo: '登録する名前入力',
  inputAddressbookAddress: '登録するアドレス入力',
  inputAddressbookDestinationTag: 'Destination Tag入力(選択)',
  needInputAddressbookMemo: '登録する名前を入力してください。',
  needInputAddressbookAddress: '登録するアドレスを入力してください。',
  registerAddress: 'アドレス登録',
  registerAddressFailed: 'アドレス登録に失敗しました。',
  alreadyRegisterdAddress: '既に登録されているアドレスです。',
  alreadyRegisterdAddress2: '既に登録されているアドレスです。 登録されている名前を修正しますか。',

  /*********初期設定**********/
  setupAreementConfirm: '利用約款同意',
  setupShowAgreement: '約款を見る',
  setupStep: [
  ],
  setupStepTitle: [
    'ウォレット初期設定',
    '基本情報入力',
    'セキュリティパスワード設定',
    '登録完了'
  ],
  setupStepGuide: [
  ],
  setupStepRecoverGuide: [
  ],
  mnemonicSelectionTitle: 'セキュリティのため、5つ以上の単語を変更してください。 スマートフォン紛失、アプリ削除等の場合はウォレットが紛失されることがありますので、必ずメモをとっておいてください。 12番目の単語は自動設定されます。',
  setupStepRecoverTitle: '復旧単語入力',

  setupCard: 'ウォレット登録',
  setupCardNotSupported: '',

  needAgreement: '約款に同意してください。',
  needViewAgreement: '約款を見るをクリックして約款を確認してください。',
  needChangeWord: '5つ以上の単語を変更してください。',
  needInputWord: '24単語を全部入力してください。',
  needValidWord: '単語が規則に合っていません。',
  passwordConfirmFail: '入力した2つのパスワードが一致しません。 もう一度ご確認ください。',
  passwordLengthFail: 'パスワードを入力してください。',
  passwordStrength: 'パスワードは英文、数字、特殊文字を組み合わせて8文字~15文字で作ってください。',
  passwordStrength0: 'パスワードを入力してください。',
  passwordStrength1: 'パスワードが弱いです。',
  passwordStrength2: 'パスワードが普通です。',
  passwordStrength3: 'パスワードが強いです。',
  emailLengthFail: 'eメールを入力してください。',
  emailStrength: 'eメール形式が正しくありません。',
  secPasswordStrength: 'セキュリティパスワードを4桁で入力してください。',
  secPasswordConfirmFail: '入力した2つのセキュリティパスワードが一致しません。 もう一度確認してください。',

  setupNewCard: '新規生成',
  setupRecoverCard: '復旧',

  phoneFail: '携帯電話番号を正確に入力してください。',
  certiCodeFail: '認証番号を入力してください。',
  certiCodeWrong: '認証番号を間違って入力されました。',
  certiCodeSendFail: '認証番号送信に失敗しました。',
  certiCodeNotVerified: '電話番号認証が必要です。',

  setupFinish: 'へようこそいらっしゃいました。おめでとうございます。',

  needLogout: [{ content: '' }],

  /*********共通サービス**********/
  purchase: ' ',
  bootError: 'サーバー接続失敗',
  bootErrorDetail: '「申し訳ありません。 現在サーバー接続が円滑に行われていません。 しばらくしてもう一度お試しください。',
  appUpdate: 'アプリアップデート',
  appUpdateDetail: 'アプリ必須アップデート」があります。 アップデート後、サービスをご利用ください。',
  goStore: 'ストア移動',
  serverDown: 'サーバ点検',
  serverDownDetail: 'サーバー点検中です。',
  waitLoading: 'しばらくお待ちください。',
  waitLoadingDetail: 'この画面が数秒以上続く場合は、アプリを再起動してください。 それでも問題が続く場合はサーバーに問題がある可能性があります。',

  /*********私の情報**********/
  myinfoSerialNum: '',
  myinfoCopyCard: 'カードコピー',
  myinfoBackupWordQuery: '復旧単語照会',
  myinfoBackupWordQueryDetail: 'スマートフォンの交換やアプリを削除してウォレットが無くなった場合は復旧単語を使ってウォレットを復旧させることができます。 まだ単語を記録しなかった場合は、今必ずメモをとっておいてください。',
  myinfoBackupWord: '復旧単語',
  myinfoLogin: 'ログイン',
  myinfoLoginDetail: '実行中です。しばらくお待ちください。',
  myinfoLoginChange: '',
  myinfoLogout: 'ログアウト',
  myinfoLogoutByTimeout: '長時間未使用でログアウトされました。',
  myinfoAppVer: 'アプリバージョン',
  myinfoHome: 'ホームページ',
  myinfoInquiry: 'お問い合わせ',
  myinfoInquiry1on1: ',1:1お問い合わせ',
  myinfoSupportCenter: 'カスタマーセンター',
  myinfoSupportCenterValue: '',
  myinfoCorporateNumber: '事業者登録',
  myinfoCorporateNumberValue: '509-86-01382',
  myinfoNotice: 'お知らせ',
  myinfoNoticeCategory: '区分',
  myinfoNoticeTitle: 'タイトル',
  myinfoNoticeDate: '登録日',
  myinfoPolicyAndGuide: '約款及びガイド',
  myinfoGuide: '利用ガイド',
  myinfoPolicy: '利用約款',
  myinfoFaq: 'よくある質問',
  myinfoLogoutConfirm: 'ログアウトしますか。',
  myinfoCopy: '',
  myinfoCopyTag: '',
  myinfoCopyTagExplanation: '',
  myinfoCopyTagComplete: '',
  myinfoCopyTagNotSupported: '',
  myinfoIdentify: '本人認証',
  myinfoIdentifyEmail: 'メール認証する',
  myinfoIdentifyEmailComplete: 'メール認証完了',
  email: 'eメール',
  inputEmail: 'eメール入力',
  inputEmailDetail: '本人確認のためメールアドレスを入力してください。',
  sendEmailComplete: '本人認証のためeメールが送信されました。',
  sendEmailFail: 'eメール送信に失敗しました。',
  myRecommendCode: '私の推薦コード',
  recommendCodeCopied: '推薦コードがコピーされました。',
  myinfoTwoSecurity: '二重認証',
  myinfoSettingNick: 'ニックネーム設定',
  myinfoHidePrice: '残高非表示',
  myinfoSettingLan: '言語設定',
  myinfoIsCurrency: '通貨表示',
  myinfoTwoSecurityKey: '二重認証キー',
  myinfoChangePassword: 'パスワード修正',
  myinfoChangeSecurityPassword: 'セキュリティパスワード修正',
  myinfoTwoSecurityInfo: '二重認証キーはウォレットから出金時、',
  myinfoTwoSecurityInfo2: 'セキュリティを強化させる6桁のキーです。',
  myinfoTwoSecurityInfo3: 'アップストアでGoogle OTPもしくは',
  myinfoTwoSecurityInfo4: 'Authy をダウンロードしてください。',
  myinfoInputAuthCode: '認証コードを入力してください。',
  myinfoOtpComplete: 'OTP設定が完了しました。',
  myinfoOtpInfo: '連動したOTP変更後',
  myinfoOtpInfo2: '間は出金が禁止されます。',
  startAuth: '認証開始',
  myinfoCopyKey: 'キーコピー',

  /*********チャージ**********
chargeCoin: 'OTCマーケットチャージ',
chargeCoinDescription1: '銀行振込みでOTCマーケットをチャージします。 以下の入力欄に金額を入力してチャージを申し込み、銀行振込みを進めてください。',
chargeCoinDescription2: 'チャージ申請が完了しました。',
chargeCoinDescription3: '金額と入金者名を以下の通りに振り込む必要があり、振込があった場合は、振込処理に多少時間がかかることがあります。',
chargeCoinDescription4:1時間以内に入金しない場合、自動的に申請が取り消されます。 複数の件を入金する場合は、件毎にチャージ申請を先に行った後、振り込まなければなりません。',
 chargeCoinDescription5: '振込口座、チャージ金額、振込者名をタッチするとコピーできます。',
 chargeCoinCopyBank: '入金口座がコピーされました。',
 chargeCoinCopyAmount: 'チャージ金額がコピーされました。',
 chargeCoinCopyName: '入金者名がコピーされました。',
 chargeKrwAmount: 'チャージ金額',
 chargeRequest: 'チャージ申請',
 chargeName: '入金者名',
 chargeBank: '入金口座',
 chargeRequestMaxLimit: '申請は同時に5件まで可能です。',
 chargeList: '実施中のチャージ申請リスト',

  /*********カスタム文句**********/
  manualInput: '手動入力',
  manualPurchase: '決済情報の貼付',
  manualPurchaseFail: 'クリップボードに決済情報がありません。',

  /****** 国 ******/
  selectCountry: '国を選択してください',
  country: '国',
  countryCode: ['KO', 'CN', 'US', 'JP',
    'AR', 'AM', 'AW', 'AU', 'AT', 'AZ', 'BH', 'BD', 'BY', 'BE',
    'BZ', 'BJ', 'BT', 'BO', 'BA', 'BW', 'BR', 'IO', 'BN', 'BG',
    'BF', 'BI', 'KH', 'CM', 'CA', 'CV', 'CF', 'TD', 'CL', 'CX',
    'CC', 'CO', 'KM', 'CK', 'CR', 'HR', 'CU', 'CW', 'CY', 'CZ',
    'CD', 'DK', 'DJ', 'TL', 'EC', 'EG', 'SV', 'GQ', 'ER', 'EE',
    'ET', 'FK', 'FO', 'FJ', 'FI', 'FR', 'PF', 'GA', 'GM', 'GE',
    'DE', 'GH', 'GI', 'GR', 'GL', 'GT', 'GN', 'GW', 'GY', 'HT',
    'HN', 'HK', 'HU', 'IS', 'IN', 'ID', 'IR', 'IQ', 'IE', 'IL',
    'IT', 'CI', 'JO', 'KZ', 'KE', 'KI', 'XK', 'KW', 'KG', 'LA',
    'LV', 'LB', 'LS', 'LR', 'LY', 'LI', 'LT', 'LU', 'MO', 'MK',
    'MG', 'MW', 'MY', 'MV', 'ML', 'MT', 'MH', 'MR', 'MU', 'YT',
    'MX', 'FM', 'MD', 'MC', 'MN', 'ME', 'MA', 'MZ', 'MM', 'NA',
    'NR', 'NP', 'NL', 'AN', 'NC', 'NZ', 'NI', 'NE', 'NG', 'NU',
    'KP', 'NO', 'OM', 'PK', 'PW', 'PS', 'PA', 'PG', 'PY', 'PE',
    'PH', 'PN', 'PL', 'PT', 'QA', 'CG', 'RE', 'RO', 'RU', 'RW',
    'BL', 'SH', 'MF', 'PM', 'WS', 'SM', 'ST', 'SA', 'SN', 'RS',
    'SC', 'SL', 'SG', 'SK', 'SI', 'SB', 'SO', 'ZA', 'SS', 'ES',
    'LK', 'SD', 'SR', 'SJ', 'SZ', 'SE', 'CH', 'SY', 'TW', 'TJ',
    'TZ', 'TH', 'TG', 'TK', 'TO', 'TN', 'TR', 'TM', 'TV', 'UG',
    'UA', 'AE', 'GB', 'UY', 'UZ', 'VU', 'VA', 'VE', 'VN', 'WF',
    'EH', 'YE', 'ZM', 'ZW',
  ],
  countryTelCode: ['82-', '86-', '1-', '81-',
    '54-', '374-', '297-', '61-', '43-', '994-', '973-', '880-', '375-', '32-',
    '501-', '229-', '975-', '591-', '387-', '267-', '55-', '246-', '673-', '359-',
    '226-', '257-', '855-', '237-', '1-', '238-', '236-', '235-', '56-', '61-',
    '61-', '57-', '269-', '682-', '506-', '385-', '53-', '599-', '357-', '420-',
    '243-', '45-', '253-', '670-', '593-', '20-', '503-', '240-', '291-', '372-',
    '251-', '500-', '298-', '679-', '358-', '33-', '689-', '241-', '220-', '995-',
    '49-', '233-', '350-', '30-', '299-', '502-', '224-', '245-', '592-', '509-',
    '504-', '852-', '36-', '354-', '91-', '62-', '98-', '964-', '353-', '972-',
    '39-', '225-', '962-', '7-', '254-', '686-', '383-', '965-', '996-', '856-',
    '371-', '961-', '266-', '231-', '218-', '423-', '370-', '352-', '853-', '389-',
    '261-', '265-', '60-', '960-', '223-', '356-', '692-', '222-', '230-', '262-',
    '52-', '691-', '373-', '377-', '976-', '382-', '212-', '258-', '95-', '264-',
    '674-', '977-', '31-', '599-', '687-', '64-', '505-', '227-', '234-', '683-',
    '850-', '47-', '968-', '92-', '680-', '970-', '507-', '675-', '595-', '51-',
    '63-', '64-', '48-', '351-', '974-', '242-', '262-', '40-', '7-', '250-',
    '590-', '290-', '590-', '508-', '685-', '378-', '239-', '966-', '221-', '381-',
    '248-', '232-', '65-', '421-', '386-', '677-', '252-', '27-', '211-', '34-',
    '94-', '249-', '597-', '47-', '268-', '46-', '41-', '963-', '886-', '992-',
    '255-', '66-', '228-', '690-', '676-', '216-', '90-', '993-', '688-', '256-',
    '380-', '971-', '44-', '598-', '998-', '678-', '379-', '58-', '84-', '681-',
    '212-', '967-', '260-', '263-',
  ],
  countryName: {
    KO: '대한민국', CN: '중국',  US: '미국', JP: '일본',
    AR: 'Argentina', AM: 'Afghanistan', AW: 'Albania', AU: 'Algeria', AT: 'American Samoa',
    AZ: 'Andorra', BH: 'Anguilla', BD: 'Antarctica', BY: 'Belarus', BE: 'Belgium',
    BZ: 'Belize', BJ: 'Benin', BT: 'Bhutan', BO: 'Bolivia', BA: 'Bosnia and Herzegovina',
    BW: 'Botswana', BR: 'Brazil', IO: 'British Indian Ocean Territory', BN: 'Brunei', BG: 'Bulgaria',
    BF: 'Burkina Faso', BI: 'Burundi', KH: 'Cambodia', CM: 'Cameroon', CA: 'Canada',
    CV: 'Cape Verde', CF: 'Central African Republic', TD: 'Chad', CL: 'Chile', CX: 'Christmas Island',
    CC: 'Cocos Islands', CO: 'Colombia', KM: 'Comoros', CK: 'Cook Islands', CR: 'Costa Rica',
    HR: 'Croatia', CU: 'Cuba', CW: 'Curacao', CY: 'Cyprus', CZ: 'Czech Republic',
    CD: 'Democratic Republic of the Congo', DK: 'Denmark', DJ: 'Djibouti', TL: 'East Timor', EC: 'Ecuador',
    EG: 'Egypt', SV: 'El Salvador', GQ: 'Equatorial Guinea', ER: 'Eritrea', EE: 'Estonia',
    ET: 'Ethiopia', FK: 'Falkland Islands', FO: 'Faroe Islands', FJ: 'Fiji', FI: 'Finland',
    FR: 'France', PF: 'French Polynesia', GA: 'Gabon', GM: 'Gambia', GE: 'Georgia',
    DE: 'Germany', GH: 'Ghana', GI: 'Gibraltar', GR: 'Greece', GL: 'Greenland',
    GT: 'Guatemala', GN: 'Guinea', GW: 'Guinea-Bissau', GY: 'Guyana', HT: 'Haiti',
    HN: 'Honduras', HK: 'Hong Kong', HU: 'Hungary', IS: 'Iceland', IN: 'India',
    ID: 'Indonesia', IR: 'Iran', IQ: 'Iraq', IE: 'Ireland', IL: 'Israel',
    IT: 'Italy', CI: 'Ivory Coast', JO: 'Jordan', KZ: 'Kazakhstan', KE: 'Kenya',
    KI: 'Kiribati', XK: 'Kosovo', KW: 'Kuwait', KG: 'Kyrgyzstan', LA: 'Laos',
    LV: 'Latvia', LB: 'Lebanon', LS: 'Lesotho', LR: 'Liberia', LY: 'Libya',
    LI: 'Liechtenstein', LT: 'Lithuania', LU: 'Luxembourg', MO: 'Macau', MK: 'Macedonia',
    MG: 'Madagascar', MW: 'Malawi', MY: 'Malaysia', MV: 'Maldives', ML: 'Mali',
    MT: 'Malta', MH: 'Marshall Islands', MR: 'Mauritania', MU: 'Mauritius', YT: 'Mayotte',
    MX: 'Mexico', FM: 'Micronesia', MD: 'Moldova', MC: 'Monaco', MN: 'Mongolia',
    ME: 'Montenegro', MA: 'Morocco', MZ: 'Mozambique', MM: 'Myanmar', NA: 'Namibia',
    NR: 'Nauru', NP: 'Nepal', NL: 'Netherlands', AN: 'Netherlands Antilles', NC: 'New Caledonia',
    NZ: 'New Zealand', NI: 'Nicaragua', NE: 'Niger', NG: 'Nigeria', NU: 'Niue',
    KP: 'North Korea', NO: 'Norway', OM: 'Oman', PK: 'Pakistan', PW: 'Palau',
    PS: 'Palestine', PA: 'Panama', PG: 'Papua New Guinea', PY: 'Paraguay', PE: 'Peru',
    PH: 'Philippines', PN: 'Pitcairn', PL: 'Poland', PT: 'Portugal', QA: 'Qatar',
    CG: 'Republic of the Congo', RE: 'Reunion', RO: 'Romania', RU: 'Russia', RW: 'Rwanda',
    BL: 'Saint Barthelemy', SH: 'Saint Helena', MF: 'Saint Martin', PM: 'Saint Pierre and Miquelon', WS: 'Samoa',
    SM: 'San Marino', ST: 'Sao Tome and Principe', SA: 'Saudi Arabia', SN: 'Senegal', RS: 'Serbia',
    SC: 'Seychelles', SL: 'Sierra Leone', SG: 'Singapore', SK: 'Slovakia', SI: 'Slovenia',
    SB: 'Solomon Islands', SO: 'Somalia', ZA: 'South Africa', SS: 'South Sudan', ES: 'Spain',
    LK: 'Sri Lanka', SD: 'Sudan', SR: 'Suriname', SJ: 'Svalbard and Jan Mayen', SZ: 'Swaziland',
    SE: 'Sweden', CH: 'Switzerland', SY: 'Syria', TW: 'Taiwan', TJ: 'Tajikistan',
    TZ: 'Tanzania', TH: 'Thailand', TG: 'Togo', TK: 'Tokelau', TO: 'Tonga',
    TN: 'Tunisia', TR: 'Turkey', TM: 'Turkmenistan', TV: 'Tuvalu', UG: 'Uganda',
    UA: 'Ukraine', AE: 'United Arab Emirates', GB: 'United Kingdom', UY: 'Uruguay', UZ: 'Uzbekistan',
    VU: 'Vanuatu', VA: 'Vatican', VE: 'Venezuela', VN: 'Vietnam', WF: 'Wallis and Futuna',
    EH: 'Western Sahara', YE: 'Yemen', ZM: 'Zambia', ZW: 'Zimbabwe',
  },

  /********* 約款等**********/

  policyContent: [
    { title: '第1章総則', content: '' },
    {
      title: '第1条(目的)',
      content:
        '本約款は株式会社ハッシュワンカンパニー(以下、「会社」という)が提供するアプリ及びウォレットで提供するサービス(以下、「サービス」という)の利用条件及び手続きに関する会社と利用者間の権利義務並びに責任事項、その他必要事項を規定することを目的とします。\n',
    },
    {

      title: '第2条(約款の明示、説明と改訂)',
      content: [
        '① この約款の内容は、会社のサービス等その他の方法で利用者に公知し、利用者がこの約款に同意することで効力が発生します。\n',
        '② 会社は、『約款の規制に関する法律』、『情報通信網の利用促進及び情報保護等に関する法律』等の関連法律を違反しない範囲内で本約款を改正することができます。\n',
        '③ 会社が約款を改訂する場合は適用日付及び改訂事由を明示して現行約款と一緒に会社サイトの初期画面やポップアップ画面、または公知事項により、その適用日の7日前から公知します。 但し、利用者に不利だったり重大な内容の変更がある場合は、適用日30日前に利用者に通知します。\n',
        '④ 会社が前項により改訂約款を公示する際、利用者に対して7日間の期間内に意思表示をしなければ意思表示が表明されたものとみなすという旨を明確に告知したにもかかわらず、利用者が明示的に拒否意思を表示しなかった場合、利用者が改訂約款に同意したものとみなします。\n',
        '⑤ 利用者が改定約款の適用に同意しないという明示的意思を表明した場合、会社は改定約款の内容を適用することができず、その場合、利用者は利用契約を解約することができます。 但し、既存約款が適用できない特別な事情がある場合は、会社は利用契約を解約することができます。\n',
      ]
    },
    {
      title: '第3条(約款外準則) ',
      content:
        '会社は、サービスについて、本約款以外に別途公知事項、個別約款、個別同意事項、利用案内、運用制作、詳細指針等(以下、総じて『詳細指針』といいます)を定め、それを利用者に知らせたり同意を得ることができるし、詳細指針のうち、利用者の同意を得た内容がこの約款と相反する場合は、『詳細指針』が優先的に適用されます。 本約款に定めてない事項や解釈については、『詳細指針』及び関連法令、商慣習に従います。\n',
    },
    {

      title: '第4条(用語の定義)',
      content: [
        '① この約款で使う用語の定義は、次のとおりです。\n',
        '1. 暗号通貨:ビットコイン(BTC)、イーサリアム(ETH)等のブロックチェーンで電子的に存在する通貨のことをいいます。\n',
        '2. 利用者:この約款を承認して会社が提供するウォレットを登録した利用者のことをいいます。\n',
        '3. アプリ:暗号通貨を保存したり、送受信できるスマートフォンオンライン専用プログラムをいいます。\n',
        '4. ウォレット:スマートフォンに暗号通貨を保存できるアプリのことをいいます。\n',
        '5. パスワード:利用者とウォレット間の同一性確認と暗号通貨セキュリティのために利用者が設定した文字の組み合わせのことをいいます。\n',
        '6. 復旧単語:ウォレットを紛失した場合、復旧できる12の暗号化された単語をいいます。\n',
      ]
    },
    {
      title: '第2章サービス利用申請及び承諾(利用者加入及び解約) ',
      content: '',
    },
    {

      title: '第5条(利用契約の成立)',
      content: [
        '① 利用者はウォレットを生成する時に本約款に同意するという意思を会社が提供するアプリに表示をすることでウォレット使用を申請します。\n',
        '② 利用契約はウォレットを登録した単位で締結します。 利用契約が成立すると、利用申請者はウォレットの利用者として登録されます。\n',
      ]
    },
    {
      title: '第6条(利用申請)',
      content: '利用申請は、会社が提供するアプリにウォレットを登録する時、自動的に利用申請が承認されます。\n',
    },
    {
      title: '第7条(利用者情報の使用についての同意及び利用申請の承諾)',
      content: [
        '① 利用者情報の使用についての同意\n',
        '1. 会社は本利用契約の履行と本利用契約上のサービスを提供する目的で利用者の個人情報を利用します。\n',
        '2. 利用者が会社及び会社と提携したサービスを便利に利用できるように、利用者情報は会社と提携した業者に提供することがあります。 但し、会社は利用者情報の提供前に、提携業者、提供目的、提供する利用者情報の内容等を事前に公知して利用者の同意を得なければなりません。\n',
        '3. 利用者は利用者情報の修正により、いつでも個人情報の閲覧や修正ができます。\n',
        '4. 利用者が利用申請書に利用者情報を記載して会社に本約款に基づいて利用申請を行うことは、会社が本約款に基づいて利用申請書に記載された利用者情報を収集、利用及び提供することに同意するものとみなされます。\n',
      ]
    },
    {

      title: '第8条(利用契約の中止及び解約)',
      content: [
        '① 利用契約及びアプリ使用の解約は、利用者がスマートフォンでアプリを削除することで解約できます。\n',
        '② 会社は、加入申請者の申請について、次の各号に該当する事由がある場合は承諾をしないことができ、加入後も、次の各号の事由が確認できる場合は承諾を取り消したり利用契約が解約できます。\n',
        '1. 本約款により以前会員資格を喪失したことがある場合\n',
        '2. 虚偽または誤った情報を記載または提供したり会社が提示する内容を記載しない場合\n',
        '3. 加入申請者の責に帰すべき事由により承認できなかったり、その他本約款で規定した諸事項を違反して申請した場合\n',
        '4. 不正な用途または別個営業を目的としてサービスを利用しようとする場合\n',
        '5. 関連法令に違反したり、社会の安寧秩序もしくは公序良俗を阻害し得る目的で申請した場合\n',
        '6. 登録申請者の年齢が満19歳未満の場合\n',
        '③ 会社は会員が提供した情報が事実と一致するかの可否を確認するために法令により許可された範囲内で専門機関を通じた実名確認または本人認証を依頼することができます。\n',
      ]
    },
    { title: '第3章 会社の義務', content: '' },
    {

      title: '第9条利用者個人情報とサービスに対する会社の義務',
      content: [
        '① 会社は関連法令と本約款を遵守し、サービスを継続的で安定的に提供するために最善を尽くして努力します。\n',
        '② 会社は、利用者が安全にサービスが利用できるように個人情報保護のためにセキュリティシステムを備えることができ、個人情報処理方針を公示して順守します。\n',
        '③会社は業務に関連して利用者の事前同意なしに利用者全体または一部個人情報等を、特定利用者の情報が区別できない統計資料に作成して使用することができて、そのため利用者はコンピューターにクッキーを転送することができます。 但し、その場合、利用者はクッキー送信を拒んだりクッキー送信について警告するように使用する機器の設定変更ができるが、その場合 会社の帰責事由なくクッキー設定変更によってサービス利用が変更されることがあります。\n',
        '④ 会社は、サービス利用に関連して利用者から提起された意見や不満が正当だと認めた場合はそれを処理しなければならないし、サービス内の掲示板、 E-mail 等で処理過程や結果を利用者に伝えることができます。\n',
        '⑤ 会社は情報通信網法、位置情報保護法、通信秘密保護法、電気通信事業法等のサービスの運用·維持に関連ある法規を遵守します。\n',
      ]
    },
    { title: '第4章 利用者の義務', content: '' },
    {

      title: '第10条(利用者ウォレットとパスワード管理に対する利用者の義務)',
      content: [
        '① ウォレットとパスワードに関する全ての管理責任は利用者にあります。 利用者が登録したウォレットとパスワードのずさんな管理、不正使用によって発生する全ての結果についての責任は利用者にあり、会社はそれに対する責任を負いません。 但し、会社の故意による場合はこの限りではありません。\n',
        '② 12の復旧単語は必ず紙に記入しておかなければならないし、ウォレットを紛失して復旧する時に12の復旧単語が必要です。 会社はその情報を持っていないため、復旧単語を紛失した場合は復旧できませんが、その時の全ての責任は利用者にあり、会社はそれに対する責任を負いません。 また、本人のミスによって12の復旧単語が流出されて被った損失についての全ての責任も利用者にあり、会社はそれに対する責任を負いません。\n',
      ]
    },
    {

      title: '第11条(情報の提供)',
      content: [
        '1. 会社は固定·無線電話、電子メール、文字サービス(LMS・SMS)、SNS等の方法で以下のようなサービスについて、利用便宜のための利用案内及び商品に関する情報を提供することができます。 その場合、利用者はいつでも受信を拒否することができます。\n',
        '① イベント及び行事関連等のサービス\n',
        '② その他会社が随時決定して利用者に提供するサービス\n',
        '2. 利用者はサービスを通じていつでも本人の個人情報を閲覧·修正·修正を要請することができます。 利用者は、会員登録申請時に記載した事項が変更された場合、サービスで直接修正するか、カスタマーセンターを通じて会社に変更事項を通知しなければなりません。 利用者は前項の変更を会社に通知しなかったため発生した不利益について、会社は責任を負いません。\n',
      ]
    },
    { title: '第5章 サービス利用総則', content: '' },
    {

      title: '第12条(サービスの種類)',
      content: [
        '① 会社が提供するサービスには暗号通貨を保存するウォレット機能、他人に暗号通貨が送受信できる機能等があります。 但し、利用者が提供する暗号通貨ウォレットアドレスの正確性についての責任は利用者にあり、会社は利用者が誤って記載した暗号通貨ウォレットアドレスに暗号通貨を送ることについて責任を負いません。\n',
        '② 会社が提供するサービスの種類は、会社の事情により随時変更されることがあり、提供されるサービスの著作権及び知的財産権は、会社に帰属します。\n',
        '③会社はサービスと関連して 会社が定めた利用条件に基づいてアカウント、ウォレット、サービス等を利用できる利用権限だけを利用者に与え、利用者はそれを活用した類似サービスの提供及び商業的活動をすることはできません。\n',
      ]
    },
    {

      title: '第13条(サービス内容のお知らせ及び変更)',
      content: [
        '① 会社は、サービスの種類によって各サービスの特性、手続及び方法に関する事項をウェブサイトを通じて公知し、利用者は、会社が知らせた各サービスに関する事項を理解した上で、サービスを利用しなければなりません。\n',
        '② 会社は相当の理由がある場合、運用上、技術上の必要に応じて提供している個別サービスの全部または一部が変更できます。 この場合、会社は最低7日前にその内容を利用者に知らせます。\n',
      ]
    },
    {

      title: '第14条(サービスの維持及び中止)',
      content: [
        '① サービスの利用は、会社の業務上または技術上の特別な支障がない限り、1日24時間を原則とします。 但し、定期点検等の必要で、会社が事前または事後に告知して定めた日または時間は、この限りではありません。\n',
        '② 会社はサービスを一定の範囲に分割し、別途各範囲毎の利用可能時間を定めることができます。 その場合、その内容を事前または事後に知らせます。\n',
        '③ 会社は次の各号に該当する場合、サービス提供を中止することができます。 その場合、会社はやむを得ない場合でない限り、サービス提供中止を事前に知らせます。\n',
        '1. サービス用設備補修等の工事によりやむを得ない場合\n',
        '2. 電気通信事業法に規定された基幹通信事業者が電気通信サービスを中止した場合\n',
        '3. 直接会社が提供するサービスでなく、提携業者等の第三者を利用して提供するサービスの場合、提携業者等の第三者がサービスを中止した場合\n',
        '4. その他の不可抗力的事由がある場合\n',
        '④ 会社は国家非常事態、停電、サービス設備の障害またはサービス利用の輻輳等で正常サービス利用に支障がある時は、サービスの全部または一部を制限したり停止することができます。\n',
      ]
    },
    {
      title: '第15条 利用制限',
      content: [
        '① 会社は、次の各号に該当する場合、会員のサービス利用及び接続を制限することができます。\n',
        '1. パスワード及びセキュリティパスワードが連続してエラーになった場合\n',
        '2. ハッキング及び事故が発生した場合\n',
        '3. 名義盗用と疑われる場合\n',
        '4. 関連法令に基づいて国家機関からのサービス制限依頼がある場合\n',
        '5. マネーロンダリング、不公正取引、犯罪行為等に関わっていたり、関わっていると合理的に疑われる場合\n',
        '6. 会員登録された利用者が満19歳未満と確認された場合\n',
        '7. その他各号に準じる事由が発生したりそのような事由発生の防止のために措置が必要な場合\n',
        '② 会社は、次の各号に該当する場合、会員の入金及び出金利用を制限することができます。\n',
        '1. 会員として加入された利用者が満19歳未満と確認された場合\n',
        '2. ハッキングや詐欺事故が発生したり発生したものと疑われる場合\n',
        '3. 名義盗用と疑われる場合\n',
        '4. 関連法令に基づいて国家機関からのサービス制限依頼がある場合 \n',
        '5. マネーロンダリング、不公正取引、犯罪行為等に関わっていたり、関わっていると合理的に疑われる場合\n',
        '6. 会員本人からの依頼がある場合\n',
        '7. 暗号通貨が送金されたが、会員アカウントに反映されない場合、または実際入出金依頼と異なった入出金された場合\n',
        '8. その他各号に準じる事由に該当する事由が発生したり、そのような事由の発生を防ぐための措置が必要な場合\n',
        '③ 本条に基づいてサービス利用を制限したり利用契約を解約する場合、会社はＥメール、SMS、App Push等で会員に通知します。\n',
        '④ 会員は、本条による利用制限等について会社が定めた手続に添って異議申立てができます。 その時、異議が正当であると会社が認めた場合、会社は直ちにサービス利用を再開します。\n',
      ]
    },
    {

      title: '第16条(利用契約の解除)',
      content: [
        '① 利用者はカスタマーセンターを通じていつでも利用契約の解約が申請できて、会社は関連法令が定めるところに基づいて直ちに処理しなければなりません。\n',
        '② 会社は次のような事由が発生した場合、会員に時間を決めてサービス利用を制限と同時に是正を要求することができます。 是正要求にもかかわらず、相当な期間内に是正できなかったり、繰り返して同じような違反行為を行った場合は、利用契約を解約することができます。\n',
        '1. 本約款第10条に規定された利用者の義務を違反したり第15条に定められた利用制限事由に該当する場合\n',
        '2. 著作権法を違反した不法プログラムの提供及び運用妨害、情報通信網利用促進及び情報保護等に関する法律を違反した不法通信及びハッキング、悪性プログラムの配布、アクセス権限超過行為等のように関連法令を違反した場合\n',
        '3. 会社が提供するサービスの円滑な進行を妨害する行為をしたり試みた場合\n',
        '4. その他各号に準じる準じる事由で本契約が維持できない事由が発生する場合\n',
        '③ 前項により利用契約を解約する時、サービス利用で獲得した全ての特典が消滅し、会社はそれに対して別途補償しません。\n',
        '④ 本条によりサービス利用契約を解約する場合、会社は利用者にeメール、SMS、App Push等で通知します。\n',
        '⑤ 利用契約の解約が完了する場合、関連法令及び個人情報処理方針に則って、会社が保有すべき情報を除いた利用者の全ての情報が削除されます。\n',
        '⑥ 第2項により、会社が利用契約を解約する場合、会社は利用者の異議申立受付及び処理等のために一定期間の間利用者の情報が保管でき、当該期間の経過後に利用者の情報(但し、入出金内訳は除く)を削除します。\n',
      ]
    },
    {

      title: '第17条（情報の提供及び広告の掲載）',
      content: [
        '① 会社は、サービス運用につき、サービスに関する各種情報をサービス画面に掲載したり、eメール、SMS、App Push等の方法で利用者に提供することができます。\n',
        '② 会社はサービスを運用につき、会社または提携会社の各種広告をサービス画面に掲載したり利用者の同意を得て、E-mail、SMS、App Push等の方法で利用者に提供できます。',
      ]
    },
    { title: '第5章 個人情報保護', content: '' },
    {

      title: '第18条(個人情報の保護) ',
      content: [
        '会社は、関係法令の定めにより、利用者の個人情報を保護するために努力します。 利用者の個人情報は、サービスの円滑な提供のため、利用者が同意した目的と範囲内でのみ利用されます。 会社は、法令に則ったり利用者が別途同意しない限り、利用者の個人情報を第三者に提供しないし、それに関する詳細については、個人情報処理方針にて定めます。\n',
      ]
    },
    { title: '第6章 損害賠償及び免責条項', content: '' },
    {

      title: '第19条(会社の免責事項及び損害賠償)',
      content: [
        '① 会社はサービスに関連してこの約款に明示していない如何なる事項について保証しません。 また、会社は、会社が発行したり支払いを保証していない暗号通貨の価値を保証しません。\n',
        '② 会社は天災事変、DDos攻撃、IDC障害、サービス接続の輻輳によるサーバーダウン、基幹通信事業者の回線障害等その他不可抗力的理由によりサービスが提供できない場合は、サービス提供に関する責任が免除されます。 但し、会社の故意または重過失による場合、この限りではありません。\n',
        '③ 会社はブロックチェーンの問題、暗号通貨発行管理システム自体の瑕疵、または技術的な問題、通信サービス業者の不良、定期的なサーバー点検等によりやむを得ず障害が発生した場合は責任を負いません。 但し、会社の故意または重過失による場合、この限りではありません。\n',
        '④ 会社は利用者の責に帰すべき理由によるサービス利用の障害やその結果については責任を負いません。 但し、利用者に正当な事由がある場合は、この限りではありません。\n',
        '⑤ 会社は利用者間または利用者と第三者間においてサービスを媒介にして取引等をした場合、責任が免除されます。 但し、会社の故意または重過失による場合、この限りではありません。\n',
        '⑥ 会社は無料で提供されるサービスの利用に関して、関連法に特別な規定がない限り、責任を負いません。\n',
        '⑦ 会社は安定したサービスを提供するために、定期的・不定期的に、または緊急サーバ点検を実施することができます。\n',
        '⑧ 誰であってもサービスエラー、電算障害またはその他の理由により第三者が所有している暗号通貨を権限なく取得したり転送された場合、会社は当事者への事前通知後、当該暗号通貨を回収したり原状回復させる等の必要な措置を取ることができます。\n',
      ]
    },
    {

      title: '第20条(管轄裁判所及び準拠法)',
      content: [
        '① 本サービスの利用約款は、大韓民国の法律に基づいて規律・解釈され、会社の料金体系等サービス利用に関連して会社と利用者間で発生した紛争で訴訟が提起される場合、大韓民国の法律が適用されます。\n',
        '② サービス利用に関連して会社と利用者間で発生した訴訟の場合、法令で定めた手続きに則った裁判所を管轄裁判所とします。\n',
      ]
    },
    { title: '附則', content: '本約款は2020年1月1日より適用されます。' },
  ],

  /*********エラーコード**********/
  E10000: 'ネットワーク接続不可',
  E10001: '不明なサーバエラー',
  E10002: '誤ったリクエスト情報',
  E10003: '権限なし',
  E10004: 'ブロックチェーン情報リクエストエラー',
  E30001: 'コインアドレス未生成エラー',

  /*********その他**********/
  needLogin2: 'ログインが必要です。',
  hideBalance: '残高非表示。',

  /**********要翻訳**********/
  loginFailedResendEmail: '認証メール再送信',

  emailResent: '認証メールが再送信されました。',
  emailResendFailed: '認証メール再送信に失敗しました。',
  invalidEmail: '既に認証されているeメールです。',
  emailAlreadyVerified: '正しくないeメールです。',

  certiCodeExpired: '認証期限が超過しました。 認証番号を再度送信してください。',

  serverRequestError: 'サーバーリクエスト失敗',
  serverRequestErrorDescription: 'サーバーリクエストに失敗しました。',

  duplCheck: '重複確認',

  emailDuplCheckError: 'eメール重複確認に失敗しました。',
  emailDuplCheckErrorEmailDupl: '既に登録されているeメールです。',
  emailDuplCheckErrorInvalidEmail: 'eメール形式が正しくありません。',
  needEmailDuplCheck: 'eメール重複確認が必要です。',

  setupFinish2: '認証メールが送信されました。\n60分以内に認証を完了してください。',

  sendCoinFailedOtpRequired: '１日送信限度を超えました。 OTPを設定して限度を上げてください。',

  useBioAuth: '生体認証使用',
  bioAuth: '生体認証',
  bioRequire: '生体を認識してください',
  bioFail: '生体認証に失敗しました。',

  feeRequestFailed: '手数料照会に失敗しました。',
  exemption: '免除(会員間転送) ',

  sendCoinSuccessNeedApproval: '送金を依頼が完了し、管理者承認後コインが転送されます。',

  setNickname: 'ニックネーム設定',
  setNicknameDescryption: 'ニックネームを設定してください',
  enterNickname: 'ニックネーム入力',
  enterNicknameValidation: 'ニックネームを1文字以上8文字以下で入力してください。',
  setNicknameSucceed: 'ニックネーム設定が完了しました。',
  setNicknameFailed: 'ニックネーム設定に失敗しました。',

  helloNickname: 'XX様、こんにちは。',
  helloWithoutNickname: 'こんにちは。',

  hideBalance: '残高非表示',
  hideBalanceSucceed1: '残高非表示が設定されました。',
  hideBalanceSucceed2: '残高非表示が解除されました。',
  hideBalanceFailed: '残高非表示設定に失敗しました。',


  supportTeam: 'カスタマーセンター',
  supportEmailCopied: 'カスタマーセンターeメールがコピーされました。',

  asset: '資産現況',
  sendCoinFailedLockedDetail: '- 最初の入金:72時間\n-OTP設定・変更:72時間\n -セキュリティパスワード変更:72時間',

  memberTrade: '会員間',// 会員間入金、会員間出金
  externalTrade: '外部', // 外部入金、外部出金

  securitySettings: 'セキュリティ設定',

  mnemonicValidationTitle: 'ニーモニックコード確認',
  mnemonicValidationDescryption: 'ニーモニックコードを正しい順番通り選択してください。',
  mnemonicValidationFailed: 'ニーモニックコードの順番が正しくありません。 もう一度ご確認ください。',

  /////////////////////////////////////////////////////////////////////////////

  exchangeCoinConfirmMessageWarn: '(交換比率は交換時点の相場で処理されるため、画面上の数量と多少誤差が生じることがあります。) ',
  generateQrMenu: '決済される',
  amount: '数量',
  shopName: '商号',
  serialNumber: 'シリアル番号',
  generate: '発行する', // QRを発行する
  purchaseAmount: '決済金額',
  lastPamentList: '最近の決済内訳',
  paymentCancelInsufficientUserBalance: '利用者の残高が足りないため、取消できません。',
  paymentCancelInsufficientOrganizationBalance: '加盟店の残高が足りないため、取消できません。',
  paymentCancelInvalidPaymentIdx: '正しくない決済情報です。',
  paymentCancelNotOrganization: '取消できる加盟店ではありません',
  paymentCancelFail: '不明なエラーにより取消に失敗しました。',
  paymentCancelSuccess: '取消処理が完了しました。',

  qrNoSerial: '加盟店情報がありません。',

  purchaseSucceed: '決済が完了しました。',
  purchaseFailed: '決済に失敗しました。 再度お試しください。',

  InvalidFranchise: '加盟店情報が正しくありません。',
  InvalidCoinType: 'コインの種類が正しくありません。',

  exchangeFailedTitle: '交換失敗',
  exchangeFailed: '交換に失敗しました。',

  setBankAccount: '口座設定',
  setBankAccountDetail: '口座番号を登録してください。',
  inputBankAccount: '口座番号入力',
  selectBankAccount: '口座選択',
  bankCodeValidation: '銀行を選択してください',
  accountValidation: '口座番号を入力してください。',
  setAccountSucceed: '口座入力が完了しました。',
  setAccountFailed: '口座入力に失敗しました。',

  purchaseAmountValidation: '金額を入力してください。',

  serverRequestErrorDescription2: 'アプリを再度始めてください',
  showAddressDetail: 'Destination Tagまで正確に入力しないと入金処理できません。',
  inputOtpValidation: 'OTPを入力してください。',

  id: 'eメール',
  inputId: 'Email',
  loginFailedInvalidLoginInfo: 'ログイン情報が正しくありません。',
  idLengthFail: 'Emailを入力してください。',

  step: '段階',

  setupStepGuidePolicy: 'ウォレット生成のためのアカウント登録、セキュリティパスワード入力等を進めます。 開始のため、約款を確認して同意してください。',

  bankName: [
    '경남은행',
    '광주은행',
    'KB국민은행',
    'IBK기업은행',
    '농협은행',
    '대구은행',
    '부산은행',
    '산업은행',
    '새마을금고',
    '수협은행',
    '신한은행',
    '신협은행',
    'SC은행',
    '우리은행',
    '우체국',
    '제주은행',
    '카카오뱅크',
    'k뱅크',
    'KEB하나은행',
    'HSBC은행',
    '뱅크오브아메리카',
    '도이치은행',
  ],
  bankCode: [
    'BANK_KN',
    'BANK_KJ',
    'BANK_KB',
    'BANK_IBK',
    'BANK_NH',
    'BANK_DGB',
    'BANK_BUSAN',
    'BANK_KDB',
    'BANK_MG',
    'BANK_SH',
    'BANK_SHINHAN',
    'BANK_CU',
    'BANK_SC',
    'BANK_WOORI',
    'BANK_POST',
    'BANK_JEJU',
    'BANK_KAKAO',
    'BANK_K',
    'BANK_KEB',
    'BANK_HSBC',
    'BANK_AMERICA',
    'BANK_DB',
  ],
  payment: '決済',
  paymentList: '決済内訳',
  sendReceive: '入出金内訳',
  exchangeTarget: '交換対象',
  exchangeTargetAddress: '交換対象アドレス',
  exchangeRatio: '交換比率',
  franchiseName: '加盟店名',
  payback: 'ペイバック',

  noNotice: '登録されたお知らせがありません。',
  notAvailableRecommender: '推薦人が見つかりません',
  sendCoinFailedOrganization: '加盟店アカウントではコインが送れません。',

  adjustAccount: '精算',

  nameFail: '名前を正確に入力してください。',

  paymentInfo: '決済情報',
  paymentSucceedInfoArrived: '決済完了内訳が届きました。',
  paymentCanceledInfoArrived: '決済取消内訳が届きました。',
  payer: '決済者',

  //////////////////////////////////////////v1.1///////////////////////////////////////////////////////
  languageSetting: '言語設定',
  languageSettingModified: '言語設定が変更されました。 アプリを再度始めてください。',

  eventTempTitle: 'OTCマーケットでは、様々なイベントを用意しています。',
  eventTempSub1: '会員登録と会員の推薦を通じたエアドロップ',
  eventTempSub2: 'コインステイキングを通じた資産運用',
  eventTempSub3: '人気コインの特価交換イベント',
  eventTempSub4: 'オンオフライン決済イベント',
  eventTempSub5: 'コンタクト友達を招待イベントなど',

  
  findPasswordDescription: '加入したメールアドレスを入力すると、パスワードを変更するためのリンクがメールで送信されます。',
  findPasswordEmailSentSuccess: 'パスワードを変更するための電子メールが送信されました。',

  /*********イベント**********/
  eventTitle: 'イベント',
  eventListBtn: '私のイベントの履歴',

  onProgress: '進行中',
  more: '見る',
  noEvent: '登録されたイベントがありません。',


  /*********イベント詳細**********/
  eventDetTitle: 'イベントの履歴',
  paidComplete: '支払い完了',
  eventNew: '新規会員登録補償イベント',

  /*********交換*********/
  register: '登録者',
  registDate: '登録日',
  doApply: '購入する',
  doCancel: 'キャンセル',
  exchangeMyList: '私の履歴',
  exchangeRegist: '販売登録',
  viewAll: '概要',
  exchangeSelectCoin: '選択する',

  exchangeSameCoinFail: '同じコイン同士取引することはできません。',

  exchangeSendCoin: '送信コイン',
  exchangeReceiveCoin: '受信コイン',
  exchangeCoinInputAmount: 'コインを入力してください',

  exchangePolicy01: ' - コイン販売時の販売金額に手数料を合算した金額が保有している残高から差し引かれます。',
  exchangePolicy02: ' - 販売登録を解除する場合は差し引かれた金額でのキャンセル料だけを除いた金額が返金されます。',
  exchangePolicy03: ' - 一度販売登録をするとマッチングされるかキャンセルされるまで待機します。',

  exchangeRegistNoBalance: '販売することができる残高が不足しています。',
  exchangeRegistFail: '失敗しました。再試行してください。',

  exchangeCancel: '取引キャンセル',
  exchangeCancelMessage: '販売をキャンセルすると、キャンセル料が発生します。キャンセルしますか？',
  exchangeCancelFee: 'キャンセル料',
  exchangeDone: '取引完了',

  exchangeApply: '購入する',
  exchangeApplyMessage: '一度購入すれば元に戻すことができません。購入しますか？',
  exchangeApplyNoBalance: '購入できる残高が不足しています。',
  exchangeInvaild: '既に取引されたか取り消されたものです。',
  exchangeSelfFail: '自分自身と取引することはできません。',
  exchangeRegistDate: '登録日時',

  exchangeStatusPending: '取引待機中',
  exchangeStatusCancel: 'キャンセル完了',
  exchangeStatusMatch: '取引完了',

  exchangeMyRegist: '販売リスト',
  exchangeMyApply: '購入リスト',
  exchangeBuyCoin: '購入コイン',
  exchangeSentCoin: '送信コイン',

  pushSetting: 'プッシュ設定',

  recommendTextWithRewardAdditionRecommend: '％sさんがお客様に[％sウォレットアプリ]をお勧めしました！ \ n1。アプリ登録時％s％sコインを差し上げます。\ n2。アプリ登録時オススメのコードを入力すると、％s％sを追加補償給付をいたします！\ n3。友達を招待紹介報酬 - 友達を招待を介してお友達を1人招待するたびに％s％sのコインを追加で得ることができます。\ n友人と一緒に％sプラットフォームの利点をゲット！\ n \ nオススメコード: ％s \ n \ nコインビーズに記載されている数多くのコインをエアドロップ受けてみてください〜\ n％s',
  recommendTextWithRewardRecommend: '％sさんがお客様に[％sウォレットアプリ]をお勧めしました！ \ n1。アプリ登録時％s％sコインを差し上げます。\ n2。友達を招待紹介報酬 - 友達を招待を介してお友達を1人招待するたびに％s％sのコインを追加で得ることができます。\ n友人と一緒に％sプラットフォームの利点をゲット！\ n \ nオススメコード: ％s \ n \ nコインビーズに記載されている数多くのコインをエアドロップ受けてみてください〜\ n％s',
  recommendTextWithReward: '％sさんがお客様に[％sウォレットアプリ]をお勧めしました！ \ nアプリ登録時に％s％sコインを差し上げます。\ n友人と一緒に％sプラットフォームの利点をゲット！\ n \ nオススメコード: ％s \ n \ nコインビーズに記載されている数多くのコインをエアドロップ受け見てください〜\ n％s',
  recommendText: '％sさんがお客様に[％sウォレットアプリ]をお勧めしました！ \ n友人と一緒に％sプラットフォームの利点をゲット！\ n \ nオススメコード: ％s \ n \ nコインビーズに記載されている数多くのコインをエアドロップ受けてみてください〜\ n％s',
  recommendTextCopied: 'おすすめフレーズがコピーされました。',

  staking: 'ステイキング',
  stakingInterest: 'ステイキングであり、',
  stakingInterestInterval: '毎日',
  unstaking: 'アンステイキング',
  stakingDescription1: 'ステイキングする場合、毎日％s％の利息が％sに支払われます。',
  stakingDescription2: 'ステイキングする場合％s日間凍結ステイキング（出金）ができません。',
  stakingDescription3: 'ステイキングを解除します。解除した数量にはもう利息が支払われていません。',
  stakingAvailableAmount: '可能スラン',
  stakingApplyAmount: '適用スラン',
  stakingInvalidAmount: '適用可能な数量を入力してください',
  stakingConfirm: '％s％sのコインをステイキングしますか？',
  stakingReleaseConfirm: '％s％sのコインをアンステイキングしますか？',
  stakingResultSuccess: 'ステイキングが完了しました。',
  stakingResultFail: 'ステイキングに失敗しました。残高が不足したり、ステイキングが不可能なコインです。',
  stakingReleaseResultSuccess: 'アンステイキングが完了しました。',
  stakingReleaseResultFail: 'アンステイキングに失敗しました。残高が不足したり、アンステイキングが不可能な状態です。',
  stakingNoBalance: 'ステイキングを解除することができる数量がありません。',

  exchangeStatus: '取引状態',
  search: '検索',

  // 추가사항
  termText: 'ウォレット作成のためにアカウント登録と\ nセキュリティパスワード入力が行われます。',
  differentPw:'暗証番号がお互いに違います。もう一度確認をお願いします。',
  idOk:'使用可能です。',
  inputEmail2:'Eメールを入力してください。',
};
