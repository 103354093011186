import { Form } from 'antd';
import React from 'react';
import { connect } from "react-redux";
import Language from '../lib/util/language';

class Privacy extends React.Component {
    constructor(props) {
        super(props);
        this.string = Language.getString();
    }

    render() {
        const string = this.string;
        return (
            <div>
                <div className="agreement-content-title">
                    {string.policyTitle}
                </div>
                    
                <div>
                    {string.policyContent.map((value, index) => {
                        let result = [];
                        if (value.title)
                            result.push(
                            <div key={'title_' + index} className="agreement-content-title">
                                {value.title}
                            </div>
                            );
                        if (value.content)
                            result.push(
                            <div key={'content_' + index} className="agreement-content-body">
                                {value.content}
                            </div>
                            );
                        return result;
                    })}
                </div>
            </div>
        )
    }
}

  export default Privacy;