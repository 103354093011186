import { Form, Input, Checkbox, Spin, Modal, Select } from 'antd';
import React, { useState } from 'react';
import "../css/signUp.scss";
import { httpGet, httpUrl, httpPost } from "../api/httpClient";
import crypto from 'crypto';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { setCoinList, setCoinBalance, setCoinStaking, setCoinAddress, setCoinFee, setCoinCurrency, setCoinServerWallet, resetCoinList, login, logout } from '../actions';
import { Route, withRouter, Link } from "react-router-dom";
import Language from '../lib/util/language';
import { reactLocalStorage } from "reactjs-localstorage";
import Const from "../const";
import SignUp from "./SignUp";

const FormItem = Form.Item;

class Login extends React.Component {
    formRef = React.createRef();
    string = Language.getString();
    
    constructor(props) {
        super(props);
        this.state = {
            saveId: false,
            language: global.language,
        };
    }
    componentDidMount() {
        let saveId = reactLocalStorage.get(Const.appCode + "#saveId");
        if (saveId === 'false') saveId = false;
        this.setState({saveId});
        if (saveId) {
            const userId = reactLocalStorage.get(Const.appCode + "#userId");
            const form = this.formRef.current;
            form.setFieldsValue({id: userId});
        }
    }
    handleSubmit = (values) => {
        const string = this.string;
        
        console.log('handleSubmit');
        const form = this.formRef.current;

        let password = form.getFieldValue("password") + form.getFieldValue("id").trim();

        let passwordHash = crypto
            .createHash('sha256')
            .update(password, 'utf8')
            .digest()
            .toString('hex');

        values.password = passwordHash;
        values.id = values.id.trim();
        console.log(values);

        httpPost(httpUrl.login, [], values).then((result) => {
            console.log("## login result: " + JSON.stringify(result, null, 4));
            result = result.data;

            if (result.result) {
                console.log("## login success");

                this.props.login(result.user);
                reactLocalStorage.setObject(Const.appCode + "#userinfo", result.user);
                this.startUp();
                if (this.state.saveId) {
                    reactLocalStorage.set(Const.appCode + "#userId", form.getFieldValue('id'));
                    reactLocalStorage.set(Const.appCode + "#saveId", true);
                }
                else {
                    reactLocalStorage.remove(Const.appCode + "#userId");
                    reactLocalStorage.set(Const.appCode + "#saveId", false);
                }

            } else {
                console.log("## login fail");

                if (result.reason == 'EMAIL_CONFIRM_NOT_YET') {
                    Modal.info({
                        title: string.loginFailedTitle,
                        content: (
                            <div>
                                {string.loginFailedEmailConfirm}
                            </div>
                        ),
                        onOk() { },
                    });
                } else {
                    let message = string.loginFailedMessage;

                    if (result.reason == 'INVALID_LOGIN_ID') message = string.loginFailedWrongId;
                    else if (result.reason == 'INVALID_LOGIN_PASSWORD') message = string.loginFailedWrongPassword;
                    else if (result.reason == 'INVALID_LOGIN_INFO') message = string.loginFailedInvalidLoginInfo;
                    else if (result.reason == 'EXPIRED') message = string.loginFailedExpiredId;
                    Modal.info({
                        title: string.loginFailedTitle,
                        content: (
                            <div>
                                {message}
                            </div>
                        ),
                        onOk() { },
                    });
                }
            }
            // if (dlg != null) dlg.dismiss();

        }).catch(e => {
            // if (dlg != null) dlg.dismiss();

            console.log('## signUp error: ' + e);
            Modal.info({
                title: string.login,
                content: (
                    <div>
                        {string.loginFailedMessage}
                    </div>
                ),
                onOk() { },
            });
            // if (callback instanceof Function) callback(false, '');
        });
    }

    startUp = () => {
        this.requestSystemListBlockchain(true).then(() => {

            this.requestCurrency(true).then(() => {
                this.requestBalance(0, true).then(() => {

                    if (this.allLoaded()) this.gotoWallet();

                }).catch(e => { console.log("## requestBalance error: " + e) });
            }).catch(e => { console.log("## requestCurrency error: " + e) });

            this.requestFee(0, true).then(() => { if (this.allLoaded()) this.gotoWallet(); }).catch(e => { console.log("## requestFee error: " + e) });

            this.requestCurrencyExchangeRate(true).then(() => { if (this.allLoaded()) this.gotoWallet(); }).catch(e => { console.log("## requestCurrencyExchangeRate error: " + e) });

        }).catch(e => { console.log("## requestSystemListBlockchain error: " + e) });
    }

    allLoaded = () => {
        if (this.state.currencyLoaded && this.state.balanceLoaded && this.state.feeLoaded && this.state.exchangeRateLoaded) return true;
        else return false;
    }

    gotoWallet = () => {
        this.props.history.push('/mywallet');
    }
 
    requestSystemListBlockchain = (hide) => {
        console.log("## Loading systemListBlockchain");
        return new Promise((resolve, reject) => {
            httpGet(httpUrl.systemListBlockchain, [global.language], {}, hide).then((result) => {
                result = result.data;
                console.log('## systemListBlockchain loaded');
                // console.log(JSON.stringify(result));

                result.forEach(obj => {
                    obj.status = true;
                    obj.address = '';
                    obj.balance = 0;
                    obj.balanceLoaded = false;
                    obj.price = 0;
                    obj.priceLoaded = false;
                    obj.value = 0;
                    obj.valueLoaded = false;
                    obj.purchaseFee = 0;
                    obj.sendInFee = 0;
                    obj.sendExFee = 0;
                    obj.tradeFee = 0;
                    obj.feeLoaded = false;
                    obj.serverWallet = '';
                    obj.serverWalletLoaded = false;
                    obj.change = 0.0;
                    obj.market = 0.0;
                    obj.trends = [];
                    obj.stakeBalance = 0;
                    obj.stakeLockAmount = 0;
                })

                this.props.setCoinList(result);
                reactLocalStorage.setObject(Const.appCode + "#coinlist", result);
                reactLocalStorage.setObject("coinList", result);
                resolve();

            }).catch((e) => {
                console.log('## systemListBlockchain load error: ' + e)
                reject(e);
            });
        })
    }

    requestCurrency = (hide) => {
        console.log("## Request currency");
        return new Promise((resolve, reject) => {
            httpGet(httpUrl.currencies, [], {}, hide).then((result) => {
                this.props.setCoinCurrency(result.data);
                reactLocalStorage.setObject(Const.appCode + "#coincurrency", result.data);
                this.state.coinCurrencyUpdateTime = new Date().toLocaleTimeString();
                this.state.currencyLoaded = true;
                resolve();

            }).catch(e => {
                console.log("## requestCurrency error: " + e);
                reject(e);
            });
        })
    }

    requestFee = (coinType, hide) => {
        console.log("##### Request fee")
        return new Promise((resolve, reject) => {
            httpGet(httpUrl.coinFee, [coinType], {}, hide).then((result) => {
                this.props.setCoinFee(result.data);
                reactLocalStorage.setObject(Const.appCode + "#coinfee", result.data);
                this.state.feeLoaded = true;
                resolve();

            }).catch(e => {
                console.log("## requestFee error: " + e);
                reject(e);
            });
        });
    }

    requestBalance = (coinType, hide) => {
        console.log("##### Request balance")
        return new Promise((resolve, reject) => {
            httpGet(httpUrl.balance, [0], {}, hide).then((result) => {
                this.props.setCoinBalance(result.data);
                reactLocalStorage.setObject(Const.appCode + "#coinbalance", result.data);
                this.state.balanceLoaded = true;

                httpGet(httpUrl.getStakingBalance, [], {}, hide).then((result) => {
                    console.log('getStakingBalance=' + JSON.stringify(result))
                    this.props.setCoinStaking(result.data.stakingBalances);
                    reactLocalStorage.setObject(Const.appCode + "#coinstaking", result.data.stakingBalances);
                    resolve();

                }).catch(e => {
                    console.log("## getStakingBalance error: " + e)
                    reject();
                });
            }).catch(e => {
                console.log("## requestBalance error: " + e)
                reject();
            });
        })
    }

    requestCurrencyExchangeRate = (hide) => {
        return new Promise((resolve, reject) => {
            this.state.exchangeRateLoaded = true;
            global.exchangeRate = [1, 1, 1, 1];
            resolve();
        })
    }

    onHandleChange = ( value ) => {
        global.language = value;
        this.setState({ language: value });        
        reactLocalStorage.setObject(Const.appCode + '#language', value, () => {
        console.log("## language: " + value); 
        });                                      
        window.location.reload();  
    }

    render() {
        const string = this.string;
        
        return (
            <div>
                <div className="wrapper">
                    <div className="logForm">                      
                            <div className="logo" onClick={()=>this.props.history.push('/currency')}>
                                <img src={require('../img/loginPage/logo.png')} />                        
                            </div>
                            <div className="form">                         
                                <div className='title-wrap'>                                   
                                        <Select       
                                        bordered={false}                 
                                        value={this.state.language}
                                        onChange={this.onHandleChange}>
                                        {Language.getLanguageList().map(data => {
                                            return (
                                            <Select.Option key={data.code} label={data.name} value={data.code}>   
                                                {data.name}
                                            </Select.Option>
                                            );
                                        })}
                                        </Select>           
                                 
                                    <div className='title-wrap2'>
                                        <span className='logTitle'>{string.myinfoLogin}</span>
                                        <span 
                                            className='loginTitle'
                                            onClick={()=>{
                                                this.props.history.push('/SignUp');
                                            }}>
                                            {string.signUp}
                                        </span>
                                    </div>
                                    </div>
                                    <div className='bar'/>
                                <Form onFinish={this.handleSubmit} ref={this.formRef}>
                                    <div className='idBox'>
                                        <p className='logText'>{string.id}</p>
                                        <Checkbox checked={this.state.saveId} onChange={e=>this.setState({saveId: e.target.checked})}>
                                            <span style={{fontFamily:'NotoSansRegular'}}>{string.saveLoginId}</span>
                                        </Checkbox>
                                    </div>
                                    <Form.Item
                                        name="id"
                                        rules={[
                                                {
                                                    required: true,
                                                    message: string.idLengthFail
                                                },
                                            ]}
                                        >
                                            <Input className="inputId"
                                                placeholder={string.id}
                                            />
                                    </Form.Item>
                                    <p className='logText'>{string.password}</p>
                                    <Form.Item
                                            name="password"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: string.passwordLengthFail
                                                },
                                            ]}
                                        >
                                            <Input type="password" className="inputId"
                                                placeholder={string.password}
                                            />
                                        </Form.Item>
                                    <div className='box-container'>        
                                        <button className="logBtn" htmltype="submit">
                                            {string.login}
                                        </button>                                  
                                    <Link to ='/FindIdPw' >
                                        <div className='findIdPwBtn'>{string.findIdPassword}</div>
                                    </Link>
                                  </div>
                                </Form>                                                
                            </div>                        
                    </div>

                </div>               
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { coinList, loginInfo } = state
    return { coinList, loginInfo }
  };
  
  const mapDispatchToProps = dispatch => (
    bindActionCreators({ setCoinList, setCoinBalance, setCoinStaking, setCoinAddress, setCoinFee, setCoinCurrency, setCoinServerWallet, resetCoinList, login, logout }, dispatch)
  );
  
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));