import { Form, Input, Checkbox, Button, Modal } from 'antd';
import React from 'react';
import "../css/signUp.scss";
import { httpGet, httpUrl, httpPost } from "../api/httpClient";
import crypto from 'crypto';
import { connect } from "react-redux";
import { Route, withRouter, Link } from "react-router-dom";
import Language from '../lib/util/language';

const FormItem = Form.Item;

class SignUp extends React.Component {
   
    constructor(props) {
        super(props);
        this.string = Language.getString();
        this.state = {
            emailDuplCheck: false,
            certiVerified: false,
            phoneEditable: true,
            timer: 180,
            onTimer: false,
            visible: false,
            recommendCode: this.props.match.params.recommendCode,
            resendEmailVisible: false,
            resendEmailAddress: '',
            resendEmailError: '',
            // 
            step: 1,
            ckAutoLogin: false,
            email: "",
            password:"",
            passwordcheck:"",           
            name:"",
            phone:"",
            securityPassword:"",
            securityPasswordCheck:"",
            modalOpen:false,
        };
        this.password="";
        this.passwordCheck = "";
        this.formRef = React.createRef();
    }

    componentDidMount() {
    }

    onPressEmailDuplCheck = () => {
        const string = this.string;
        if (this.state.emailDuplCheck) return;
        if (this.state.email === "" || this.state.email === null) {
            alert(string.emailLengthFail)
            return;
        }
        console.log(this.state.email)
        httpGet(httpUrl.userCheckEmail, [this.state.email],{}).then((result) => {
            console.log("## onPressEmailDuplCheck result: " + JSON.stringify(result));
            result = result.data;

            if (result == "SUCCESS") {
                this.formRef.current.setFields([{
                    name: 'email',
                    errors: []
                }]);
                this.setState({ emailDuplCheck: true, msg: '' });

            } else {
                console.log("## onPressEmailDuplCheck error");
                let message = string.emailDuplCheckError;
                if (result == 'INVALID_EMAIL') message = string.emailDuplCheckErrorInvalidEmail;
                if (result == 'ALREADY_EXIST') message = string.emailDuplCheckErrorEmailDupl;

                this.formRef.current.setFields([{
                    name: 'email',
                    errors: [message]
                }]);
            }

        }).catch(e => {
            console.log("## userPhoneauthCheck error: " + e)
        });

    }

    onPressSendCertiCode = () => {
        const string = this.string;
        
        const form = this.formRef.current;
        const formPhone = form.getFieldValue("phone").replace(/[^0-9]+/g,'');
        form.setFields([{ name: 'phone', value: formPhone }]);

        if (this.state.certiVerified) return;
        if (this.state.onTimer) return;

        if (formPhone == undefined || formPhone == '' || formPhone.length < 9) {
            form.setFields([{
                name: 'phone',
                errors: [string.signUpRuleMsg08]
            }]);
            return;
        }
        httpPost(httpUrl.userPhoneauthGenerate, [], { phone: formPhone }).then((result) => {
            console.log("## userPhoneauthGenerate result: " + JSON.stringify(result));
            result = result.data.token;
            this.certiCodeToken = result;
            if (result && result != '') {
                this.setState({ onTimer: true, phoneEditable: false });
                this.interval = setInterval(
                    () => {
                        if (this.state.timer == 0) {
                            clearInterval(this.interval);
                            this.setState({ onTimer: false, timer: 180 });
                            return;
                        }
                        this.setState({ timer: this.state.timer - 1 });
                    },
                    1000
                );
            } else {
                form.setFields([{
                    name: 'phone',
                    errors: [string.certiCodeSendFail]
                }]);
                console.log("## userPhoneauthGenerate server error");
            }

        }).catch(e => {
            console.log("## userPhoneauthGenerate error: " + e)
        });
    }

    onPressCheckCertiCode = () => {
        const string = this.string;
        
        if (this.state.certiVerified) return;
        if (!this.state.onTimer) return;

        const form = this.formRef.current;
        const formPhone = form.getFieldValue("phone").replace(/[^0-9]+/g,'');
        const formCertiCode = form.getFieldValue("certiCode");

        if (formCertiCode == undefined || formCertiCode == '') {
            form.setFields([{
                name: 'certiCode',
                errors: [string.certiCodeFail]
            }]);
            return;
        }

        httpGet(httpUrl.userPhoneauthCheck, [this.certiCodeToken, formCertiCode], {}).then((result) => {
            console.log("## userPhoneauthCheck result: " + JSON.stringify(result));
            result = result.data;

            if (result.responseCode == "SUCCESS") {
                this.setState({ certiVerified: true });
            } else {
                console.log("## userPhoneauthCheck server error");

                let message = string.certiCodeWrong;
                if (result.responseCode == 'EXPIRED') message = string.certiCodeExpired;

                form.setFields([{
                    name: 'certiCode',
                    errors: [message]
                }]);
            }

        }).catch(e => {
            console.log("## userPhoneauthCheck error: " + e)
        });
    }

    maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }

    handleSubmit = (values) => {
        const string = this.string;
        
        console.log('handleSubmit');
        const form = this.formRef.current;

        console.log('handleSubmit2');
        // 왜인지 getFieldValue로 비밀번호 값이 안나옴
        // let password = form.getFieldValue("password") + form.getFieldValue("email");
        let password = this.password + form.getFieldValue("email");
        let secPassword = form.getFieldValue("securityPassword") + form.getFieldValue("email");

        let passwordHash = crypto
        .createHash('sha256')
        .update(password, 'utf8')
        .digest()
        .toString('hex');

        let secPasswordHash = crypto
        .createHash('sha256')
        .update(secPassword, 'utf8')
        .digest()
        .toString('hex');

        httpPost(httpUrl.signUp, [], {
            deviceId: 'web',
            id: form.getFieldValue("email"),
            mnemonic: 'web',
            password: passwordHash,
            name: form.getFieldValue("name"),
            phone: form.getFieldValue("phone"),
            recommender: this.state.recommendCode,
            securityPassword: secPasswordHash,
    
        }).then((result)=>{
            console.log("## signUp result: " + JSON.stringify(result));
            result = result.data;      
            
            if (result.result){
               this.setState({
                step: 6
               })
            } else {
                let message = string.signUpErrorMessage;
                if(result.reason == 'DUPLICATE') message = string.signUpErrorIdDuplication;
                else if(result.reason == 'WRONG_EMAIL_TYPE') message = string.signUpErrorInvalidEmailType;
                else if(result.reason == 'MAX_PHONE') message = string.signUpErrorMaxPhone;
                
                Modal.info({
                    title: string.signUpFailTitle,
                    content: (
                    <div className="signup-result">
                        {message}
                    </div>
                    ),
                    onOk() {},
                });
            }
        
        }).catch(e=>{
        console.log ("## signUp error: " + e)
        });
    }

    // agreementModal = () => {
    //     const string = this.string;        
    //     Modal.confirm({
    //       width: '80vw',
    //       title: string.policyTitle,
    //       content: (
    //         <div className="agreement-content">
    //             {string.policyContent.map((value, index) => {
    //                 let result = [];
    //                 if (value.title)
    //                     result.push(
    //                     <div key={'title_' + index} className="agreement-content-title">
    //                         {value.title}
    //                     </div>
    //                     );
    //                 if (value.content)
    //                     result.push(
    //                     <div key={'content_' + index} className="agreement-content-body">
    //                         {value.content}
    //                     </div>
    //                     );
    //                 return result;
    //             })}
    //         </div>
    //       ),
    //       okText : string.ok,
    //       cancelText:string.cancel,
    //       onOk :()=>{
    //         this.setState({ckAutoLogin: true})
    //       },
    //       onCancel :()=> {
    //         this.setState({ckAutoLogin: false})
    //     },
    //     });
    //   }

    resendEmail = () => {
        const string = this.string;
        this.setState({
            resendEmailAddress: this.state.email
        })
        
        if (this.state.resendEmailAddress == null) {
            this.setState({resendEmailError: string.emailRule03})
            return;
        }
        let resendEmailAddress = this.state.resendEmailAddress.trim()
        if (resendEmailAddress == '') {
            this.setState({resendEmailError: string.emailRule03})
            return;
        }
        var regExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
        if (!regExp.test(resendEmailAddress)) {
            this.setState({resendEmailError: string.emailRule03})
            return;
        }
        httpGet(httpUrl.userResendEmailSignup, [resendEmailAddress]).then((result)=>{
            result = result.data;
            if (result == "SUCCESS"){
                this.setState({resendEmailVisible: false}, ()=>{
                    Modal.info({
                        content: (
                        <div className="signup-result">
                            {string.emailResent}
                        </div>
                        ),
                        onOk() {},
                    });
                })
            } else {
                let message = string.emailResendFailed;
                if (result == "INVALID_EMAIL") message = string.invalidEmail;
                if (result == "ALREADY_VERIFIED") message = string.emailAlreadyVerified;
                this.setState({resendEmailVisible: false}, ()=>{
                    Modal.info({
                        content: (
                        <div className="signup-result">
                            {message}
                        </div>
                        ),
                        onOk() {},
                    });
                })
            }
      
        }).catch(e=>{
            console.log ("## resendEmail error: " + e);
        });

    }

    onChangeInput = (e) => {
        console.log(e)
        const { id, value } = e;
        this.setState({ [id] : value })
    }

    nextStep = (step) => {
        const string = this.string;
        const reg_num = /^[0-9]+$/;      
        switch(step) {
            case 1 :
                if (!this.state.ckAutoLogin) {                    
                    alert(string.needAgreement);
                    return;
                }
            this.setState({ step : this.state.step + 1 })
            break;
            
            case 2 :
                // 이메일체크
                let reg_email = /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;
                if (!reg_email.test(this.state.email)) {
                    alert(string.emailStrength)
                    return;
                }
                // 비밀번호체크
                const pw = this.password;
                let strongRegex = new RegExp(
                    "^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).{8,15}$"
                );
                if (!strongRegex.test(pw)) {
                    alert(string.inputPasswordRule);
                    return;
                }
                if (!this.state.emailDuplCheck) {
                    alert(string.needEmailDuplCheck);
                    return;
                }
                if (this.password !== this.passwordCheck) {
                    alert(string.differentPw);
                    return;
                }
            this.setState({ step : this.state.step + 1 })
            break;

            case 3: 
                if (this.state.name === "" || this.state.name === null) {
                    alert(string.needInputAddressbookMemo);
                    return;
                }
                if (this.state.phone === "" || this.state.phone === null) {
                    alert(string.phoneFail);
                    return;
                }       
       
                if (!reg_num.test(this.state.phone) || this.state.phone.length < 9){
                    alert(string.phoneFail);
                    return;
                }          
            this.setState({ step : this.state.step + 1 })
            break;

            case 4:      
                if (this.state.securityPassword === "" || this.state.securityPassword === null) {
                    alert(string.passwordLengthFail);
                    return;
                }         
                if (!reg_num.test(this.state.securityPassword)) {
                    alert(string.secPasswordStrength);
                    return ;
                }
                if (this.state.securityPassword !== this.state.securityPasswordCheck) {
                    alert(string.secPasswordConfirmFail);
                    return;
                }
            this.setState({ step : this.state.step + 1 })
            break;
        }
    }


    render() {
        const string = this.string;
        
        let { emailDuplCheck, phoneEditable, certiVerified } = this.state;
        const numList = [];
        for (let i=1; i<7; i++){
            numList.push(i)
        };
        return (
            <div>
            <div className="wrapper">
                <div className="logForm">
                    <div className="logo" onClick={()=>this.props.history.push('/currency')}>
                        <img src={require('../img/loginPage/logo.png')} />                        
                    </div>
                    <div className="form">
                        <div className='title-wrap2'>
                            <span 
                                className='loginTitle' 
                                onClick={()=>{
                                    this.props.history.push('/login');
                            }}>{string.myinfoLogin}</span>
                            <span className='logTitle'>{string.signUp}</span>
                        </div>
                        <div className='bar'/>                    
                        <div className="numSection">
                           {numList.map((value) => {
                            return(
                                <div className={value === this.state.step ? 'activeNum' : 'normalNum'}>                                    
                                    {value}
                                </div>
                            )
                           })}                                
                        </div>   
                        {this.state.step === 1 && (                                      
                            <div className="signUpContent">
                                <p className="notiText">
                                    {string.termText}                      
                                </p>
                                <div className="termSection">
                                    <div className="termText">{string.myinfoPolicy}</div>
                                    <div className='checkTerm'>
                                    <Checkbox 
                                        checked={this.state.ckAutoLogin} 
                                        // onChange={e=>this.setState({ckAutoLogin: e.target.checked})} 
                                        onChange={()=>{this.setState({modalOpen : !this.state.modalOpen})}}
                                        style={{fontFamily:'NotoSansRegular', fontSize: 12}}>
                                            {string.iAgree}
                                    </Checkbox>                                  
                                        
                                    </div>
                                </div>

                                <div className="term">
                                {string.policyContent.map((value, index) => {
                                    let result = [];
                                    if (value.title)
                                        result.push(
                                        <div key={'title_' + index} className="agreement-content-title">
                                            {value.title}
                                        </div>
                                        );
                                    if (value.content)
                                        result.push(
                                        <div key={'content_' + index} className="agreement-content-body">
                                            {value.content}
                                        </div>
                                        );
                                    return result;
                                })}
                                </div>
                                {/* 모달 */}
                                <Modal
                                centered
                                title= {string.policyTitle}
                                visible={this.state.modalOpen}
                                onOk = {()=>{
                                    this.setState({ckAutoLogin: true,modalOpen:false })
                                  }}
                                onCancel ={()=> {
                                    this.setState({ckAutoLogin: false,modalOpen:false})
                                }}>
                                <div className="agreement-content">
                                      {string.policyContent.map((value, index) => {
                                          let result = [];
                                          if (value.title)
                                              result.push(
                                              <div key={'title_' + index} className="agreement-content-title">
                                                  {value.title}
                                              </div>
                                              );
                                          if (value.content)
                                              result.push(
                                              <div key={'content_' + index} className="agreement-content-body">
                                                  {value.content}
                                              </div>
                                              );
                                          return result;
                                      })}
                                  </div>
                                </Modal>
                            </div>                            
                        )}                       
                       <Form onFinish={this.handleSubmit} ref={this.formRef}>    
                        {this.state.step === 2 && (   
                                <div className="signUpContent">
                                <div className='formItem-box'>
                                    <p>{string.id} 
                                    {emailDuplCheck && (<span className='duplchecktext' style={{color:'#35b7ae', fontWeight:'bold'}}>*{string.idOk}</span>)}</p> 
                                    <div className='input-flex'>
                                    <Form.Item name="email">                                       
                                        <Input
                                            className='signUp-input'
                                            placeholder={string.inputEmail}           
                                            value={this.state.email}                                         
                                            onChange={(e)=>this.onChangeInput(e.target)}     
                                            disabled={emailDuplCheck}                                                                   
                                        />                                                           
                                    </Form.Item>
                                    <Button                                            
                                        onClick={this.onPressEmailDuplCheck}
                                        disabled={emailDuplCheck}>                                          
                                        {string.duplCheck}
                                    </Button>
                                    </div>
                                </div>            

                                <div className='formItem-box'>                                
                                    <p>{string.password} </p>                                                                     
                                    <Form.Item name="password" >
                                        <Input
                                            type="password"
                                            className='signUp-input'
                                            placeholder={string.passwordStrength0}                                              
                                            onChange={(e)=>
                                                this.password = e.target.value
                                               }                                                             
                                        />      
                                         <span className='pw-sub-text'>{string.inputPasswordRule}</span>                                
                                    </Form.Item>                                   
                                </div>      
                        
                                <div className='formItem-box'>
                                    <div className='formItem-box'>
                                    <p>{string.passwordConfirm}</p>                                   
                                    </div> 
                                    <Form.Item name="passwordCheck" >
                                        <Input
                                           type="password"
                                            className='signUp-input'
                                            placeholder={string.passwordStrength0}    
                                            onChange={(e)=>
                                                this.passwordCheck = e.target.value
                                               }                                                              
                                        />     
                                        <span className='pw-sub-text'>{string.inputPasswordRule}</span>                                 
                                    </Form.Item>                                    
                                </div>                                
                            </div>
                        )}
                        {this.state.step === 3 && (
                            <div className='signUpContent'>
                                 <div className='formItem-box'>                                    
                                    <p>{string.name}</p>                     
                                    <Form.Item 
                                        name="name">
                                        <Input                                            
                                            className='signUp-input'
                                            placeholder={string.needInputAddressbookMemo}        
                                            value={this.state.name}           
                                            onChange={e=>this.onChangeInput(e.target)}                                                                                                                            
                                        />                                      
                                    </Form.Item>
                                </div>  
                                <div className='formItem-box'>                                    
                                    <p>{string.phone}</p>                     
                                    <Form.Item 
                                        name="phone">
                                        <Input                                            
                                            className='signUp-input'
                                            maxLength='11'
                                            placeholder={string.inputPhone}
                                            value={this.state.phone}           
                                            onChange={(e)=>this.onChangeInput(e.target)}                         
                                        />                                      
                                    </Form.Item>
                                </div>       
                            </div>
                        )}
                        {this.state.step === 4 && (
                            <div className='signUpContent'>
                                <div className='formItem-box'>
                                    <div className='formItem-box'>
                                    <p>{string.secPassword}</p>                                   
                                    </div>                                    
                                    <Form.Item 
                                        name="securityPassword"  
                                        rules={[{ 
                                            required: true,
                                            message: string.inputSecPassword
                                        }]}>
                                        <Input
                                            type="password"
                                            maxLength='4'
                                            className='signUp-input'
                                            placeholder={string.inputSecPassword}           
                                            value={this.state.securityPassword}           
                                            onChange={(e)=>this.onChangeInput(e.target)}                                                          
                                        />                                      
                                    </Form.Item>
                                </div>    
                                <div className='formItem-box'>
                                    <div className='formItem-box'>
                                    <p>{string.secPasswordConfirm}</p>                                  
                                    </div>                                    
                                    <Form.Item 
                                        name="securityPasswordCheck" 
                                        rules={[{ 
                                            required: true,
                                            message: string.inputSecPasswordConfirm
                                        }]}>
                                        <Input
                                            type="password"
                                            maxLength='4'
                                            className='signUp-input'
                                            placeholder={string.inputSecPasswordConfirm}     
                                            value={this.state.securityPasswordCheck}           
                                            onChange={(e)=>this.onChangeInput(e.target)}                                                            
                                        />                                      
                                    </Form.Item>
                                </div> 
                            </div>
                        )}
                        {this.state.step === 5 && (
                            <div className='signUpContent'>
                                <div className='formItem-box'>                                   
                                    <p>{string.inputRecommendCode}</p>                                                                    
                                    <Form.Item 
                                        name="recommender">
                                        <Input                                           
                                            maxLength='4'
                                            className='signUp-input'
                                            placeholder={string.inputRecommendCode}      
                                            value={this.state.recommendCode}           
                                            onChange={(e)=>this.onChangeInput(e.target)}                                                          
                                        />                                      
                                    </Form.Item>
                                </div>                                
                            </div>
                        )}
                        {this.state.step === 6 && (
                            <div className='signUpContent finish'>
                               <p className='finishTitle'>Welcome!</p>   
                               <p className='finishText1'>{string.appTitle}{string.setupFinish}</p>   
                               <p className='finishText1'>{string.setupFinish2}</p>                     
                            </div>
                        )}
                        {this.state.step === 6 ? (
                            <Link to ='/login'>
                              <div className="finishBtn">{string.login}</div>
                            </Link>
                        ):(
                            <div className='nextBtn-container'>                      
                            <Button
                                type="submit" 
                                className="nextBtn"
                                disabled={this.state.step === 1 ? true :false}
                                onClick={()=>this.setState({
                                    step : this.state.step - 1    
                                })}
                               >
                                &larr; {string.prev}
                            </Button>                                                     
                            <Button
                                type="submit" 
                                className="nextBtn"
                                onClick={()=>
                                    this.state.step === 5 
                                    ? this.handleSubmit()
                                    : this.nextStep(this.state.step)}
                               >
                                {string.next} &rarr;
                            </Button>
                            </div>                           
                        )}
                     
                        </Form>
                        
                    </div>
                </div>
                </div>
                </div>
        );
    }
}

export default withRouter(connect(null, null)(SignUp));