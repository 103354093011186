import { Modal, Button } from 'antd';
import React from 'react';
import { withAlert } from 'react-alert';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import "../../css/main.scss";
import "../../css/myWallet.scss";
import "../../css/signUp.scss";
import Language from '../../lib/util/language';
var QRCode = require("qrcode.react");

class AddressDialog extends React.Component {
  constructor(props) {
    super(props);
    this.string = Language.getString();
    this.state = {
      price: '',
    };
  }

  componentDidMount() {
    console.log(JSON.stringify(this.props.coin, null, 4));
  }

  render() {
    const string = this.string;
    return (
      <Modal visible={this.props.visible} onCancel={() => this.props.onCancel()} cancelText={string.close} footer={[]}>
        <div className="receive-qr-title">
          {string.receivedAddress}
        </div>
        <div style={{textAlign: 'center'}}>
          <QRCode  
            size = {200}    
            value={this.state.sele == '' ? this.props.address : this.props.address + "?value=" + this.state.price + "&coinType=" + this.props.coin.symbol}
          />
        </div>
        <div className="receive-qr-address">
          {this.props.address.replace("(Destination Tag)", "\n(Destination Tag)")}
        </div>
        <div style={{textAlign: 'center'}}>
          <CopyToClipboard text={this.props.address}
            onCopy={() => this.props.alert.show(string.mywalletAddressCopied)}>
            <div className="mainColorBtnLarge">{string.copy}</div>
          </CopyToClipboard>

        </div>

      </Modal>
    );
  }
}

export default withAlert()(AddressDialog);
