import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'antd/dist/antd.css';
import * as serviceWorker from './serviceWorker';
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import { Spin } from "antd";
import { createStore } from "redux";
import reducer from "./reducer";
import { Provider } from "react-redux";
import "./css/signUp.scss";
import "./css/signUp_m.scss";
import "./css/notosans.css";
import "./css/muli.css";
import "./css/main.scss";
import "./css/main_m.scss";
import Const from "./const";

import { httpGet, httpUrl, httpPost } from "./api/httpClient";
import { reactLocalStorage } from "reactjs-localstorage";
import Language from './lib/util/language';

let store = createStore(reducer);

const options = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_CENTER,
  timeout: 3000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE
}

Language.getServerLocale();
global.currency = 0;
global.loadingCount = 0;
global.componentId = '';
global.exchangeRate = [1, 1, 1, 1];

const localLan = reactLocalStorage.get(Const.appCode + '#language');
let lan = null;
if (localLan) lan = JSON.parse(localLan)
 if (lan !== null && (lan == 'ko' || lan == 'en' || lan == 'ja')) {
    global.language = lan;
    console.log('## get language setting: ' + global.language)
  } else {
    console.log('## no language settings.');
    global.language = Language.getServerLocale();
  }

ReactDOM.render(
  // <React.StrictMode>
    <AlertProvider template={AlertTemplate} {...options}>
      <Provider store={store}>
        <App />
        <div
          id="loadingSpinner"
          style={{
            display: "none",
            position: "fixed",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.3)",
            top: 0,
            left: 0,
            textAlign: "center",
            zIndex: 99999
            // pointerEvents: "none"
          }}>
          <Spin style={{ position: "absolute", top: "50%", zIndex: 99999 }} />
        </div>
      </Provider>
    </AlertProvider>
  // </React.StrictMode>
  ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
