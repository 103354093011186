import { Form, Input, Checkbox, Button, Modal } from 'antd';
import React from 'react';
import { withAlert } from 'react-alert';
import "../css/signUp.scss";
import "../css/main.scss";
import "../css/myWallet.scss";
import "../css/myWallet_m.scss";
import { httpGet, httpUrl, httpPost } from "../api/httpClient";
import crypto, { timingSafeEqual } from 'crypto';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { setCoinList, setCoinBalance, setCoinAddress } from '../actions';
import { Route, withRouter } from "react-router-dom";
import { formatBalance } from "../util/coinUtil";
import NumberFormat from '../lib/util/numberformat';
import Language from '../lib/util/language';
import StringUtil from '../lib/util/stringUtil';
import Coin from '../lib/coin/coin';
import AddressValidation from '../lib/util/addressValidation'

const FormItem = Form.Item;

class TradeSend extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.string = Language.getString();

    this.state = {
      sendAmount: '',
      targetAddress: '',

      checkConfirm: false,
      destinationTag: '',

      secPassword: '',
      otp: '',

      memo: '',

      isShowCoinSelect: false,

      selectedCoinType: this.props.selectedCoinType,

      sendConfirmVisible: false,
      sendInfo: {
      },
      sendTarget: {
      }
    };
  }

  initData = () => {
    this.formRef.current.resetFields()
  };

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  onClickSendCoin = (values) => {
    const string = this.string;
    let errors = {};
    let coin = this.props.coinList.find(x => x.coinType == this.state.selectedCoinType);

    // 수수료 계산

    httpGet(httpUrl.walletCheckFee, [this.state.selectedCoinType, values.targetAddress]).then((result) => {
      console.log(JSON.stringify(result));
      // result = result.data;

      let fee;
      let feeCoin = this.props.coinList.find(x => x.coinType == result.data.feeCoinType);

      if (result.result == 'SUCCESS') {
        fee = result.data.fee / Coin.getCoinUnit(result.data.feeCoinType).base;

        console.log("## fee: " + fee);

        if (feeCoin.balance < fee) {
          this.props.alert.show(string.sendCoinLowFee + " (" + fee + " " + feeCoin.symbol + ")")
          return;
        }

        // 코인 전송 시작

        console.log("##### Sending coin");

        let sendAmount = parseFloat(values.sendAmount);
        let sendAmountBase = Math.round(sendAmount * Coin.decimalPlace) / Coin.decimalPlace * Coin.getCoinUnit(coin.coinType).base;
        let secPasswordHash = crypto.createHash('sha256').update(values.secPassword + this.props.loginInfo.id, 'utf8').digest().toString('hex');

        let target = {
          amount: sendAmountBase,
          coinType: coin.coinType,
          to: values.targetAddress,
          destinationTag: 0,
          otpCode: values.otp,
          securityPassword: secPasswordHash,
          memo: '',
          fee: fee,
        };

        console.log('## send target: ' + JSON.stringify(target));

        // this.sendCoin(target)
        this.confirmSendCoin(coin, target, feeCoin);

      } else {
        console.log("## walletCheckFee failed")
        this.props.alert.show(string.feeRequestFailed);
        return;
      }
    }).catch(e => { console.log("## walletCheckFee failed: " + e) });
  }

  confirmSendCoin = (coin, target, feeCoin) => {
    console.log('##### confirmSendCoin');
    console.log(coin);
    console.log(target);

    this.setState({
      sendConfirmVisible: true,
      sendInfo: {
        coinName: coin.name,
        coinSymbol: coin.symbol,
        address: target.to,
        amount: Math.round(target.amount / Coin.getCoinUnit(this.state.selectedCoinType).base * Coin.decimalPlace) / Coin.decimalPlace,
        fee: target.fee,
        feeUnit: feeCoin.symbol,
        destinationTag: target.destinationTag,
      },
      sendTarget: target
    })
  }

  sendCoin = () => {
    const coinType = this.state.sendTarget.coinType;
    this.setState({sendConfirmVisible: false, sendInfo: {}, sendTarget: {}})
    const string = this.string;
    httpPost(httpUrl.walletSend, [], this.state.sendTarget).then((result) => {
      console.log(JSON.stringify(result));
      result = result.data;

      if (result == 'SUCCESS' || result == 'APPROVAL') {
        console.log("## sent success");
        Modal.info({
          title: string.sendCoinSuccessTitle,
          content: (
            <div>
              {string.sendCoinSuccess}
            </div>
          ),
          onOk() { },
        });
        this.initData();
        this.props.requestBalance(0);
        return;
      } else {
        let message = string.sendCoinFailed;
        if (result == 'INVALID_ADDRESS') message = string.sendCoinFailedInvalidAddress;
        else if (result == 'INSUFFICIENT_BALANCE') message = string.sendCoinFailedInsufficientBalance;
        else if (result == 'LOCKED') message = string.sendCoinFailedLocked + "\n" + string.sendCoinFailedDetail;
        else if (result == 'INVALID_OTP') message = string.sendCoinFailedInvalidOtp;
        else if (result == 'INVALID_SECURITY_PASSWORD') message = string.sendCoinFailedInvalidSecPassword;
        else if (result == 'REQUIRED_OTP') message = string.sendCoinFailedOtpRequired;
        else if (result == 'LIMIT_EXCEED') message = string.sendCoinFailedLimitExceed;
        else if (result == 'INVALID_DESTINATIONTAG') message = string.sendCoinFailedInvalidDesTag;
        else if (result == 'REQUIRED_OTP_DAILY') message = string.sendCoinFailedOtpRequired;
        else if (result == 'CANNOT_SEND_ORGANIZATION') message = string.sendCoinFailedOrganization;

        Modal.info({
          title: string.sendCoinFailedTitle,
          content: (
            <div>
              {message}
            </div>
          ),
          onOk() { },
        });
      }

    }).catch(e => { });
  };

  setAddress = (address, destinationTag) => {
    this.setState({ targetAddress: address, destinationTag: destinationTag })
  }

  render() {
    const string = this.string;
    let { errors = {} } = this.state;
    return (
      <div className="trade-send-container">
        <div className='trade-send-title-box'>
          <div className="trade-send-title">
            {string.send}
          </div>
          <div className="trade-send-button mainColorBtnLarge" onClick={() => this.props.close()}>
            {string.menuTradeInfo}
          </div>
        </div>
        <Form onFinish={this.onClickSendCoin} ref={this.formRef}>
          <div>
            <FormItem
              name="targetAddress"
              rules={[{ required: true, message: '주소를 입력해주세요' }]}>
              <Input placeholder={string.inputAddress} />
            </FormItem>
          </div>
          <div>
            <FormItem
              name="sendAmount"
              rules={[{
                validator: (rule, value, cb) => {
                  if (isNaN(value)) cb('숫자를 입력해주세요.');
                  else {
                    const modValue = Math.floor(value * Coin.decimalPlace) / Coin.decimalPlace;
                    if (value == '') cb('보낼 수량을 입력해주세요.');
                    else if (modValue == NaN) cb('숫자를 입력해주세요.');
                    else if (modValue <= 0) cb(string.sendCoinInputAmountLow);
                    else cb();
                  }
                }
              }]}>
              <Input placeholder={string.inputAmount} />
            </FormItem>
          </div>
          <div>
            <FormItem
              name="secPassword"
              rules={[{ required: true, message: '보안비밀번호를 입력해주세요' }]}>
              <Input type="password" placeholder={string.secPassword} maxLength={4} />
            </FormItem>

          </div>
          <div>
            {(this.props.loginInfo.securityLevel == 2) && (
              <FormItem
                name="otp"
                rules={[{ required: true, message: 'OTP를 입력해주세요' }]}>
                <Input type="password" placeholder={string.otp} maxLength={6} />
              </FormItem>
            )}

          </div>
          <div>

            <FormItem
              name="checkConfirm"
              rules={[{
                validator: (rule, value, cb) => {
                  if (this.state.agreementCheck) cb();
                  else cb('송금 유의사항확인에 동의해 주십시오');
                }
              }]}
            >
              <Checkbox checked={this.state.agreementCheck} onChange={(e) => this.setState({ agreementCheck: e.target.checked })}>
                {string.sendCoinCautionConfirm}
              </Checkbox>
            </FormItem>

          </div>
          <div>
            {string.sendCoinCautionConfirmDescriptionEx}
          </div>
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <button className="signInBtn" htmltype="submit">
              {string.send}
            </button>
          </div>
        </Form>
        {this.state.sendConfirmVisible && (
          <Modal className="trade-send-confirm-modal" visible={this.state.sendConfirmVisible} onOk={this.sendCoin} onCancel={() => this.setState({ sendConfirmVisible: false })} cancelText={string.close} okText={string.ok}>
            <div className="trade-send-confirm-title">{string.sendCoinConfirmDlgTtl}</div>
            <div>{string.sendCoinConfirmMessage}</div>
            <div className="trade-send-confirm-item">{string.address}:</div>
            <div className="trade-send-confirm-item" style={{ marginLeft: '10px', color: '#693cff' }}>{this.state.sendInfo.address}</div><br />
            <div className="trade-send-confirm-item">{string.value}:</div>
            <div className="trade-send-confirm-item" style={{ marginLeft: '10px' }}>{this.state.sendInfo.amount}</div>
            <div className="trade-send-confirm-item">{this.state.sendInfo.coinSymbol}</div><br />
            <div className="trade-send-confirm-item">{string.fee}:</div>
            {this.state.sendInfo.fee == 0 ? (
              <div className="trade-send-confirm-item" style={{ marginLeft: '10px' }}>{string.exemption}</div>
            ) : (
              <div className="trade-send-confirm-item" style={{ marginLeft: '10px' }}>
                <div className="trade-send-confirm-item">{this.state.sendInfo.fee}</div>
                <div className="trade-send-confirm-item">{this.state.sendInfo.feeUnit}</div>
              </div>
            )}
          </Modal>
        )}
      </div >
    );
  }
}

const mapStateToProps = (state) => {
  const { coinList, loginInfo } = state
  return { coinList, loginInfo }
};

export default withAlert()(connect(mapStateToProps)(TradeSend));
