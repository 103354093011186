import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setCoinList, setCoinBalance, setCoinAddress, setCoinCurrency } from '../actions';
import { Route, withRouter } from "react-router-dom";
import { httpGet, httpUrl } from '../api/httpClient';
import con from '../const';
import { formatDateDay } from "../lib/util/dateUtil";
import Language from '../lib/util/language';
import { formatBalance } from "../util/coinUtil";
import NumberFormat from '../lib/util/numberformat';

class Currency extends Component {
  constructor(props) {
    super(props);
    this.string = Language.getString();
    this.state = {

        noticeData: [], 
        coinCurrencyUpdateTime: '',
    }
  }
  
  componentDidMount() {
      this.requestCurrency();
    
    httpGet(httpUrl.noticeList, [10, 1, global.language]).then((result)=>{
      let noticeList = result.data.noticeList == null ? [] : result.data.noticeList;
      if (noticeList && noticeList.length > 3) noticeList = noticeList.slice(0,3)
      this.setState({noticeData: noticeList});
    }).catch((e)=>{
      console.log("Get Notice Error: " + e);
    });
  }
  
  componentWillUnmount() {
  }

  requestCurrency = (hide) => {
    console.log("## Request currency");
    return new Promise((resolve, reject) => {
      httpGet(httpUrl.currencies, [], {}, hide).then((result)=>{        
        this.props.setCoinCurrency(result.data);
        this.setState({coinCurrencyUpdateTime: new Date().toLocaleTimeString(), currencyLoaded: true})
        resolve();

      }).catch(e => {
        console.log("## requestCurrency error: " + e);
        reject(e);
      });
    })
  }

  onClickNotice = (contents) => {
    // Navigation.push(this.props.componentId, {
    //   component: {
    //     name: 'navigation.Notice',
    //     passProps: {
    //     },
    //     options: {}
    //   }
    // });
    // if (contents != '') {
    //   Navigation.push(this.props.componentId, {
    //     component: {
    //       name: 'navigation.WebviewDialog',
    //       passProps: {
    //         title: string.myinfoNotice,
    //         type: 'html',
    //         body: contents ? contents.replace(/@loginid/g, this.props.loginInfo.id) : '',
    //         close: () => {
    //           Navigation.pop(this.props.componentId); 
    //         }
    //       },
    //       options: {
    //         topBar: { drawBehind: true, visible: false },
    //         screenBackgroundColor: 'transparent',
    //         modalPresentationStyle: 'overCurrentContext'
    //       }
    //     }
    //   });

    // }
  }

  onClickCoinInfo = (coin) => {
    // if (this.state.graphOpen == coin.coinType) this.setState({graphOpen: 0});
    // else this.setState({graphOpen: coin.coinType});
  }

  renderFlatList = (item) => {
    if (item.symbol == "UTC") return; // UTC는 시세정보에서 제외

    let changeColor = '#000000';
    let changeBackColor = '#ffffff';
    let arrowImage = require('../img/common/none.png');
    
    if (item.change < 0) {
      changeColor = '#f11015';
      changeBackColor = '#ffffff';
      arrowImage = require('../img/common/arrow_down.png');
    }
    if (item.change > 0) {
      changeColor = '#0ab512';
      changeBackColor = '#ffffff';
      arrowImage = require('../img/common/arrow_up.png');
    }
    const color ={};
    const string = this.string;
    return (
        <div className="currency-coin-container">
            <div className="currency-coin-image">
                <img src={item.image} />
            </div>
            <div className="currency-coin-symbol">
                <div className="currency-coin-symbol-name">
                {item.name}
                </div>
                <div className="currency-coin-symbol-mark">
                {item.symbol}
                </div>
            </div>
            <div className="currency-coin-price">
                <div className="currency-coin-price-num">
                {(item.price).toFixed(this.state.currency == 0 && item.price > 100 ? 0 : 2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                </div>
                <div className="currency-coin-price-unit">
                    {string.krw1}
                </div>
            </div>
            <div className="currency-coin-change">
                <div className="currency-coin-change-num" style={{color: changeColor}}>
                {item.change}%
                </div>
                <div className="currency-coin-change-arrow">
                    <img src={arrowImage}/>
                </div>
            </div>

        </div>
    );
  }

  render() {
      const string = this.string;
      const color = {};
      const layout = {};
    return (
        <div className="currency-content">
            <div className="currency-top-title">
                {string.myinfoNotice}
            </div>
            <div style={{float: 'right'}} className="signInBtn" onClick={()=>this.props.history.push('/notice')}>
              {string.more}
            </div>
            {/* <div className="currency-top-more" onClick={this.requestCurrency}>
                <div>{string.more}</div>
            </div> */}
            <div className="divider"></div>
            <div className="currency-notice-container">
                {this.state.noticeData instanceof Array && this.state.noticeData.map(value => {
                    let content = (
                    <div className="currency-notice-item">
                        <div className="currency-notice-no">
                            {value.idx}
                        </div>
                        <div className="currency-notice-title">
                            {value.title}
                        </div>
                        <div className="currency-notice-date">
                            {formatDateDay(value.createDate)}
                        </div>
                    </div>
                    )
                    return content;
                })}
                {/* <div className="divider-slim"/> */}
            </div>

            <div className="currency-top-title">
                {string.menuCurrency}
            </div>
            <div className="currency-top-time">{this.state.coinCurrencyUpdateTime} {string.updated} {string.criteriaCoinmarketcap}</div>
            <div className="currency-top-refresh" onClick={this.requestCurrency}>
                <img src={require('../img/transaction/refreshBtn.png')}/>
            </div>
            <div className="divider"></div>

            {(this.props.coinList instanceof Array) && this.props.coinList.map(value => {
                return this.renderFlatList(value);
            })}
        </div>
    )
  }
}

const mapStateToProps = state => {
    const { coinList, loginInfo } = state;
    return { coinList, loginInfo };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators({ setCoinList, setCoinBalance, setCoinAddress, setCoinCurrency }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Currency));