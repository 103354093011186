import { Form } from 'antd';
import React from 'react';
import "../css/main.scss";
import "../css/signUp.scss";

const FormItem = Form.Item;


class Mobile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    
    goApp = () => {
        if (/Android/i.test(navigator.userAgent)) {
            global.location.href = 'market://details?id=com.p2pwallet';
        } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
            global.location.href = 'itms://itunes.apple.com/app/id1498737637?mt=8'
        } else {
            alert("모바일에서 실행해주세요.");
        }
    }
    render() {
        console.log(this.props.userinfo)
        
        return (
            <div className="wrapperMain" style={{height: '500px', textAlign: 'center', paddingTop: '100px'}}>
                        <div className="mainLogo" style={{marginRight: '0px'}}></div>
                        <div className="mobile-message">
                        모바일에서는 앱을 이용해 주세요
                        </div>
                        <div className="mobile-button" onClick={this.goApp}>앱 실행하기</div>
            </div>
        )
    }
}

export default Mobile;