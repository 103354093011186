export default {

  appTitle: 'OTC market',
  /********* Menu********/
  menuCurrency: 'Market',
  menuMywallet: 'Wallet',
  menuEvent: 'Event',
  menuTrade: 'Transaction',
  menuTradeSend: 'Send',
  menuTradeInfo: 'Trade',
  menuExchange: 'Exchange',
  menuPayment: 'Payment',
  menuBuyback: 'Buyback',
  menuDApp: 'DApp',
  menuMyInfo: 'My Info',
  menuExchangeInfo: 'Exchange details',

  /******** Sign in******/
  login: 'Login to wallet',
  autoLogin: 'Auto Login',
  saveLoginId: 'Save ID',
  findId: 'Find ID',
  findPassword: 'Find Password',
  findIdPassword: 'Find ID/PW',
  signUp: 'Sign up',
  phone: 'Phone number',
  inputPhone: 'Enter phone number',
  certiCode: 'Authentication number',
  sendCertiCode: 'Send authentication number',
  checkCertiCode: 'Check authentication number',
  inputCertiCode: 'Enter authentication number',
  inputRecommendCode: 'Enter the recommender\'s code (optional).',
  // inputRecommendCodeComment: 'If you enter the recommender's name, you will get 200 UUTT AirDrop.',

  mnemonicTitle: 'Getting a mnemonic code is like getting an ownership of a wallet asset.',
  mnemonicContent1: 'If your mobile phone, ID or password is lost, the mnemonic code can restore your wallet assets.',
  mnemonicContent2: 'Please, use paper and pen to write down the mnemonic code.',
  mnemonicContent3: 'Please, keep it where it is safe.',
  mnemonicContent4: 'Please, don\'t store and share your mnemonics code in an online environment such as mail, photo albums, SNS, etc.',
  readAgreement: 'I read the above well.',
  readViewAgreement: 'Please, check if you have checked the contents.',

  signUpErrorTitle: 'Failed to generate a wallet',
  signUpErrorIdDuplication: 'This email is already registered. Please check your email.',
  signUpErrorInvalidEmailType: 'Email format is invalid. Please check again.',
  signUpErrorMaxPhone: 'You have exceeded the number of registered accounts per phone number.',
  signUpErrorMessage: 'Failed to generate wallet. Please try again in a moment.',
  mnemonicDescription: 'Getting a mnemonic code is like taking ownership of a wallet asset.',
  mnemonicDescriptionDetail: [
    'If your mobile phone, ID or password is lost, the mnemonic code can restore your wallet assets.',
    'Please, use paper and pen to write down the mnemonic code.',
    'Please, keep the mnemonic code where it is safe.',
    'Please, don\'t store and share your mnemonics code in an online environment such as mail, photo albums, SNS, etc.'
  ],
  iAgree: 'I read the above well.',
  captureProhibited: 'Capture is prohibited',
  captureProhibitedDescription: [
    'Do not capture, share, or save.',
    'Third-party malicious access could result in asset loss.',
  ],
  welcomeToWallet: 'Welcome to OTC market',
  getStarted: 'Get started.',
  findPasswordEmailSent: 'An email has been sent to change your password. Please click the link in the email to change your password.',


  /*********MNEMONIC**********/
  mnemonicGuideFindIdPw: [
    //'Please enter the recovery words issued when creating the wallet in turn.',
    'When you sign up, please proceed with authentication with your registered mobile phone number.',
    'Find ID is complete. If you need to change your password, please enter your password below.',
    'The password change is complete. Please proceed with login.',
  ],
  mnemonicGuideFindSecPw: [
    //'Please enter the recovery words issued when creating the wallet in turn.',
    'When you sign up, please proceed with authentication with your registered mobile phone number.',
    'Please enter a new security password.',
    'The security password change has been completed.',
  ],
  mnemonicGuideChangePw: [
    'Please enter your current password and new password.',
    'The password change is complete.',
  ],
  mnemonicGuideChangeSecPw: [
    'Please enter 4 digits of current security password and 4 digits of new security password.',
    'The security password change has been completed.',
  ],
  mnemonicGuideChangeOtp: [
    //'Please enter the recovery words issued when creating the wallet in turn.',
    'When you sign up, please proceed with authentication with your registered mobile phone number.',
  ],
  mnemonicError: 'Failed to find ID.',
  mnemonicErrorWrongSms: 'Text authentication failed.',
  mnemonicErrorWrongUser: 'There is no such user.',
  mnemonicErrorWrongMnemonic: 'The authentication information is invalid.',
  mnemonicErrorFailFindSecPassword: 'Failed to find security password.',
  changeOtpFailed: 'The OTP change failed.',

  /*********common**********/
  unquoted: 'Unquoted',
  address: 'Address',
  destinationTag: 'Destination Tag',
  destinationTagShort: 'Dest. Tag',
  error: 'Error',
  errorMessage: 'An error has occurred. Please try again in a few minutes.',
  coin: 'Coin',
  myCoin: 'My Coin',
  coinType: 'Coin type',
  kind: 'Type',
  value: 'Amount',
  myValue: 'My balance',
  totalValue: 'Current total amount',
  fee: 'Fee',
  localCoinUnit: ['KRW', 'USD', 'CNY', 'JPY'],
  localCoin: 'KRW',
  localCoinBig: 'Billion',
  faster: 'Fast',
  normal: 'Normal',
  prev: 'Previous',
  next: 'Next',
  time: 'Time',
  type: 'Type',
  target: 'Target',
  targetAddress: 'Send address',
  sendAmount: 'Amount to send',
  exchangeAmount: 'Amount of exchange',
  send: 'Send',
  sent: 'Sent',
  sending: 'Sending',
  sendingAddress: 'Sending address',
  receive: 'Receive',
  received: 'Received',
  receiving: 'receiving',
  receivedAddress: 'Received address',
  save: 'Save',
  name: 'Name',
  setting: 'Setting',
  security: 'Security center',
  intro: 'About us',
  cs: 'Customer center',
  submit: 'Submit',

  failed: 'Failed',
  unuse: 'Unused',
  use: 'Ise',
  ok: 'OK',
  cancel: 'Cancel',
  close: 'Close',
  back: 'Back',
  copy: 'Copy address',
  copy2: 'Copy',
  share: 'Share',
  enterPrice: 'Enter the amount',
  enterPriceTitle: 'Enter amount',
  max: 'Max',
  reset: 'Reset',
  updated: 'Update',
  default: 'Default',
  paste: 'Paste',
  captureQr: 'Capture QR',
  captureQrTitle: 'Capturing QR',
  captureQrDetail: 'You can set the address to which you want to send a coin by capturing a QR code. Please move the camera so that the QR code can be accurately captured. When the QR code is recognized, the capturing will automatically end.',
  serverFail: 'Your request failed. Please try again in a few minutes.',
  extraCount: 'Other %s cases',
  email: 'Check email',
  email2: 'Email',
  tradeCoin: 'Select Coin',
  needLogin: 'This screen requires login.',
  find: 'Find',
  walletAddress: 'Wallet address',
  start: 'Get started',
  inputOption: 'Optional',
  memo: 'Memo',
  otp: 'OTP',
  detail: 'Details',
  completed: 'Completed',
  oneMoreBackToExit: 'Press once more to exit.',
  txId: 'Tx ID',

  /********* Country******/
  country: 'Country',
  korea: 'Korea',
  korean: 'Korean',
  krw1: 'KRW',
  krw2: 'KRW',
  china: 'China',
  // chinese: 'China',
  chinese: 'Chinese',
  cny1: 'yuan',
  cny2: 'yuan',
  japan : 'Japan',
  // japanese: 'Japan',
  japanese: 'Japanese',
  jpy1: 'Yen',
  jpy2: 'Yen',
  usa: 'U.S.',
  // english: 'English',
  english: 'English',
  usd1: 'Dollar',
  usd2: 'Dollar',
  eur1: 'Euro',
  eur2: 'Euro',
  // vietnam: 'Vietnam',
  Vietnam: 'Vietnamese',
  usd1: 'Dollar',
  usd2: 'Dollar',
  eur1: 'Euro',
  eur2: 'Euro',

  setLanguage: 'Language setting',
  setCurrency: 'Call setting',

  /********Password********/
  password: 'Password', //'Confirm wallet password',
  passwordConfirm: 'Confirm password',
  inputPassword: 'Wallet password',
  inputPasswordConfirm: 'Confirm wallet password',
  inputPasswordDetail: 'Please enter the password of your wallet.',
  inputPasswordRule: 'Enter 8 to 15 digits in combination with alphabets, numbers, and special characters.',
  inputCurrentPassword: 'Enter current wallet password.',
  inputNewPassword: 'Enter new wallet password.',
  inputNewPasswordConfirm: 'Confirm new wallet password.',
  inputCurrentSecPassword: 'Enter your current security password (four digits).',
  inputNewSecPassword: 'Enter the new security password (four digits).',
  inputNewSecPasswordConfirm: 'Confirm new security password (4 digits)',
  inputFingerDetail: 'Please verify the fingerprint registered on your smartphone.',
  secPassword: 'Security password',
  shortTermSecPassword: 'Security password',
  inputSecPassword: 'Enter security password (four digits)',
  secPasswordConfirm: 'Check security password',
  inputSecPasswordConfirm: 'Check password (four digits)',
  inputOtpCode: 'Enter OTP',
  loginFailedTitle: 'Login failed',
  loginFailedWrongId: 'Wrong ID.',
  loginFailedWrongPassword: 'Wrong password',
  loginFailedExpiredId: 'This ID has been disabled.',
  loginFailedEmailConfirm: 'Email is not certified. Please use the email after authentication.',
  loginFailedMessage: 'Login failed. Please try again in a moment.',
  fingerCheckExplain1: 'Register the use of fingerprints',
  fingerCheckExplain2: '(From next time, log in with fingerprints)',
  usefingerAuth: 'Use fingerprint authentication.',
  fingerAuth: 'Fingerprint authentication',
  fingerRequire: 'Please recognize fingerprints.',
  fingerFail: 'Failed fingerprint authentication.',
  fingerNoData: 'No authentication information found. Please log in again.',
  setupOtp: 'OTP setting',
  changePassword: 'Change password',
  changeSecPassword: 'Change security password',
  findSecPassword: 'Find security password',
  setupOtpDetail: 'Please use the keys below to set up Google OTP. OTP authentication is required when executing important functions such as coin remittance. Press OK to copy the OTP key.',
  otpCopied: 'OTP key is copied.',
  copyOtpKey: 'Copy OTP Key',
  otpAlreadySet: 'OTP is already set.',
  invalidOtp: 'OTP information is invalid',
  changeOtp: 'Change OTP',
  changePasswordFailed: 'Password change failed',
  changePasswordFailedSamePassword: 'The old and new passwords are the same.',
  changePasswordFailedInvalidPassword: 'The password is invalid.',
  changeSecPasswordFailed: 'Security password change failed',
  changeSecPasswordFailedSamePassword: 'The old and new security passwords are the same.',
  changeSecPasswordFailedInvalidPassword: 'Security password invalid',
  otpSetupDescription: 'OTP is a six-digit number key that enhances security in the event of a withdrawal from wallet.',
  downloadOtp1: 'Please download Google OTP from the app store.',
  downloadOtp2: 'Please download Authy from the app store.',
  downloadOtp3: 'Please download Google OTP or Authy from the app store.',
  startOtpSetup: 'Start Authentication',
  inputOtpCodeDescription: 'Enter the authentication code',
  submit: 'Submit',
  otpSetupFinished: 'OTP setup is complete',
  otpSetupFinishedDescription: 'The withdrawal is prohibited for 72 hours after the linked OTP change.',
  otpSetupFinishedDescription1: 'After linked OTP changes',
  otpSetupFinishedDescription2: 'withdrawals are not available.',
  otpSetupFinishedDescription3: 'for 72 Hours.',

  /*********Tagging**********/
  requestTagging: '',
  requestTaggingDetail: '',
  nfcOffMessage1: '',
  nfcOffMessage2: '',
  nfcNotSupported: '',

  /*********Notice**********/
  // noticeCategory: ['','Notice','Event','News'],
  noticeCategory: { NOTE: 'Notice', EVNT: 'Event', NEWS: 'News' },

  /*********Quote**********/
  changePercent: 'Fluctuation',
  totalAmountValue: 'Market Cap',
  criteriaCoinmarketcap: '(Coin market cap criteria)',
  whitePaper: 'White paper',
  website: 'Website',
  community: 'Community',
  country: 'Country',
  preparing: 'We are preparing the contents.',

  /*********My wallet**********/
  mywalletNoCoins: 'There are no coins held',
  mywalletRemoveCoin: 'Would you like to change the selected coin to unused?',
  mywalletAddressCopied: 'Your address has been copied',
  mywalletReceive: 'You can receive coins at the address below. Please copy the address or capture the QR code',
  mywalletBlbSend: 'Send',
  mywalletBlbSendDetail: 'Blobs Coin is currently not available for withdrawal. We are preparing a service to use the coin. Details can be found in future notices.',
  mywalletPossesionCoinCheck: 'View only holding coins',
  mywalletCausion: 'Only coins equal to this address can be sent. If you send another coin to this address incorrectly, you will not be able to retrieve it.',
  mywalletCausionBtc: 'When you deposit and withdraw BitCoin (BTC), BitCoin Cash (BCH), and BitCoin Gold (BCG), they are different cryptocurrencies, so please be aware of it.',
  mywalletCausionEth: 'When you deposit and withdraw ETH (Ethereum) and ETC (Ethereum Classic), they are different cryptocurrencies, so please be aware of it.',
  mywalletCausionXrp: 'Ripple can only be withdrawn if the wallet has more than 20 XRP due to its coin nature, and you should always keep the balance above 20 XRP.',
  transactionInfo: 'Transaction details',
  inputAddress: 'Enter address',
  inputAmount: 'Enter amount',
  depositCompleted: 'Deposit completed',
  outerWallet: 'Outer wallet',
  memoOption: 'Memo (optional)',
  qrNoCoin: 'QR does not contain coin information.',

  /*********deal**********/
  selectCoin: 'Please select a coin',
  sendCoinInputAmount: 'Please enter the amount',
  sendCoinInputAmountLow: 'The minimum withdrawal amount of coins is 0.000001.',
  sendCoinInputFee: 'Please enter a fee',
  sendCoinInputAddress: 'Please enter address',
  sendCoinInputAddress2: 'Address (Please enter a valid address)',
  sendCoinLowBalance: 'Your balance is insufficient',
  // sendCoinLowBalanceXrp: '(Requires holding at least 20XRP)',
  sendCoinLowBalanceXrp: '',
  sendCoinLowFee: 'The fee is insufficient',
  sendCoinInputError: 'Failed to create a send information.',
  sendCoinSuccess: 'The send has been completed.',
  sendCoinNeedConfirm: 'Please check the notice and check it.',
  sendCoinConfirmDlgTtl: 'Send',
  sendCoinConfirmMessage: 'Send coins as follows. Once coin is sent, cancellation is not possible. Would you like to send?',
  sendCoinWarnMessage: 'Can\'t cancel after request!',

  sendCoinCautionConfirmDescriptionEx: [
    'Please check again if the amount and address are correct.',
    'Coin amounts are managed by six decimal places as standard and',
    'the amount below 0. may cause errors depending on the situation.',
    'Because of the nature of the blockchain, it is impossible to cancel after sending it.'
  ],

  sendCoinCautionConfirm: 'Confirm remittance cautions.',
  sendCoinCautionConfirm2: 'I agree to the cautions.',
  sendCoinCautionFee: 'There is a fee of about 0.01 ETH for blockchain transmission.',
  sendCoinCautionConfirmBtc: 'When you deposit and withdraw BitCoin (BTC), BitCoin Cash (BCH), and BitCoin Gold (BCG), they are different cryptocurrencies, so please be aware of it.',
  sendCoinCautionConfirmEth: 'When you deposit and withdraw ETH (Ethereum) and ETC (Ethereum Classic), they are different cryptocurrencies, so please be aware of it.',
  sendCoinCautionConfirmXrp: 'Ripple can only be withdrawn if the wallet has more than 20 XRP due to its coin nature, and you should always keep the balance above 20 XRP.',
  sendCoinCautionConfirmErc20: 'Ether fees used to transfer Ethereum tokens may be more or less than set amount depending on the network situation. Therefore, you must have Ethereum three times the fee when you want to withdraw.',
  sendCoinCautionConfirmDescription: [
    'Coin amounts are managed by six decimal places as standard and the amount below 0.0001 may cause errors depending on the situation.',
    'After sending, it may take an hour or more to complete, and the status of the transfer can be checked in the transaction history.',
    'In case of deposit/withdrawal to other exchange wallets, it may be affected by the relevant exchange policy.',
    'Because of the nature of the cryptocurrency, it is impossible to cancel after sending it.',
    'Please check your address before sending.',
    'You can only send to the same coin address as this coin. If you send coin to another coin address, you will not be able to retrieve it.',
    'The problem of deposit and withdrawal delay due to the overload of the blockchain cannot be handled by OTC market.',
  ],
  sendCoinXrpUseTag: 'Use tag',
  sendCoinFailedTitle: 'Failed to send coin',
  sendCoinFailed: 'Failed to send coin. Please try again later.',
  sendCoinFailedInvalidAddress: 'The address you are sending is invalid.',
  sendCoinFailedInsufficientBalance: 'Insufficient balance.',
  sendCoinFailedLocked: 'Coin is locked.',
  sendCoinFailedInvalidOtp: 'The OTP information is invalid',
  sendCoinFailedInvalidSecPassword: 'The security password is invalid.',
  sendCoinFailedNoSecPassword: 'Please enter a 4-digit security password.',
  sendCoinFailedInvalidDesTag: 'This Destination Tag does not exist.',
  sendCoinFailedOtpRequired: 'You have exceeded the daily limit that can be sent without OTP.',
  sendCoinFailedLimitExceed: 'The daily limit amount has been exceeded.',
  sendCoinSuccessTitle: 'Coin transfer request completed',
  includeAmount: 'Reflected amount',
  includedAmount: 'Deposit amount included in QR',
  includeAmountCaution: 'The QR code reflecting the amount is not compatible with other wallets.',
  inputDestinationTag: 'Enter Destination Tag (optional)',

  /*********거래내역**********/
  noCoins: 'No transactions',

  /*********Payment details**********/
  makePayment: 'Make a payment',
  paymentCautionConfirm: 'Check payment cautions',
  paymentCautionConfirmDescription: [
    'Coin amounts are managed by six decimal places as standard and the amount below 0.0001 may cause errors depending on the situation.',
    'After sending, it may take an hour or more to complete, and the status of the transfer can be checked in the transaction history.',
    'Because of the nature of the blockchain, it is impossible to cancel after sending it.',
    'Please check again if the amount and address are correct.',
    'The problem of deposit and withdrawal delay due to the overload of the blockchain cannot be handled by OTC market.'
  ],
  cancelPayment: 'Do you want to cancel the payment?',

  /*********Exchange**********/
  exchangeCoinInputAmount: 'Please input amount.',
  exchangeCoinLowBalance: 'Balance is insufficient.',
  exchangeCautionConfirm: 'Confirm exchange cautions.',
  exchangeCoinLowBalanceXrp: '(Requires holding at least 20XRP)',
  exchangeCoinNeedConfirm: 'Please check the cautions after confirming.',
  exchangeCoinInputError: 'Failed to create exchange information.',
  exchangeCoinSuccess: 'You have requested an exchange.',
  exchangeCautionConfirmDescription: [
    '   Exchange coins according to the exchange rate with Bit OTC coins.',
    'Coin amounts are managed by six decimal places as standard at Bit OTC market and the amount below 0.0001 may cause errors depending on the situation.',
    'It may take an hour or more to complete exchange, and the status of the transfer can be checked in the transaction history.',
    'Because of the nature of the blockchain, it is impossible to cancel after sending it.',
    'Please check again if the amount and address are correct.',
    'The problem of deposit and withdrawal delay due to the overload of the blockchain cannot be handled by OTC market.',
  ],
  exchange: 'Exchange',
  exchangeInfo: 'Exchange details',
  exchangeSendValue: 'Amount to send',
  exchangeReceiveValue: 'Amount to receive',
  exchangeCoinConfirmDlgTtl: 'Exchange',
  exchangeCoinConfirmMessage: 'Exchange the coin as shown below. You can\'t cancel it if you exchange it once. Would you like to exchange?',

  /*********Address book**********/
  manageAddressBook: 'Address book management',
  addressBook: 'Address Book',
  noRegisteredAddress: 'There is no registered address in the address book.',
  addAddressSuccess: 'Registration to the address book is successful.',
  deleteAddress: 'Delete address book',
  deleteAddressDescription: 'Do you want to delete the item from address book?',
  deleteAddressSuccess: 'Deleted from address book',
  inputAddressbookMemo: 'Input name to register',
  inputAddressbookAddress: 'Input address to register',
  inputAddressbookDestinationTag: 'Enter Destination Tag (optional)',
  needInputAddressbookMemo: 'Please enter a name to register.',
  needInputAddressbookAddress: 'Please enter an address to register.',
  registerAddress: 'Register an address',
  registerAddressFailed: 'Address registration failed.',
  alreadyRegisterdAddress: 'The address is already registered.',
  alreadyRegisterdAddress2: 'The address is already registered. Do you want to edit the registered name?',

  /*********Factory setting**********/
  setupAreementConfirm: 'Agree to terms and conditions',
  setupShowAgreement: 'View',
  setupStep: [
  ],
  setupStepTitle: [
    'Initial wallet setting',
    'Enter basic information',
    'Set security password',
    'Completed registration',
  ],
  setupStepGuide: [
  ],
  setupStepRecoverGuide: [
  ],
  mnemonicSelectionTitle: 'Please change 5 or more words for security. If you lose a smartphone or delete an app, your wallet may be lost, so make sure to take note. The 12th word is automatically set.',
  setupStepRecoverTitle: 'Enter recovery word',

  setupCard: 'Wallet registration',
  setupCardNotSupported: '',

  needAgreement: 'Please agree to the terms and conditions',
  needViewAgreement: 'Please click View terms and conditions to check them.',
  needChangeWord: 'Please change 5 or more words.',
  needInputWord: 'Please enter all 24 words',
  needValidWord: 'The words do not fit the rule',
  passwordConfirmFail: 'The two passwords you entered do not match. Please check it again.',
  passwordLengthFail: 'Please enter your password',
  passwordStrength: 'Please make your password 8~15 characters in combination with English, numbers, and special characters.',
  passwordStrength0: 'Please enter your password',
  passwordStrength1: 'Your password is weak.',
  passwordStrength2: 'Your password is normal.',
  passwordStrength3: 'Your password is strong.',
  emailLengthFail: 'Please enter your email.',
  emailStrength: 'The email format is invalid.',
  secPasswordStrength: 'Please enter your security password in 4 digits.',
  secPasswordConfirmFail: 'The two security passwords you entered do not match. Please check it again.',

  setupNewCard: 'Create new',
  setupRecoverCard: 'Recovery',

  phoneFail: 'Please enter your mobile phone number correctly.',
  certiCodeFail: 'Please enter an authentication number.',
  certiCodeWrong: 'You have entered an invalid authentication number.',
  certiCodeSendFail: 'Failed to send the authentication number.',
  certiCodeNotVerified: 'Phone number authentication is required.',

  setupFinish: 'Congratulations and welcome to',

  needLogout: [{ content: '' }],

  /*********Common services******/
  purchase: '',
  bootError: 'Server connection failed',
  bootErrorDetail: 'Sorry. Server connection is not good at this time. Please try again in a moment.',
  appUpdate: 'Update apps',
  appUpdateDetail: 'Required app updates are available. Please use the service after the update.',
  goStore: 'Go to store',
  serverDown: 'Server inspection',
  serverDownDetail: 'The server is being inspected.',
  waitLoading: 'Wait a moment, please.',
  waitLoadingDetail: 'If this screen last longer than a few seconds, please restart the app. If the problem persists, there may be a problem with the server.',

  /**************/
  myinfoSerialNum: '',
  myinfoCopyCard: 'Card Copy',
  myinfoBackupWordQuery: 'Recovery word query',
  myinfoBackupWordQueryDetail: 'If your wallet is lost by replacing your smartphone or deleting the app, you can use the recovery word to recover your wallet. If you haven\'t already done so, make sure to take notes now.',
  myinfoBackupWord: 'Recovery word',
  myinfoLogin: 'Login in',
  myinfoLoginDetail: 'Running. Just a moment, please.',
  myinfoLoginChange: '',
  myinfoLogout: 'Logout',
  myinfoLogoutByTimeout: 'You have been logged out for a long period of time.',
  myinfoAppVer: 'App version',
  myinfoHome: 'Home Page',
  myinfoInquiry: 'Inquiry',
  myinfoInquiry1on1: '1:1 Inquiry',
  myinfoSupportCenter: 'Customer Center',
  myinfoSupportCenterValue: '',
  myinfoCorporateNumber: 'Corporate registration number',
  myinfoCorporateNumberValue: '509-86-01382',
  myinfoNotice: 'Notices',
  myinfoNoticeCategory: 'Category',
  myinfoNoticeTitle: 'Title',
  myinfoNoticeDate: 'Registration Date',
  myinfoPolicyAndGuide: 'Terms and Conditions',
  myinfoGuide: 'Use Guide',
  myinfoPolicy: 'Terms of Use',
  myinfoFaq: 'FAQ',
  myinfoLogoutConfirm: 'Do you want to log out?',
  myinfoCopy: '',
  myinfoCopyTag: '',
  myinfoCopyTagExplanation: '',
  myinfoCopyTagComplete: '',
  myinfoCopyTagNotSupported: '',
  myinfoIdentify: 'Identity authentication',
  myinfoIdentifyEmail: 'Authentication by mail',
  myinfoIdentifyEmailComplete: 'Mail authentication completed',
  email: 'Email',
  inputEmail: 'Enter email',
  inputEmailDetail: 'Please enter an email address to confirm your identity.',
  sendEmailComplete: 'An email has been sent for authentication.',
  sendEmailFail: 'Failed to send email.',
  myRecommendCode: 'My recommendation code',
  recommendCodeCopied: 'Recommendation code has been copied.',
  myinfoTwoSecurity: 'Duplicate authentication',
  myinfoSettingNick: 'Set nickname',
  myinfoHidePrice: 'Hide balance',
  myinfoSettingLan: 'Language Setup',
  myinfoIsCurrency: 'Currency display',
  myinfoTwoSecurityKey: 'Dual authentication key',
  myinfoChangePassword: 'Correct password',
  myinfoChangeSecurityPassword: 'Correct security password',
  myinfoTwoSecurityInfo: 'When withdrawing from a wallet, the dual authentication key is.',
  myinfoTwoSecurityInfo2: 'a security-enhancing six-digit key.',
  myinfoTwoSecurityInfo3: 'From App Store',
  myinfoTwoSecurityInfo4: 'download Google OTP or Authy.',
  myinfoInputAuthCode: 'Please enter the authentication code.',
  myinfoOtpComplete: 'OTP setup is complete.',
  myinfoOtpInfo: 'After linked OTP changes',
  myinfoOtpInfo2: 'withdrawals are not available for a while.',
  startAuth: 'Start Authentication',
  myinfoCopyKey: 'Copy key',

  /******** Charging********/
  chargeCoin: 'OTC market Charge',
  chargeCoinDescription1: 'Charge OTC market without bankbook. Please enter the amount in the text box below, apply for charging, and proceed with the transfer.',
  chargeCoinDescription2: 'Application for charging has been completed.',
  chargeCoinDescription3: 'The amount and name of the depositor must be transferred as shown below, and if the transfer is made incorrectly, it may take some time to process the deposit.',
  chargeCoinDescription4: 'If you do not make a deposit within one hour, your application will be automatically canceled. If you make multiple deposits, you must first apply for each charge and then transfer it.',
  chargeCoinDescription5: 'You can make a copy by touching the account, charge amount, and name of the depositor.',
  chargeCoinCopyBank: 'The deposit account has been copied.',
  chargeCoinCopyAmount: 'Charge amount has been copied.',
  chargeCoinCopyName: 'The depositor\'s name has been copied.',
  chargeKrwAmount: 'Charge amount',
  chargeRequest: 'Charge Application',
  chargeName: 'Depositor\'s name',
  chargeBank: 'Deposit account',
  chargeRequestMaxLimit: 'Up to five applications can be filed at the same time.',
  chargeList: 'List of charge applications in progress',

  /*********Custom phrase******/
  manualInput: 'Manual Input',
  manualPurchase: 'Pasting payment information',
  manualPurchaseFail: 'There is no payment information on the clipboard.',

  /******* Country ****/
  selectCountry: 'Select a country.',
  country: 'Country',
  countryCode: ['KO', 'CN', 'US', 'JP',
    'AR', 'AM', 'AW', 'AU', 'AT', 'AZ', 'BH', 'BD', 'BY', 'BE',
    'BZ', 'BJ', 'BT', 'BO', 'BA', 'BW', 'BR', 'IO', 'BN', 'BG',
    'BF', 'BI', 'KH', 'CM', 'CA', 'CV', 'CF', 'TD', 'CL', 'CX',
    'CC', 'CO', 'KM', 'CK', 'CR', 'HR', 'CU', 'CW', 'CY', 'CZ',
    'CD', 'DK', 'DJ', 'TL', 'EC', 'EG', 'SV', 'GQ', 'ER', 'EE',
    'ET', 'FK', 'FO', 'FJ', 'FI', 'FR', 'PF', 'GA', 'GM', 'GE',
    'DE', 'GH', 'GI', 'GR', 'GL', 'GT', 'GN', 'GW', 'GY', 'HT',
    'HN', 'HK', 'HU', 'IS', 'IN', 'ID', 'IR', 'IQ', 'IE', 'IL',
    'IT', 'CI', 'JO', 'KZ', 'KE', 'KI', 'XK', 'KW', 'KG', 'LA',
    'LV', 'LB', 'LS', 'LR', 'LY', 'LI', 'LT', 'LU', 'MO', 'MK',
    'MG', 'MW', 'MY', 'MV', 'ML', 'MT', 'MH', 'MR', 'MU', 'YT',
    'MX', 'FM', 'MD', 'MC', 'MN', 'ME', 'MA', 'MZ', 'MM', 'NA',
    'NR', 'NP', 'NL', 'AN', 'NC', 'NZ', 'NI', 'NE', 'NG', 'NU',
    'KP', 'NO', 'OM', 'PK', 'PW', 'PS', 'PA', 'PG', 'PY', 'PE',
    'PH', 'PN', 'PL', 'PT', 'QA', 'CG', 'RE', 'RO', 'RU', 'RW',
    'BL', 'SH', 'MF', 'PM', 'WS', 'SM', 'ST', 'SA', 'SN', 'RS',
    'SC', 'SL', 'SG', 'SK', 'SI', 'SB', 'SO', 'ZA', 'SS', 'ES',
    'LK', 'SD', 'SR', 'SJ', 'SZ', 'SE', 'CH', 'SY', 'TW', 'TJ',
    'TZ', 'TH', 'TG', 'TK', 'TO', 'TN', 'TR', 'TM', 'TV', 'UG',
    'UA', 'AE', 'GB', 'UY', 'UZ', 'VU', 'VA', 'VE', 'VN', 'WF',
    'EH', 'YE', 'ZM', 'ZW',
  ],
  countryTelCode: ['82-', '86-', '1-', '81-',
    '54-', '374-', '297-', '61-', '43-', '994-', '973-', '880-', '375-', '32-',
    '501-', '229-', '975-', '591-', '387-', '267-', '55-', '246-', '673-', '359-',
    '226-', '257-', '855-', '237-', '1-', '238-', '236-', '235-', '56-', '61-',
    '61-', '57-', '269-', '682-', '506-', '385-', '53-', '599-', '357-', '420-',
    '243-', '45-', '253-', '670-', '593-', '20-', '503-', '240-', '291-', '372-',
    '251-', '500-', '298-', '679-', '358-', '33-', '689-', '241-', '220-', '995-',
    '49-', '233-', '350-', '30-', '299-', '502-', '224-', '245-', '592-', '509-',
    '504-', '852-', '36-', '354-', '91-', '62-', '98-', '964-', '353-', '972-',
    '39-', '225-', '962-', '7-', '254-', '686-', '383-', '965-', '996-', '856-',
    '371-', '961-', '266-', '231-', '218-', '423-', '370-', '352-', '853-', '389-',
    '261-', '265-', '60-', '960-', '223-', '356-', '692-', '222-', '230-', '262-',
    '52-', '691-', '373-', '377-', '976-', '382-', '212-', '258-', '95-', '264-',
    '674-', '977-', '31-', '599-', '687-', '64-', '505-', '227-', '234-', '683-',
    '850-', '47-', '968-', '92-', '680-', '970-', '507-', '675-', '595-', '51-',
    '63-', '64-', '48-', '351-', '974-', '242-', '262-', '40-', '7-', '250-',
    '590-', '290-', '590-', '508-', '685-', '378-', '239-', '966-', '221-', '381-',
    '248-', '232-', '65-', '421-', '386-', '677-', '252-', '27-', '211-', '34-',
    '94-', '249-', '597-', '47-', '268-', '46-', '41-', '963-', '886-', '992-',
    '255-', '66-', '228-', '690-', '676-', '216-', '90-', '993-', '688-', '256-',
    '380-', '971-', '44-', '598-', '998-', '678-', '379-', '58-', '84-', '681-',
    '212-', '967-', '260-', '263-',
  ],
  countryName: {
    KO: '대한민국', CN: '중국', US: '미국', JP: '일본',
    AR: 'Argentina', AM: 'Afghanistan', AW: 'Albania', AU: 'Algeria', AT: 'American Samoa',
    AZ: 'Andorra', BH: 'Anguilla', BD: 'Antarctica', BY: 'Belarus', BE: 'Belgium',
    BZ: 'Belize', BJ: 'Benin', BT: 'Bhutan', BO: 'Bolivia', BA: 'Bosnia and Herzegovina',
    BW: 'Botswana', BR: 'Brazil', IO: 'British Indian Ocean Territory', BN: 'Brunei', BG: 'Bulgaria',
    BF: 'Burkina Faso', BI: 'Burundi', KH: 'Cambodia', CM: 'Cameroon', CA: 'Canada',
    CV: 'Cape Verde', CF: 'Central African Republic', TD: 'Chad', CL: 'Chile', CX: 'Christmas Island',
    CC: 'Cocos Islands', CO: 'Colombia', KM: 'Comoros', CK: 'Cook Islands', CR: 'Costa Rica',
    HR: 'Croatia', CU: 'Cuba', CW: 'Curacao', CY: 'Cyprus', CZ: 'Czech Republic',
    CD: 'Democratic Republic of the Congo', DK: 'Denmark', DJ: 'Djibouti', TL: 'East Timor', EC: 'Ecuador',
    EG: 'Egypt', SV: 'El Salvador', GQ: 'Equatorial Guinea', ER: 'Eritrea', EE: 'Estonia',
    ET: 'Ethiopia', FK: 'Falkland Islands', FO: 'Faroe Islands', FJ: 'Fiji', FI: 'Finland',
    FR: 'France', PF: 'French Polynesia', GA: 'Gabon', GM: 'Gambia', GE: 'Georgia',
    DE: 'Germany', GH: 'Ghana', GI: 'Gibraltar', GR: 'Greece', GL: 'Greenland',
    GT: 'Guatemala', GN: 'Guinea', GW: 'Guinea-Bissau', GY: 'Guyana', HT: 'Haiti',
    HN: 'Honduras', HK: 'Hong Kong', HU: 'Hungary', IS: 'Iceland', IN: 'India',
    ID: 'Indonesia', IR: 'Iran', IQ: 'Iraq', IE: 'Ireland', IL: 'Israel',
    IT: 'Italy', CI: 'Ivory Coast', JO: 'Jordan', KZ: 'Kazakhstan', KE: 'Kenya',
    KI: 'Kiribati', XK: 'Kosovo', KW: 'Kuwait', KG: 'Kyrgyzstan', LA: 'Laos',
    LV: 'Latvia', LB: 'Lebanon', LS: 'Lesotho', LR: 'Liberia', LY: 'Libya',
    LI: 'Liechtenstein', LT: 'Lithuania', LU: 'Luxembourg', MO: 'Macau', MK: 'Macedonia',
    MG: 'Madagascar', MW: 'Malawi', MY: 'Malaysia', MV: 'Maldives', ML: 'Mali',
    MT: 'Malta', MH: 'Marshall Islands', MR: 'Mauritania', MU: 'Mauritius', YT: 'Mayotte',
    MX: 'Mexico', FM: 'Micronesia', MD: 'Moldova', MC: 'Monaco', MN: 'Mongolia',
    ME: 'Montenegro', MA: 'Morocco', MZ: 'Mozambique', MM: 'Myanmar', NA: 'Namibia',
    NR: 'Nauru', NP: 'Nepal', NL: 'Netherlands', AN: 'Netherlands Antilles', NC: 'New Caledonia',
    NZ: 'New Zealand', NI: 'Nicaragua', NE: 'Niger', NG: 'Nigeria', NU: 'Niue',
    KP: 'North Korea', NO: 'Norway', OM: 'Oman', PK: 'Pakistan', PW: 'Palau',
    PS: 'Palestine', PA: 'Panama', PG: 'Papua New Guinea', PY: 'Paraguay', PE: 'Peru',
    PH: 'Philippines', PN: 'Pitcairn', PL: 'Poland', PT: 'Portugal', QA: 'Qatar',
    CG: 'Republic of the Congo', RE: 'Reunion', RO: 'Romania', RU: 'Russia', RW: 'Rwanda',
    BL: 'Saint Barthelemy', SH: 'Saint Helena', MF: 'Saint Martin', PM: 'Saint Pierre and Miquelon', WS: 'Samoa',
    SM: 'San Marino', ST: 'Sao Tome and Principe', SA: 'Saudi Arabia', SN: 'Senegal', RS: 'Serbia',
    SC: 'Seychelles', SL: 'Sierra Leone', SG: 'Singapore', SK: 'Slovakia', SI: 'Slovenia',
    SB: 'Solomon Islands', SO: 'Somalia', ZA: 'South Africa', SS: 'South Sudan', ES: 'Spain',
    LK: 'Sri Lanka', SD: 'Sudan', SR: 'Suriname', SJ: 'Svalbard and Jan Mayen', SZ: 'Swaziland',
    SE: 'Sweden', CH: 'Switzerland', SY: 'Syria', TW: 'Taiwan', TJ: 'Tajikistan',
    TZ: 'Tanzania', TH: 'Thailand', TG: 'Togo', TK: 'Tokelau', TO: 'Tonga',
    TN: 'Tunisia', TR: 'Turkey', TM: 'Turkmenistan', TV: 'Tuvalu', UG: 'Uganda',
    UA: 'Ukraine', AE: 'United Arab Emirates', GB: 'United Kingdom', UY: 'Uruguay', UZ: 'Uzbekistan',
    VU: 'Vanuatu', VA: 'Vatican', VE: 'Venezuela', VN: 'Vietnam', WF: 'Wallis and Futuna',
    EH: 'Western Sahara', YE: 'Yemen', ZM: 'Zambia', ZW: 'Zimbabwe',
  },

  /********** Terms and Conditions, etc******/

  policyContent: [
    { title: 'Chapter 1 General rules', content: '' },
    {
      title: 'Article 1 (Purpose)',
      content:
        'This TERMS AND CONDITIONS stipulates the rights, obligations and responsibilities between the company and the user, and other necessary matters regarding the use and procedures of the services provided by the apps and wallets of Hashone (hereinafter: the company).\n',
    },
    {
      title: 'Article 2 (Statement, explanation and TERMS AND CONDITIONS revision))',
      content: [
        '① The contents of the TERMS AND CONDITIONS shall be notified to the user by the company\'s service and other means, and the contents will be effective as the user agrees to this TERMS AND CONDITIONS.\n',
        '② The company may revise this TERMS AND CONDITIONS to the extent that it does not violate the relevant laws, such as the "Act on the Regulation of TERMS AND CONDITIONS" and the "Act on the Promotion of Information and Communications Network Utilization and Information Protection, etc.\n',
        '③ If the company revises the TERMS AND CONDITIONS, it will announce the date of application and the reason for revision, along with the current TERMS AND CONDITIONS, on the initial screen, pop-up screen, or notice of the company\'s site, seven days before the application date. However, in the event of a change in contents that is unfavorable to or significant to the user, the user is notified 30 days before the application date.\n',
        '④ Although the company publicly announced the revised TERMS AND CONDITIONS in accordance with the preceding paragraph and clearly notified the user that if the user does not express his/her intention within the period of seven days, the user\'s failure to express his/her intention explicitly shall be deemed that the user has agreed to the TERMS AND CONDITIONS revision.\n',
        '⑤ If the user expresses an explicit intention not accepting the application of the TERMS AND CONDITIONS revision, the company cannot apply the TERMS AND CONDITIONS revision. In this case, the user may terminate the agreement. However, the company may terminate the service agreement if there are special circumstances where the existing TERMS AND CONDITIONS cannot be applied.\n',
      ]
    },
    {
      title: 'Article 3 (Rules other than TERMS AND CONDITIONS)',
      content:
        'The company sets separate notices, individual TERMS AND CONDITIONS, individual agreements, usage guides, operation policies, detailed instructions, etc. (hereinafter collectively referred to as “Detailed Guidelines”) and may notify or obtain consent from the user. In the event that the contents of the Detailed Guidelines that have been agreed to by the user conflict with the TERMS AND CONDITIONS, the Detailed Guidelines take precedence. Any matters or interpretations not specified by this TERMS AND CONDITIONS will be governed by the Detailed Guidelines and related laws or commercial practices.\n',
    },
    {
      title: 'Article 4 (Definition of terms)',

      content: [
        '① The definition of terms used in the TERMS AND CONDITIONS is as follows.\n',
        '1. Cryptocurrency: It refers to currencies that exist electronically in the blockchain, such as Bitcoin (BTC) and Ethereum (ETH).\n',
        '2. User: It refers to the user who approved the TERMS AND CONDITIONS and registered a wallet provided by the company.\n',
        '3. App: It refers to a smartphone online-only program that can store or send and receive cryptocurrency.\n',
        '4. Wallet: It refers to an app that can store cryptocurrency on a smartphone.\n',
        '5. Password: It refers to a combination of characters set by the user to verify the identity between the user and the wallets and secure cryptocurrency.\n',
        '6. Recovery Words: It refers to 12-encrypted words for recovery when wallet is lost.\n',]
    },
    {
      title: 'Chapter 2 Service Use Application and Approval (User subscription and cancellation)',

      content: '',
    },
    {
      title: 'Article 5 (Establishment of an agreement for use)',

      content: [
        '① The user apply for the use of their wallets by expressing their consent to the TERMS AND CONDITIONS when creating wallets in the app provided by the company.\n',
        '② Use agreement is concluded by registered unit of wallet. Once the service agreement is established, the applicant will be registered as a wallet user.\n',
      ]
    },
    {
      title: 'Article 6 (Application for use)',
      content: 'Application for use is automatically approved when registering a wallet in an app provided by the company..\n',
    },
    {
      title: ' Article 7 (Consent to the use of user information and acceptance of application for use)',
      content: [
        '① Consent to the use of user information\n',
        '1. The company uses the user\'s personal information for the purpose of implementing this service agreement and providing services under this service agreement.\n',
        '2. The user information may be provided to companies affiliated with the company in order to make it easier for the user to use the company and its affiliated services. However, before providing the user information, the company shall notify the user of affiliates, the purpose of provision, and the details of the user information to be provided in advance and obtain the user\'s consent.\n',
        '3. The user can view and modify their personal information at any time by modifying the user information.\n',
        '4. If the user enters the user information in the application form and applies for use to the company pursuant to this TERMS AND CONDITIONS, it is deemed that the company agrees to collect, use and provide the user information listed in the application under the TERMS AND CONDITIONS.\n',

      ]
    },
    {
      title: ' Article 8 (Suspension and cancellation of use agreement)',
      content: [
        '① Cancellation of the use agreement and app use is possible by the user by deleting the app from the smartphone.\n',
        '② The company may not approve the application of the applicant for registration if there is a reason falling under each of the following subparagraphs, and if the reasons for each of the following subparagraphs are confirmed after signing up, the company may cancel the consent or terminate the use agreement.\n',
        '1. If user has previously lost membership under the TERMS AND CONDITIONS;\n',
        '2. If false or incorrect information is entered or provided, or the content presented by the company is not entered;\n',
        '3. If approval is not possible due to reasons attributable to the applicant for membership, or if the application is made in violation of all other matters prescribed by the TERMS AND CONDITIONS;\n',
        '4. If user wants to use the service for an illegal purpose or a separate business purpose;\n',
        '5. If the application is made for the purpose of violating related laws or impeding social order or morals\n',
        '6. In case the applicant\'s age is under 19;\n',
        '③ The company may request real name authentication or identity authentication through a professional organization within the range permitted by law in order to check whether the information provided by the member is consistent with the facts;\n',

      ]
    },
    { title: ' Chapter 3 Company\'s obligation', content: '' },
    {
      title: 'Article 9 Company\'s obligations for the user personal information and services',
      content: [
        '① The company complies with relevant laws and regulations and the TERMS AND CONDITIONS, and makes every effort to provide continuous and stable service.\n',
        '② The company may have a security system to protect personal information so that the user can safely use the service, and disclose and comply with the privacy policy.\n',
        '③ The company may write and use all or part of the personal information of the user as statistical data that cannot distinguish specific user information without prior consent of the user in relation to business, and for this purpose, the user transmits a cookie to the computer. However, if the user refuses to send cookies or change the settings of the device used to warn about the transmission of cookies, the use of the service may be changed without reasons attributable to the company due to the change in cookie settings.\n',
        '④ If the company deems that opinions or complaints raised by the user in relation to the use of the service are legitimate, they shall handle them, and can communicate the processing process and results to the user through bulletin boards and e-mails within the service.\n',
        '⑤ The company complies with laws and regulations related to the operation and maintenance of services such as the Information and Communication Network Act, the Location Information Protection Act, the Communication Secret Protection Act, and the Telecommunication Business Act.\n',

      ]
    },
    { title: ' Chapter 4 User\'s obligation', content: '' },
    {
      title: 'Article 10 (User\'s obligation to manage user wallets and passwords)',
      content: [
        '① The user is responsible for all management of the wallet and password. The user is responsible for all results arising from negligence or illegal use of the wallet and password registered by the user, and the company shall not be held liable for them. However, this is not the case if the company intentionally caused it.\n',
        '② 12 recovery words shall be written on paper and 12 recovery words are required to recover the lost wallet. Because the company does not have this information, it cannot be recovered if the recovery word is lost, and the user is fully responsible for it and the company is not responsible for it. In addition, the user is fully responsible for the loss caused by the leakage of 12 recovery words due to his/her mistake, and the company is not responsible for it.\n'

      ]
    },
    {
      title: 'Article 11 (Providing of information)',
      content: [
        '1. The company can provide information on the following services and products for convenience of use through wired and wireless telephones, e-mail, text messaging service (LMS/SMS), SNS, etc. In this case, the user can refuse to receive at any time.\n',
        '① Services related to events \n',
        '② Other services that the company frequently decide and provide to the user\n',
        '2. The user can view his/her personal information and request modification or change at any time through the service. The user shall modify the changes directly in the service or notify the company of the changes through the customer center if the details listed at the time of application for membership change. The company shall not be liable for any disadvantages caused by the user\'s failure to notify the company of the changes in the preceding paragraph.\n',

      ]
    },
    { title: 'Chapter 5 General rules for service use', content: '' },
    {
      title: 'Article 12 (Types of services)',
      content: [
        '① The services provided by the company include a wallet function to store cryptocurrency, and a function to send and receive cryptocurrency to other people. However, the user is responsible for the accuracy of the cryptocurrency wallet address provided by the user, and the company is not responsible for sending cryptocurrency to the cryptocurrency wallet address that the user incorrectly entered.\n',
        '② The type of service provided by the company may be changed from time to time due to the circumstances of the company, and the copyright and intellectual property rights for the provided service belong to the company.\n',
        '③ In relation to the service, the company only grants the user the right to use accounts, wallets, and services according to the terms of use set by the company, and the user cannot provide similar services and perform commercial activities using this.\n',
      ]
    },
    {
      title: 'Article 13 (Notice and Change of Service Contents)',
      content: [
        '① The company announces the characteristics, procedures, and methods of each service according to the type of service through the website, and the user shall understand and use the service notified by the company.\n',
        '② The company may change all or part of the individual services provided according to operational and technical needs if there is a considerable reason. In this case, the company notifies the user of the content at least seven days in advance.\n',
      ]
    },
    {
      title: 'Article 14 (Maintenance and suspension of service)',
      content: [
        '① In principle, the service shall be used 24 hours a day unless there is a special problem with the company\'s business or technology. However, this does not apply to the day or time set by the company prior to or after the need for regular inspection.\n',
        '② The company can divide the service into a certain range and set the available time separately for each range. In this case, the contents are notified in advance or later.\n',
        '③ The company can stop providing services in any of the following cases: In this case, the company will notify you in advance of the suspension of the service unless it is inevitable.\n',
        '1. Where unavoidable circumstances due to construction, such as repair of service equipment;\n',
        '2. Where the key telecommunications service provider as stipulated in the Telecommunications Business Act has stopped the telecommunication service;\n',
        '3. Where the service provided by an affiliate or a third party, other than a service provided directly by the company is suspended by a third party, such as an affiliate.\n',
        '4. Where there is any other force majeure reason;\n',
        '④ The company may restrict or suspend all or part of the service if normal service use is disrupted due to a national emergency, power failure, failure of the service facilities, or excessive use of the service.\n',
      ]
    },
    {
      title: 'Article 15 Restriction to use',
      content: [
        '① The company may restrict the members\' service use and access in any of the following cases:\n',
        '1. Where consecutive password and security password errors;\n',
        '2. Where hacking or accident occurs;\n',
        '3. Where theft of ID is suspected;\n',
        '4. Where a State agency requests restriction on services in accordance with relevant statutes;\n',
        '5. Where it is reasonably suspected that the user is involved in money laundering, unfair trade, criminal activities, etc.;\n',
        '6. Where the user who is a member is confirmed to be under 19 years of age;\n',
        '7. Where a cause corresponding to any other cause arises or measures are required to prevent such cause from occurring;\n',
        '② The company may restrict the member\'s use of send and receive in any of the following cases:\n',
        '1. Where the user who has joined as a member is confirmed to be under 19 years of age;\n',
        '2. Where hacking or fraud is suspected to have occurred;\n',
        '3. Where theft of ID is suspected;\n',
        '4. Where a State agency requests restriction on services in accordance with relevant statutes;\n',
        '5. Where it is reasonably suspected that the user is involved in money laundering, unfair trade, criminal activities, etc.;\n',
        '6. Upon request of the member himself/herself;\n',
        '7. Where the cryptocurrency has been sent but is not reflected in the member\'s account or that the send and the receive have been made differently from the actual send and receive request;\n',
        '8. Where a cause corresponding to any other cause arises or measures are required to prevent such cause from occurring;\n',
        '③ If the service is restricted or the agreement is terminated pursuant to this Article, the company shall notify the members by e-mail, SMS, or App Push.\n',
        '④ Members may file an objection to the restriction of use under this Article in accordance with the procedures set by the company. If the company acknowledges that the objection is justifiable, the company immediately resumes the use of the service.\n',

      ]
    },
    {
      title: 'Article 16 (Cancellation of Use Agreement)',
      content: [
        '① The user can apply for cancellation of the use agreement at any time through the customer center, and the company shall deal with it immediately in accordance with the relevant laws and regulations.\n',
        '② The company may limit the use of the service by setting a time when the following reasons occur to the member and request correction. Despite the request for correction, the agreement of use may be terminated if it is not corrected within a considerable period of time or if the same violation is repeatedly committed.\n',
        '1. Where the user violates the obligations of the user stipulated in Article 10 of this TERMS AND CONDITIONS or falls under the reasons for restriction of use stipulated in Article 15;\n',
        '2. Where violation of related laws, such as illegal communication and hacking in violation of the Act on Promotion of Information and Communication Network Utilization and Information Protection, etc. occurs;\n',
        '3. Where an act or attempt is made to interfere with the smooth progress of the service provided by the company;\n',
        '4. Where any other reason that cannot maintain this agreement due to reasons similar to each subparagraph.\n',
        '③ Upon cancellation of the use agreement pursuant to the preceding paragraph, all benefits obtained through the use of the service will lapse, and the company does not compensate for this.\n',
        '④ In the event of cancellation of the service use agreement pursuant to this Article, the company notifies the user by E-mail, SMS, App Push, etc.\n',
        '⑤ When the cancellation of the use agreement is completed, all information of the user except for the information that the company shall hold in accordance with the relevant laws and personal information processing policy will be deleted.\n',
        '⑥ If the company terminates the use agreement pursuant to Paragraph 2, the company may store the user\'s information for a certain period of time for receiving and processing the user\'s objection, and after that period elapses, deletes the user\'s information (except send and receive details).\n',
      ]
    },
    {
      title: 'Article 17 (Provision of information and posting of advertisements)',
      content: [
        '① In operating the service, the company can post various information related to the service on the service screen or provide it to the user through e-mail, SMS, and App Push.\n',
        '② In operating the service, the company may post various advertisements of the company or affiliates on the service screen or provide it to the user by e-mail, SMS, App Push, etc. with the consent of the user.\n',
      ]
    },
    { title: 'Chapter 5 Personal Information Protection', content: '' },
    {
      title: 'Article 18 (Protection of Personal Information)',
      content: [
        'The company strives to protect the user\'s personal information in accordance with the relevant laws and regulations. The user\'s personal information is used only for the purpose and scope agreed by the user for the smooth provision of services. The company does not provide the user\'s personal information to a third party unless it is in accordance with the law or the user agrees otherwise, and the details of this are determined in the privacy policy.\n',
      ]
    },
    { title: 'Chapter 6 Damage and Immunity Clause', content: '' },
    {
      title: ' Article 19 (Company\'s exemption and compensation)',
      content: [
        '① The company does not guarantee any matters not specified in this TERMS AND CONDITIONS regarding the service. Also, the company does not guarantee the value of the cryptocurrency that the company has not issued or guaranteed payment.\n',
        '② If the company is unable to provide the service due to natural disasters, DDoS attacks, IDC failures, server down due to soaring service access, and other unavoidable reasons, such as a line failure of a fixed-term carrier, the company is exempted from responsibility for providing the service. However, this does not apply to company\'s intentional or gross negligence.\n',
        '③ The company shall not be held responsible for any inevitable failure due to problems in the blockchain, defects in the cryptocurrency issue management system itself, technical problems, poor communication service providers, or regular server inspection. However, this does not apply to company\'s intentional or gross negligence.\n',
        '④ The company shall not be held liable for any service disruption or consequences caused by the cause attributable to the user. However, this does not apply if the user has a justifiable reason.\n',
        '⑤ The company is exempted from responsibility if it transacts with the service as a medium between users or between the user and third parties. However, this does not apply to company\'s intentional or gross negligence.\n',
        '⑥ The company shall not be held liable for the use of free services unless otherwise provided in the relevant laws.\n',
        '⑦ The company can perform regular, irregular, or urgent server checks to ensure stable service delivery.\n',
        '⑧ If anyone acquires or receives a third-party-owned cryptocurrency without authority due to a service error, computer failure, or other reasons, the company may take necessary measures, such as retrieving or restoring the cryptocurrency after prior notification to the parties.\n',

      ]
    },
    {
      title: ' Article 20 (Jurisdiction courts and compliance laws)',
      content: [
        '① The TERMS AND CONDITIONS using this service is regulated and interpreted in accordance with Korean law, and if a lawsuit is filed between the company and the user regarding the use of the service, such as the company\'s fee system, the Korean law applies.\n',
        '② Where a lawsuit between the company and the user regarding the use of the service is filed, the court according to the procedures prescribed in the statutes shall be the competent court.\n',

      ]
    },
    { title: 'Attachment', content: 'This TERMS AND CONDITIONS shall take effect as of 1 January 2020.' },
  ],

  /*********Error code**********/
  E10000: 'No network access',
  E10001: 'Unknown server error',
  E10002: 'Invalid information request',
  E10003: 'No authorization',
  E10004: 'Blockchain information request error',
  E30001: 'Coin address not generated error',

  /*********Etc**********/
  needLogin2: 'Login required.',
  hideBalance: 'Hide balance',

  /********** Translation required **********/
  loginFailedResendEmail: 'Resend authentication email',

  emailResent: 'The verification email has been resent.',
  emailResendFailed: 'Failed to resend the authentication email.',
  invalidEmail: 'This email has already been verified',
  emailAlreadyVerified: 'This email is invalid.',

  certiCodeExpired: 'The authentication deadline has been exceeded. Please resend the verification number.',

  serverRequestError: 'Server request failed',
  serverRequestErrorDescription: 'The server request failed.',

  duplCheck: 'Duplicate check',

  emailDuplCheckError: 'The email duplicate check failed.',
  emailDuplCheckErrorEmailDupl: 'This email is already registered.',
  emailDuplCheckErrorInvalidEmail: 'The email format is invalid.',
  needEmailDuplCheck: 'An email duplicate check is required.',

  setupFinish2: 'Authentication email has been sent.\nPlease complete the authentication within 60 minutes.',
  sendCoinFailedOtpRequired: 'You have exceeded the daily sending limit. Please increase the limit by setting OTP.',
  useBioAuth: 'FIDO',
  bioAuth: 'Biometric authentication',
  bioRequire: 'Please proceed biometric authentication.',
  bioFail: 'Biometric authentication failed.',

  feeRequestFailed: 'Fee inquiry failed.',
  exemption: 'Exemption (for transfer between members)',

  sendCoinSuccessNeedApproval: 'The sending request has been completed and the coin will be sent after manager\'s approval.',

  setNickname: 'Set nickname',
  setNicknameDescryption: 'Please set a nickname',
  enterNickname: 'Enter a nickname.',
  enterNicknameValidation: 'Please enter a nickname of 1 to 8 characters.',
  setNicknameSucceed: 'Nickname setting is complete.',
  setNicknameFailed: 'Failed to set nickname.',

  helloNickname: 'Hello, XX',
  helloWithoutNickname: 'Hello',

  hideBalance: 'Hide balance',
  hideBalanceSucceed1: 'Balance hiding has been set.',
  hideBalanceSucceed2: 'The balance is not hidden.',
  hideBalanceFailed: 'The balance hiding setting failed.',

  supportTeam: 'Customer Center',
  supportEmailCopied: 'The customer center\'s email has been copied.',

  asset: 'Asset status',
  sendCoinFailedLockedDetail: '- First deposit: 72 hours\n- OTP Setting/Change: 72 hours\n- Security Password Change: 72 hours',

  memberTrade: 'Between members', // Deposit between members, Withdrawal between member',
  externalTrade: 'External', // External deposit, External withdrawal',

  securitySettings: 'security settings',

  mnemonicValidationTitle: 'Check mnemonic code',
  mnemonicValidationDescryption: 'Please select mnemonic codes in correct order.',
  mnemonicValidationFailed: 'The order of the mnemonic codes is incorrect. Please check again.',

  /////////////////////////////////////////////// //////////////////////////

  exchangeCoinConfirmMessageWarn: '(Since the exchange rate is processed as the price at the time of exchange, there may be a slight error with the amount on the screen.)',
  generateQrMenu: 'Receive payment',
  amount: 'Amount',
  shopName: 'Shop name',
  serialNumber: 'Serial number',
  generate: 'Issue', // Issue QR',
  purchaseAmount: 'Payment amount',
  lastPamentList: 'Recent payment history',
  paymentCancelInsufficientUserBalance: 'The user\'s balance is insufficient to cancel.',
  paymentCancelInsufficientOrganizationBalance: 'The franchise\'s balance is insufficient to cancel.',
  paymentCancelInvalidPaymentIdx: 'The payment information is invalid.',
  paymentCancelNotOrganization: 'This is not a cancelable franchise.',
  paymentCancelFail: 'Cancel failed due to an unknown error.',
  paymentCancelSuccess: 'Cancellation is completed.',

  qrNoSerial: 'There is no franchise information.',

  purchaseSucceed: 'Your payment has been completed.',
  purchaseFailed: 'Your payment failed. Please try again.',

  InvalidFranchise: 'The franchise information is invalid.',
  InvalidCoinType: 'The coin type is invalid.',

  exchangeFailedTitle: 'Exchange Failed',
  exchangeFailed: 'The exchange failed.',

  setBankAccount: 'Set account',
  setBankAccountDetail: 'Please register an account number.',
  inputBankAccount: 'Enter account number',
  selectBankAccount: 'Select account',
  bankCodeValidation: 'Please select a bank.',
  accountValidation: 'Please enter your account number.',
  setAccountSucceed: 'Account entry is complete.',
  setAccountFailed: 'Account entry failed.',

  purchaseAmountValidation: 'Please enter an amount.',

  serverRequestErrorDescription2: 'Please restart the app.',
  showAddressDetail: 'Destination Tag must be correctly entered for deposit processing.',
  inputOtpValidation: 'Please enter OTP',

  id: 'Email',
  inputId: 'Email',
  loginFailedInvalidLoginInfo: 'The login information is invalid.',
  idLengthFail: 'Please enter your email.',

  step: 'Step',

  setupStepGuidePolicy: 'Proceed with registering an account for creating a wallet and entering a security password. Please check and agree to the terms and conditions to get start.',

  bankName: [
    '경남은행',
    '광주은행',
    'KB국민은행',
    'IBK기업은행',
    '농협은행',
    '대구은행',
    '부산은행',
    '산업은행',
    '새마을금고',
    '수협은행',
    '신한은행',
    '신협은행',
    'SC은행',
    '우리은행',
    '우체국',
    '제주은행',
    '카카오뱅크',
    'k뱅크',
    'KEB하나은행',
    'HSBC은행',
    '뱅크오브아메리카',
    '도이치은행',
  ],
  bankCode: [
    'BANK_KN',
    'BANK_KJ',
    'BANK_KB',
    'BANK_IBK',
    'BANK_NH',
    'BANK_DGB',
    'BANK_BUSAN',
    'BANK_KDB',
    'BANK_MG',
    'BANK_SH',
    'BANK_SHINHAN',
    'BANK_CU',
    'BANK_SC',
    'BANK_WOORI',
    'BANK_POST',
    'BANK_JEJU',
    'BANK_KAKAO',
    'BANK_K',
    'BANK_KEB',
    'BANK_HSBC',
    'BANK_AMERICA',
    'BANK_DB',
  ],
  payment: 'Payment',
  paymentList: 'Payment history',
  sendReceive: 'Send and Receive details',
  exchangeTarget: 'Exchange target',
  exchangeTargetAddress: 'Exchange target address',
  exchangeRatio: 'Exchange ratio',
  franchiseName: 'Franchise name',
  payback: 'Payback',

  noNotice: 'There are no registered notices.',
  notAvailableRecommender: 'No recommenders are found',
  sendCoinFailedOrganization: 'Cannot send coins to franchise account.',

  adjustAccount: 'Adjust account',

  nameFail: 'Please enter your name correctly.',

  paymentInfo: 'Payment information',
  paymentSucceedInfoArrived: 'The payment completion details have arrived.',
  paymentCanceledInfoArrived: 'The payment cancellation history has arrived.',
  payer: 'Payer',

  //////////////////////////////////////////v1.1///////////////////////////////////////////////////////
  languageSetting: 'Language setting',
  languageSettingModified: 'The language setting has been changed. Please restart the app.',

  eventTempTitle: 'OTC market is preparing various events.',
  eventTempSub1: "Airdrop through member registration and member recommendation",
  eventTempSub2: "Asset management through coin staking",
  eventTempSub3: "Special exchange event for popular coins",
  eventTempSub4: "Online & Offline Payment Event",
  eventTempSub5: "friend invite event, etc.",

  findPasswordDescription:'Enter your registered email and a link to change your password will be emailed to you.',
  findPasswordEmailSentSuccess:'An email has been sent to change your password.',
  
  /*********event**********/
  eventTitle:'event',
  eventListBtn:'my event history',

  onProgress:'in progress',
  more:'more',
  noEvent:'There are no registered events',

  
  /*********Event details**********/
  eventDetTitle:'event history',
  paidComplete:'Payment completed',
  eventNew:'New Membership Reward Event',


  /*********exchange*********/
  register:'Registrant',
  registDate:'Registration Date',
  doApply:'Buy',
  doCancel:'Cancel',
  exchangeMyList:'My History',
  exchangeRegist:'sales registration',
  viewAll:'view all',
  exchangeSelectCoin:'select',

  exchangeSameCoinFail:'The same coins cannot be traded.',

  exchangeSendCoin:'sending coin',
  exchangeReceiveCoin:'Received Coin',
  exchangeCoinInputAmount:'Please enter your coin',

  exchangePolicy01:'- When selling coins, the sum of the sales amount plus the commission will be deducted from the balance held.',
  exchangePolicy02:'- In case of cancellation of sales registration, the amount deducted from the deducted amount will be refunded.',
  exchangePolicy03:'- Once you register for sale, it will wait until it is matched or cancelled.',

  exchangeRegistNoBalance:'There is not enough balance to sell',
  exchangeRegistFail:'Failed. please try again.',

  exchangeCancel:'Cancel trading',
  exchangeCancelMessage:'Canceling a sale will incur a cancellation fee. Are you sure you want to cancel?',
  exchangeCancelFee:'Cancel Fee',
  exchangeDone:'trading completed',
  
  exchangeApply:'Buy',
  exchangeApplyMessage:'Once purchase is irrevocable. Would you like to buy it?',
  exchangeApplyNoBalance:'There is not enough balance to purchase',
  exchangeInvaild:'The trading has already been made or has been cancelled.',
  exchangeSelfFail:'You cannot trade with yourself.',
  exchangeRegistDate:'Registration time',

  exchangeStatusPending:'Pending trade',
  exchangeStatusCancel:'Cancel completed',
  exchangeStatusMatch:'trading completed',

  exchangeMyRegist:'Sales list',
  exchangeMyApply:'Purchase list',
  exchangeBuyCoin:'Buy Coin',
  exchangeSentCoin:'Sent Coin',
  
  pushSetting:'push setting',

  recommendTextWithRewardAdditionRecommend:'%s recommended [%s Wallet App] to you! \n1. When you sign up for the app, you will receive %s %s coins.\n2. If you enter the referral code when signing up for the app, you will receive an additional %s %s reward!\n3. Friend Invite Referral Reward-You can get an additional %s %s coin every time you invite one friend through Invite Friends.\nPlease take advantage of the %s platform with your friends!\n\nRecommender Code: %s\n\nReceive an airdrop of numerous coins listed on Coinbiz~\n%s',
  recommendTextWithRewardRecommend:'%s recommended [%s Wallet App] to you! \n1. When you sign up for the app, you will receive %s %s coins.\n2. Friend Invite Referral Reward-You can get an additional %s %s coin every time you invite one friend through Invite Friends.\nPlease take advantage of the %s platform with your friends!\n\nRecommender Code: %s\n\nReceive an airdrop of numerous coins listed on Coinbiz~\n%s',
  recommendTextWithReward:'%s recommended [%s Wallet App] to you! \nWhen you sign up for the app, you will receive %s %s coins.\nGet the benefits of the %s platform with your friends!\n\nRecommender Code: %s\n\nReceive airdrops of numerous coins listed on Coinbiz Look~\n%s',
  recommendText:'%s recommended [%s Wallet App] to you! \nGet the benefits of the %s platform with your friends!\n\nRecommender Code: %s\n\nReceive airdrops of numerous coins listed on Coinbiz~\n%s',
  recommendTextCopied:'The recommended text has been copied.',

  staking:'staking',
  stakingInterest:'staking interest',
  stakingInterestInterval:'daily',
  unstaking:'unstaking',
  stakingDescription1:'When staking, %s% interest is paid in %s every day.',
  stakingDescription2:'In case of staking, unstaking (withdrawal) is not allowed for %s days.',
  stakingDescription3:'Unstaking. Interest is no longer paid on the canceled quantity.',
  stakingAvailableAmount:'AvailableAmount',
  stakingApplyAmount:'Application count',
  stakingInvalidAmount:'Please enter the available quantity',
  stakingConfirm:'Would you like to stake %s %s coins?',
  stakingReleaseConfirm:'Would you like to unstake %s %s coins?',
  stakingResultSuccess:'Staking is complete.',
  stakingResultFail:'Staking has failed. It is a coin that has insufficient balance or cannot be staking.',
  stakingReleaseResultSuccess:'Unstaking is complete.',
  stakingReleaseResultFail:'Unstaking failed. The balance is insufficient or unstaking is not possible.',
  stakingNoBalance:'There is no quantity available to unlock staking',

  exchangeStatus: 'Transaction status',
  search: 'Search',

  // 추가사항
  termText: 'Account registration and security password entry will be carried out to create a wallet. Please check and agree to the terms and conditions below to begin.',
  differentPw : 'Passwords are different. Please check again.',
  idOk:'It is available.',
  inputEmail2:'Please enter your email.',
};