import { Form, Input, Checkbox, Button, Modal } from 'antd';
import React from 'react';
import "../css/signUp.scss";
import "../css/main.scss";
import { httpGet, httpUrl, httpPost } from "../api/httpClient";
import crypto, { timingSafeEqual } from 'crypto';
import { connect } from "react-redux";
import { Route, withRouter } from "react-router-dom";
import { formatBalance } from "../util/coinUtil";

const FormItem = Form.Item;

class CoinMc extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            blockchains: [],
            balances: [],
        };
    }
    componentDidMount() {
        this.requestSystemListBlockchain();
        this.requestBalance();
    }
    requestBalance = () => {
        console.log("##### Request balance")
        httpGet(httpUrl.balance, [0], {}).then((result) => {
            result = result.data;
            console.log(result)
            this.setState({ balances: result })
        }).catch(e => {
            console.log("## requestBalance error: " + e)
        });
    }
    requestSystemListBlockchain = () => {
        console.log("## Loading systemListBlockchain");
        httpGet(httpUrl.systemListBlockchain, [global.language], {}).then((result) => {
            result = result.data;
            console.log('## systemListBlockchain loaded');
            this.setState({ blockchains: result })
        }).catch((e) => {
            console.log('## systemListBlockchain load error: ' + e)
        });
    }

    render() {
        
        return (
            <div className="wrapperMain">
                <div className="mainContent">
                    <div className="container" style={{ textAlign: 'center', paddingBottom: '30px' }}>

                        {this.state.blockchains.map((value, index) => {
                            let balanceRow = this.state.balances.find(x=>x.coinType==value.coinType);
                            let balance = 0;
                            if (balanceRow != null) balance = balanceRow.balance;
                            return (
                                <div className="blockchain-wrapper" key={index}>
                                    <div className="blockchain-image"><img src={value.image}/></div>
                                    <div className="blockchain-content">
                                        <div className="blockchain-content-name">{value.name}</div>
                                        <div className="blockchain-content-balance">{formatBalance(balance, value.decimal, 4)}<span>{value.symbol}</span></div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(connect(null, null)(CoinMc));