class Language {
    static getLanguageList() {
        return [
            {code: 'en', name: 'English'},
            {code: 'ja', name: '日本語'},
            {code: 'ko', name: '한국어'},
        ];
    }
    static getServerLocale() {
        var locale = navigator.language || navigator.userLanguage; 
        console.log("## locale : " + locale);
        global.deviceLocale = locale;
        if (!global.deviceLocale || global.deviceLocale.length < 2) global.deviceLocale = 'ko';
        let lang = "en";
        if (locale.slice(0,2) == "jp") lang = 'ja';
        else if (locale.slice(0,2) == "en") lang = 'en';
        return lang;
    }
    static getString() {

        switch(global.language){
            case 'ko': return require('../../res/string_ko').default;
            case 'en': return require('../../res/string_en').default;
            case 'ja': return require('../../res/string_ja').default;
            default: return require('../../res/string_en').default;
        }
    }
}
export default Language;