export default {
  serverProtocol: 'https',
  serverIp: 'back.otcex.io',
  serverPort: '18001',
  serverContext: '',

  // serverProtocol: 'http',
  // serverIp: 'localhost',
  // serverPort: '18001',
  // serverContext: '',

  appCode: 'otcex'
};
