import React, { Component } from 'react';
import { connect } from 'react-redux';
import { httpGet, httpUrl } from '../api/httpClient';
import Coin from '../lib/coin/coin';
import Language from '../lib/util/language';
import { formatDate, startDateFormat, endDateFormat, amountFormat } from "../util/util";
import { Form, Table, DatePicker, Input, Button, Pagination, Modal } from "antd";

class MyEvent extends Component {
  constructor(props) {
    super(props);
    this.string = Language.getString();

    this.state = {
      tableData: [],
      pagination: {
        total: 0,
        current: 1
      },
      lastPage: 0,
    };
  }
  back = () => {
    this.props.history.push('/event')
  };
  componentDidMount() {
    this.getMyEventList();
  }

  getMyEventList = () => {
    httpGet(httpUrl.myEventList, [10, this.state.pagination.current])
      .then(result => {
        result = result.data;
        const pagination = { ...this.state.pagination };
        pagination.current = result.currentPage;
        pagination.total = result.totalCount;
        this.setState({
          tableData: result.eventRewards,
          pagination,
        });
      })
      .catch();
  };
  handlePageChange = (page, pageSize) => {
    const pager = { ...this.state.pagination };
    pager.current = page;
    this.setState({
      pagination: pager,
    }, () => this.getMyEventList());
  };
  renderFlatList = (rowData) => {
    const string = this.string;
    return (
      <div>
        <div className="myevent-item-container">
        <div className="myevent-item-status-wrapper">
          <div className="myevent-item-title">
            {rowData.title}
          </div>        
            <div className="myevent-item-status">
              {string.paidComplete}
            </div>
          </div>
          <div className="myevent-item-status-wrapper2">
          <div className="myevent-item-amount">
            {(
              rowData.amount /
              Coin.getCoinUnit(rowData.coinType).base
            ).toFixed(4) + ' ' + this.props.coinList.find(
              x => x.coinType == rowData.coinType
            ).symbol}
          </div>
          <div className="myevent-item-date">
            {formatDate(rowData.createDate)}
          </div>
          </div>
        </div>
      </div>
    );
  }
  render() {
    const string = this.string;
    return (
      <div className="event-content">
        <div className="mytran-header-back-wrapper" onClick={() => { this.back(); }}>
          <img src={require('../img/transaction/backBtn.png')} />
        </div>
        
        <div className="currency-top-refresh" onClick={this.getMyEventList} style={{paddingLeft: '30px', paddingTop: '10px'}}>
            <img src={require('../img/transaction/refreshBtn.png')}/>
        </div>
        <br/>
        <br/>

        {(this.state.tableData instanceof Array) && this.state.tableData.map(value => {
          return this.renderFlatList(value);
        })}

        <div className="pagination">
          <Pagination
            onChange={this.handlePageChange}
            defaultCurrent={1}
            pageSize={10}
            total={this.state.pagination.total} />
        </div>
      </div>

    );
  }
}

const mapStateToProps = (state) => {
  const { coinList } = state
  return { coinList }
};

export default connect(mapStateToProps)(MyEvent);
