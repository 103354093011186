import React from 'react';
import { Pagination } from 'antd';
import { withAlert } from 'react-alert';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { setCoinAddress, setCoinBalance, setCoinStaking, setCoinList } from '../actions';
import { httpGet, httpPost, httpUrl } from "../api/httpClient";
import "../css/main.scss";
import "../css/myWallet.scss";
import "../css/myWallet_m.scss";
import "../css/signUp.scss";
import Language from '../lib/util/language';
import NumberFormat from '../lib/util/numberformat';
import AddressDialog from './dialog/addressDialog';
import { formatDateSecond } from "../lib/util/dateUtil";
import Coin from '../lib/coin/coin';
import TradeSend from './TradeSend';
import { reactLocalStorage } from "reactjs-localstorage";
import Const from "../const";

class TradeInfo extends React.Component {
  constructor(props) {
    super(props);
    this.string = Language.getString();

    this.state = {
      selectedCoinType: this.props.selectedCoinType,
      tableData: [],
      pagination: {
        total: 0,
        current: 1
      },

      currency: global.currency,
      exchangeRate: global.exchangeRate,

      active: 0,

      addressDialogVisible: false,
      addressDialogCoin: 0,
      addressDialogAddress: '',

      sendVisible: false,
    }
  }

  componentDidMount() {
    this.setActive(0);

    // console.log('stake setting='+JSON.stringify(global.stakingSettings))
    // console.log(
    //   `-------coinList------\n${JSON.stringify(this.props.coinList, null, 4)}`
    // );
  }

  componentWillUnmount() {
  }

  componentDidAppear = () => {
    this.setState({ currency: global.currency });
  };

  setActive = (idx) => {
    this.setState({
      tableData: [],
      pagination: {
        total: 0,
        current: 1
      },
      isShowCoinSelect: false,
      active: idx
    }, () => {
      this.initialLoadActive();
    });

  }
  initialLoadActive = () => {
    this.setState({
      tableData: [],
    })

    this.requestBalance(this.state.selectedCoinType);

    if (this.state.active == 0) this.getTradeList();
  }
  requestBalance = (coinType, hide) => {
      console.log("##### Request balance")
      return new Promise((resolve, reject) => {
          httpGet(httpUrl.balance, [0], {}, hide).then((result) => {
              this.props.setCoinBalance(result.data);
              reactLocalStorage.setObject(Const.appCode + "#coinbalance", result.data);

              httpGet(httpUrl.getStakingBalance, [], {}, hide).then((result) => {
                  this.props.setCoinStaking(result.data.stakingBalances);
                  reactLocalStorage.setObject(Const.appCode + "#coinstaking", result.data.stakingBalances);
                  resolve();

              }).catch(e => {
                  console.log("## getStakingBalance error: " + e)
                  reject();
              });
          }).catch(e => {
              console.log("## requestBalance error: " + e)
              reject();
          });
      })
  }
  handlePageChange = (page, pageSize) => {
    const pager = { ...this.state.pagination };
    pager.current = page;
    pager.total = pageSize;
    this.setState({
      refreshing: true,
      pagination: pager,
    }, () => this.getTradeList());
  };

  getTradeList = () => {
    httpGet(httpUrl.transLog, [10, this.state.pagination.current, this.state.selectedCoinType]).then((result) => {
      result = result.data;

      console.log(JSON.stringify(result, null, 4));

      let tableData = [];
      result.logs.forEach((obj, idx) => {
        const rowData = {};
        // rowData['transTimeString'] = obj.createDateString;
        rowData['transTimeString'] = formatDateSecond(obj.createDate);
        rowData['direction'] = obj.direction;
        rowData['type'] = obj.type;
        rowData['coinType'] = obj.coinType;
        rowData['status'] = obj.status;
        let targetAddr = (obj.userName ? '[' + obj.userName + ']' : '') + obj.targetAddr;//.substr(0,8)+'...'
        // let targetCnt = (obj.state == 1 ? obj.toCount : obj.fromCount);
        // (targetCnt > 1) && (targetAddr += util.format(string.extraCount, targetCnt - 1));
        rowData['targetAddr'] = targetAddr;
        rowData['amount'] = obj.amount;
        rowData['memo'] = obj.memo;
        rowData['sourceAddr'] = obj.from;
        rowData['fee'] = obj.fee;
        rowData['txId'] = obj.txid;
        rowData['destinationTag'] = obj.destinationTag;

        tableData.push(rowData);
      })

      const pagination = { ...this.state.pagination };
      pagination.total = result.totalCount;

      this.setState({
        tableData: tableData,
        refreshing: false,
        pagination
      });
    }).catch(() => {
    }).finally(() => {
    });
  }

  onClickSend(coinType) {
    this.setState({ sendVisible: true })
  }
  closeSend = () => {
    this.setState({ sendVisible: false })
  }

  onClickReceive(coinType, address) {
    console.log('##### onClickReceive: ' + address);
    if (address == '' || address == null) {
      console.log('## Adding coin!!');
      this.addCoin(coinType);
    } else {
      this.setState({
        addressDialogVisible: true,
        addressDialogCoin: this.props.coinList.find(x => x.coinType == coinType),
        addressDialogAddress: address,
      })
    }
  }

  addCoin(coinType) {
    httpPost(httpUrl.addCoin, [], { coinType: coinType })
      .then(result => {
        console.log('addCoin result: ' + JSON.stringify(result));
        result = result.data;

        this.props.setCoinAddress(coinType, result.address);

        this.setState({
          addressDialogVisible: true,
          addressDialogCoin: this.props.coinList.find(x => x.coinType == coinType),
          addressDialogAddress: result.address,
        })
        console.log('## addCoin success');
      })
      .catch(e => {
        console.log('## addCoin error: ' + e);
      });
  }

  renderFlatList = (rowData) => {
    const string = this.string;
    let stateBorderColor = '';
    let stateTextColor = '';
    let stateText = '';
    let titleText = '';
    if (this.state.active == 0) {
      titleText = rowData.targetAddr;
      if (rowData.direction == 'OUT') {
        stateBorderColor =
          rowData.status == 'SUCCESS' ? '#a7ceef' : '#2d67ff';
        stateTextColor =
          rowData.status == 'SUCCESS' ? '#4f95d0' : '#2d67ff';
        stateText =
          rowData.status == 'SUCCESS'
            ? string.sent
            : rowData.status == 'FAIL'
              ? string.failed
              : string.sending;
        stateText = rowData.type == 'I2E' ? string.externalTrade + " " + stateText : stateText;
        stateText = rowData.type == 'I2A' ? string.adjustAccount : stateText;
      } else {
        stateBorderColor =
          rowData.status == 'SUCCESS' ? '#f5bbbb' : '#2d67ff';
        stateTextColor =
          rowData.status == 'SUCCESS' ? '#e76969' : '#2d67ff';
        stateText =
          rowData.status == 'SUCCESS'
            ? string.received
            : rowData.status == 'FAIL'
              ? string.failed
              : string.receiving;
        stateText = rowData.type == 'E2I' ? string.externalTrade + " " + stateText : stateText;
        stateText = rowData.type == 'AIR' ? string.received : stateText;
      }
    }
    else if (this.state.active == 1) {
      titleText = string.exchangeTarget + ' : ' + (rowData.amount / Coin.getCoinUnit(this.state.selectedCoinType).base * rowData.ratio).toFixed(6) + ' ' + this.props.coinList.find(x => x.coinType == rowData.toCoinType).symbol;
      stateBorderColor = '#2d67ff';
      stateTextColor = '#2d67ff';
      stateText = string.exchange;
    }
    else if (this.state.active == 2) {
      titleText = rowData.franchiseName;
      stateBorderColor = '#2d67ff';
      stateTextColor = '#2d67ff';
      stateText = string.payment;
      if (rowData.canceled == 1) {
        stateBorderColor = '#a7ceef';
        stateTextColor = '#a7ceef';
        stateText = string.cancel;
      }
    }
    return (
      <div className="trade-item-container">
        <div className="trade-item-title">
          {titleText}
        </div>
        <div className="trade-state" style={{color: stateTextColor, borderColor: stateBorderColor}}>
          {stateText}
        </div>
        <div className="trade-amount">
          {(
            rowData.amount /
            Coin.getCoinUnit(this.state.selectedCoinType).base
          ).toFixed(6)}
        </div>
        <div className="trade-amount-unit">
          {
            this.props.coinList.find(
              x => x.coinType == this.state.selectedCoinType
            ).symbol
          }
        </div>
        <div className="trade-date">
          {rowData.transTimeString}
        </div>
      </div>

    );
  }

  render() {
    const string = this.string;
    if (this.state.sendVisible) {
      return (<TradeSend 
        close={this.closeSend}
        selectedCoinType={this.state.selectedCoinType}
        requestBalance={this.requestBalance}/>);
    }
    else
      return (
        <div className="trade-container">
          <div className='trade-title-container'>
          <div className="trade-title">
            {string.menuTradeInfo}
          </div>
          <div className="trade-button-container">
            <div className="mainColorBtnLarge trade-button" onClick={() => {
              this.onClickReceive(
                this.state.selectedCoinType,
                this.props.coinList.find(
                  x => x.coinType == this.state.selectedCoinType
                ).address
              );
            }}>
              {string.receive}
            </div>
            <div className="mainColorBtnLarge trade-button" onClick={() => {
              this.onClickSend(this.state.selectedCoinType);
            }}>
              {string.menuTradeSend}
            </div>

          </div>
          </div>
          <div>
            {this.state.tableData.map(item => {
              console.log(item);
              if (!item) return;
              return this.renderFlatList(item);
            })}
          </div>
          <div style={{textAlign: 'center'}}>
          <Pagination
            onChange={this.handlePageChange}
            defaultCurrent={1}
            pageSize={10}
            total={this.state.pagination.total} />

          </div>
          <AddressDialog
            onCancel={() => this.setState({ addressDialogVisible: false })}
            visible={this.state.addressDialogVisible}
            coin={this.state.addressDialogCoin}
            address={this.state.addressDialogAddress}
          />
        </div>

      );
  }
}

const mapStateToProps = state => {
  const { coinList, loginInfo } = state;
  return { coinList, loginInfo };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ setCoinList, setCoinBalance, setCoinStaking, setCoinAddress }, dispatch);

export default withAlert()(connect(
  mapStateToProps,
  mapDispatchToProps
)(TradeInfo));

