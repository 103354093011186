import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setCoinList, setCoinBalance, setCoinAddress, setCoinCurrency } from '../actions';
import { Route, withRouter } from "react-router-dom";
import { httpGet, httpUrl } from '../api/httpClient';
import Language from '../lib/util/language';
import { formatDateDay } from "../lib/util/dateUtil";
import { Form, Table, DatePicker, Input, Button, Pagination, Modal } from "antd";

class Event extends Component {
  constructor(props) {
    super(props);
    this.string = Language.getString();
    this.state = {
    //   showEventContent: false,
      tableData: [],
    //   currentPage: 0,
    //   lastPage: 0,
    //   refreshing: false,
      pagination: {
        total: 0,
        current: 1
      },
      eventDetailVisible: false,
      eventDetailContent: '',
    };
  }

  onClickEventDetTab() {
    // Navigation.push(this.props.componentId, {
    //   component: {
    //     name: 'navigation.EventDetTab',
    //     passProps: {
    //       coinList: this.props.coinList
    //     }
    //   },
    // });
  }
  componentDidMount() {
    this.getEventList();
  }

  getEventList = () => {
    httpGet(httpUrl.eventList, [10, this.state.pagination.current]).then(result => { 
        result = result.data;
        const pagination = { ...this.state.pagination };
        pagination.current = result.currentPage;
        pagination.total = result.totalCount;
        this.setState({
          tableData: result.events,
          pagination,
        });
      })
      .catch();
  };

  handlePageChange = (page, pageSize) => {
    const pager = { ...this.state.pagination };
    pager.current = page;
    this.setState({
      pagination: pager,
    }, () => this.getEventList());
  };

  toggleEvent = contents => {
    this.setState({eventDetailContent: contents.replace(/@loginid/g,this.props.loginInfo.id), eventDetailVisible: true});
  };
  doLink = (link, linkTarget) => {
    const linkUrl = link + (link.indexOf('?') > 0 ? '&' : '?') + 'id=' + this.props.loginInfo.id;
    global.window.open(linkUrl);
  }
  renderFlatList = ( item ) => {
      const string = this.string;
      return (
            <div className="event-item-container" onClick={()=>this.toggleEvent(item.content)}>
                <div className="event-item-title-box">
                <div className='event-item-title'>{item.title}</div>
                {item.link != null && (<div onClick={() => this.doLink(item.link, item.linkTarget)} className="event-item-link">{string.more}</div>)}
                </div>
                <div className='event-img-container'>
                    {item.image != null && (
                        <img src={item.image} />
                    )}
                </div>
            </div>
      )
  }
  render() {
      const string = this.string;
      return (
        <div className="event-content">
            <div className="event-top-title">
                {string.menuEvent}
            </div>
            <div className="currency-top-refresh" onClick={this.getEventList} style={{paddingLeft: '30px', paddingTop: '10px'}}>
                <img src={require('../img/transaction/refreshBtn.png')}/>
            </div>
            <div style={{float: 'right'}} className="signInBtn" onClick={()=>this.props.history.push('/myevent')}>
              {string.eventListBtn}
            </div>
            <div className="divider"></div>
            {(this.state.tableData instanceof Array) && this.state.tableData.map(value => {
                return this.renderFlatList(value);
            })}             
            <div className="pagination">
            <Pagination
              onChange={this.handlePageChange}
              defaultCurrent={1}
              pageSize={10}
              style={{marginTop: 10}}
              total={this.state.pagination.total} />
            </div>
            <Modal
                title={string.eventTitle}
                visible={this.state.eventDetailVisible}
                onCancel={()=>this.setState({eventDetailVisible: false})}
                cancelText={string.close}
                footer={[
                    <Button key="back" onClick={()=>this.setState({eventDetailVisible: false})}>
                      {string.close}
                    </Button>
                  ]}>
                <div dangerouslySetInnerHTML={ {__html: this.state.eventDetailContent} }>
                </div>
            </Modal>
        </div>
      );
    }
}

const mapStateToProps = state => {
    const { coinList, loginInfo } = state;
    return { coinList, loginInfo };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators({ setCoinList, setCoinBalance, setCoinAddress, setCoinCurrency }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Event));

