import React, { Component, useState, Fragment } from "react";

import { Form, Table, DatePicker, Input, Button, Pagination, Select } from "antd";

import "../css/signUp.scss";
import "../css/main.scss";
import "../css/transaction.scss";
import "../css/transaction_m.scss";
import { Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { reactLocalStorage } from "reactjs-localstorage";
import { httpGet, httpUrl, httpPost } from "../api/httpClient";
import { formatDate, startDateFormat, endDateFormat, amountFormat } from "../util/util";
import CoinSelectBox from "../components/common/CoinSelectBox";
import { Modal } from "../components/common/Modal";
import { value } from "../res/string_en";
import Language from '../lib/util/language';
import Const from '../const';
import { withAlert } from 'react-alert'
import Coin from '../lib/coin/coin';

class MyTransaction extends Component {
    string = Language.getString();
    constructor(props) {
        super(props);
        this.state = {
            actionType: 0,
            tableData: [],
            refreshing: false,
            currentTab: 0,
            exchangeFee: 0,
            exchangeCancelFee: 0,
            pagination: {
              total: 0,
              current: 1
            },
        };
    }
    back = () => {
        this.props.changeMyTransaction(0)
    };

    componentDidMount() {
        this.getMyExchangeList();
        httpGet(httpUrl.exchangeSetting, [])
            .then(result => {
                this.setState({ exchangeFee: result.data.fee, exchangeCancelFee: result.data.cancelFee })
            })
            .catch();
    }
    getMyExchangeList = () => {
        httpGet(httpUrl.exchangeMyList, [10, this.state.pagination.current, this.state.actionType])
            .then(result => {
                result = result.data;
                const pagination = { ...this.state.pagination };
                pagination.current = result.currentPage;
                pagination.total = result.totalCount;
                
                this.setState({
                    tableData: result.exchanges,
                    refreshing: false,
                    pagination
                });
            })
            .catch();
    }

    handlePageChange = (page, pageSize) => {
      const pager = { ...this.state.pagination };
      pager.current = page;
      pager.total = pageSize;
      this.setState({
        refreshing: true,
        pagination: pager,
      }, () => this.getMyExchangeList());
    };

    handleRefresh = () => {
        console.log('exchange handleRefresh')
        this.setState({ refreshing: true }, () => {
            this.getMyExchangeList();
        })
    }

    reload = () => {
        this.setState({
            tableData: [],
            pagination: {
              total: 0,
              current: 1
            },
        }, () => {
            this.getMyExchangeList();
        });
    }
    cancelExchange = (idx, amount, symbol) => {
        let stringMsg = this.string.exchangeCancelMessage;
        if (this.state.exchangeCancelFee > 0) {
            stringMsg += "\n" + this.string.exchangeCancelFee + ": " + (amount * this.state.exchangeCancelFee) + ' ' + symbol;
        }
        if (global.confirm(stringMsg)) {
            httpPost(httpUrl.exchangeCancel, [idx], {}).then((result) => {
            if(result.data) {
                this.reload();
            }
            else this.props.alert.show(this.string.serverFail)
            }).catch(() => {
            });
        }
    }
    renderFlatList = (item) => {
        let fromCoinInfo = this.props.coinList.find(x => x.coinType == item.fromCoinType)
        let toCoinInfo = this.props.coinList.find(x => x.coinType == item.toCoinType)
        let statusString = {
            PENDING: this.string.exchangeStatusPending,
            CANCEL: this.string.exchangeStatusCancel,
            MATCH: this.string.exchangeStatusMatch,
        };

        let fromCoinString = '';
        let toCoinString = '';
        if (this.state.currentTab == 0) {
            fromCoinString = this.string.exchangeSendValue;
            toCoinString = this.string.exchangeReceiveValue;
        }
        else if (this.state.currentTab == 1) {
            fromCoinString = this.string.exchangeBuyCoin;
            toCoinString = this.string.exchangeSentCoin;
        }
        return (
            <div className="mytran-list-item-container">
                <div className="mytran-list-item-detail">
                    <div className="mytran-list-item-coin">
                        {fromCoinString}: <span className="mytran-list-item-coin-num1">{(item.fromAmount / Coin.getCoinUnit(item.fromCoinType).base).toFixed(4)}</span> <span className="mytran-list-item-symbol">{fromCoinInfo.symbol}</span>
                    </div>
                    <div className="mytran-list-item-coin"> {toCoinString}: <span className="mytran-list-item-coin-num2">{(item.toAmount / Coin.getCoinUnit(item.fromCoinType).base).toFixed(4)}</span> <span className="mytran-list-item-symbol">{toCoinInfo.symbol}</span> </div>
                </div>
                <div className="mytran-list-item-detail">
                    <div className="mytran-list-item-fee"> {this.string.fee}: {(item.fee * item.fromAmount / Coin.getCoinUnit(item.fromCoinType).base).toFixed(4)} {fromCoinInfo.symbol} </div>
                    <div className="mytran-list-item-date"> {this.string.exchangeRegistDate}: {formatDate(item.createDate)}</div>
                </div>
                <div className="mytran-list-item-status">
                    <div className="">
                        <div className="">
                            <div className="" onPress={() => { }}>
                                <div className="mytran-list-item-status-text">
                                    {statusString[item.status]}
                                </div>
                            </div>
                            {item.status == "PENDING" && (
                                <div className="mytran-list-item-status-cancel" onClick={() => { this.cancelExchange(item.idx, (item.fromAmount / Coin.getCoinUnit(item.fromCoinType).base).toFixed(2), fromCoinInfo.symbol); }}>
                                    {this.string.doCancel}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    render() {
        return (
            <Fragment>
                <div className="mytran-header-container">
                    <div className="mytran-header-back-wrapper" onClick={() => { this.back(); }}>
                        <img src={require('../img/transaction/backBtn.png')} />
                    </div>
                    <div className="currency-top-refresh" onClick={this.reload} style={{paddingLeft: '30px', paddingTop: '10px'}}>
                        <img src={require('../img/transaction/refreshBtn.png')}/>
                    </div>
                    <div className="mytran-header-button-wrapper">
                        <div className={this.state.actionType == 0 ? 'mainColorBtnLarge' : 'grayColorBtnLarge'} style={{marginRight: 10}} onClick={() => {
                            this.setState({
                                actionType: 0,
                                tableData: [],
                                currentPage: 0,
                                lastPage: 0,
                                currentTab: 0,
                            }, () => { this.getMyExchangeList() })
                        }}>{this.string.exchangeRegist} </div>
                        <div className={this.state.actionType == 1 ? 'mainColorBtnLarge' : 'grayColorBtnLarge'} onClick={() => {
                            this.setState({
                                actionType: 1,
                                tableData: [],
                                currentPage: 0,
                                lastPage: 0,
                                currentTab: 1,
                            }, () => { this.getMyExchangeList() })
                        }}>{this.string.exchangeMyApply}</div>
                    </div>
                </div>

                <div className="mytran-list-container">
                    {this.state.tableData.map(item => {
                        console.log(item);
                        if (!item) return;
                        return this.renderFlatList(item);
                    })}
                </div>               
            
                <Pagination
                onChange={this.handlePageChange}
                defaultCurrent={1}
                pageSize={10}
                total={this.state.pagination.total} />
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    const { coinList, loginInfo } = state;
    return { coinList, loginInfo };
};


export default withAlert()(withRouter(connect(mapStateToProps, null)(MyTransaction)));
