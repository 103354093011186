
import Coin from '../coin/coin';

class NumberFormat {
  static commaNumber(a) {
    var b = Math.round(a * Coin.decimalPlace) / Coin.decimalPlace;

    var parts = b.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return parts.join(".");
  }
}
export default NumberFormat;