import React, { Component } from 'react';
import { Switch, Route, Redirect } from "react-router-dom";
import SignUp from '../pages/SignUp';
import Privacy from '../pages/Privacy';
import Login from '../pages/Login';
import MyWallet from '../pages/MyWallet';
import Currency from '../pages/Currency';
import Event from '../pages/Event';
import ModifyPassword from '../pages/ModifyPassword';
import MainPage from '../pages/MainPage';
import Mobile from '../pages/Mobile';
import Exchange from '../pages/Exchange';
import Transaction from '../pages/Transaction';
import MyEvent from '../pages/MyEvent';
import Notice from '../pages/Notice';

export default class Content extends Component {
    
    render() {
        // if (/Android/i.test(navigator.userAgent) || /iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        //     return (<Mobile/>)
        // }
        // else {
            return (
                <Switch>
                    {/* <Route exact path="/mainpage" component={MainPage} /> */}
                    <Route exact path="/mywallet" component={MyWallet} />
                    <Route exact path="/currency" component={Currency} />
                    <Route exact path="/event" component={Event} />
                    <Route exact path="/myevent" component={MyEvent} />
                    <Route exact path="/notice" component={Notice} />
                    <Route exact path="/exchange" component={Transaction} />

                    <Route exact path="/privacy" component={Privacy} />
                    {/* <Route exact path="/signup" component={SignUp} /> */}
                    {/* <Route path="/password/modify" component={ModifyPassword} /> */} 
                    {/* <Route exact path="/mobile" component={Mobile} /> */}
                    <Redirect to="/currency" />
                </Switch>
            );
        }        
    // }
}
