import { Form, Input, Checkbox, Button, Modal } from 'antd';
import React from 'react';
import "../css/signUp.scss";
import "../css/main.scss";
import "../css/myWallet.scss";
import "../css/myWallet_m.scss";
import { httpGet, httpUrl, httpPost } from "../api/httpClient";
import crypto, { timingSafeEqual } from 'crypto';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { setCoinList, setCoinBalance, setCoinStaking, setCoinAddress } from '../actions';
import { Route, withRouter } from "react-router-dom";
import { formatBalance } from "../util/coinUtil";
import NumberFormat from '../lib/util/numberformat';
import Language from '../lib/util/language';
import TradeInfo from './TradeInfo';
import TradeSend from './TradeSend';
import { reactLocalStorage } from "reactjs-localstorage";
import Const from "../const";

const FormItem = Form.Item;

class MyWallet extends React.Component {
    constructor(props) {
        super(props);
        this.string = Language.getString();
        this.state = {
            blockchains: [],
            balances: [],
            currency: global.currency,

            selectedCoinType: 0
        };
    }
    componentDidMount() {
        this.calTotalValue();
        this.requestBalance();
        this.reload()
    }

    // 상세보기
    showDetail(coinType) {
        if (coinType == this.state.selectedCoinType) {
            this.setState({
                selectedCoinType: -1
            })
        } else {
            this.setState({
                selectedCoinType: coinType
            })
        }
    }

    reload = () => {
        this.calTotalValue();
        this.requestBalance();
    } 

    calTotalValue() {
        if (!Array.isArray(this.props.coinList)) return '-';
        let totalValue = 0;
        let allLoaded = true;
        this.props.coinList.forEach(coin => {
            allLoaded &= coin.valueLoaded;
            totalValue += coin.value;
        });
        return totalValue.toFixed(0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
   
    }

    requestBalance = (coinType, hide) => {
        console.log("##### Request balance")
        return new Promise((resolve, reject) => {
            httpGet(httpUrl.balance, [0], {}, hide).then((result) => {
                this.props.setCoinBalance(result.data);
                reactLocalStorage.setObject(Const.appCode + "#coinbalance", result.data);
  
                httpGet(httpUrl.getStakingBalance, [], {}, hide).then((result) => {
                    this.props.setCoinStaking(result.data.stakingBalances);
                    reactLocalStorage.setObject(Const.appCode + "#coinstaking", result.data.stakingBalances);
                    resolve();
  
                }).catch(e => {
                    console.log("## getStakingBalance error: " + e)
                    reject();
                });
            }).catch(e => {
                console.log("## requestBalance error: " + e)
                reject();
            });
        })
    }
    render() {
        const string = this.string;
            return (
                
                <div className="walletContent">                
                    <p className="intro">{this.props.loginInfo ? string.helloNickname.replace('XX', this.props.loginInfo.name) : ''}</p>
                    <div className="currency-top-refresh" onClick={this.requestBalance}>
                        <img src={require('../img/transaction/refreshBtn.png')}/>
                    </div>
                    <div className="possCoinTitle">
                        {string.myValue}
                    </div>
                    <div className="possCoinContent">
                        <img src={require('../img/myWallet/miniLogo.png')} className="miniLogo" />
                        {/* <div className="possCoin">{this.state.balances.length > 0 ? this.state.balances.reduce((accumulator, currentValue) => accumulator + (currentValue.balance/)) : 0} </div> */}
                        <div className="possCoin">{this.calTotalValue()} </div>
                        <div className="coinUnit">{string.localCoinUnit[this.state.currency]}</div>
                    </div>

                    <div className="possCoinListTitle">
                        {string.myCoin}
                        {/* <img className="searchBtn" src={require('../img/myWallet/searchBtn.png')}/> */}
                    </div>
                    <div className="spanLine"></div>

                    {this.props.coinList instanceof Array && this.props.coinList.map((value, index) => {
                        let balanceRow = this.state.balances.find(x => x.coinType == value.coinType);
                        let balance = 0;
                        if (balanceRow != null) balance = balanceRow.balance;
                        return (
                            <div key={index} >
                            <div className="blockchainContent" style={{cursor: 'pointer'}} onClick={()=>{this.showDetail(value.coinType);}}>
                                <div className="content">
                                    <div className="blockchainImage">
                                        <img src={value.image} />
                                    </div>
                                    <div className="blockchainTitle">
                                        <div className="blockchainContentName">{value.name}</div>
                                        <div className="blockchainContentSymbol">{value.symbol}</div>

                                    </div>
                                    <div className="costTable">
                                        <div className="blockchainCost">
                                            <div className="costNum">{value.balance == 0 ? 0 : NumberFormat.commaNumber(value.balance.toFixed(4))}<span className="costSymbol">{value.symbol}</span></div>
                                            <div className="costKo">{value.value.toFixed(0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}<span className="costKoSymbol">KRW</span></div>
                                        </div>

                                    </div>
                                </div>
                                <div className='minus-box'>
                                    {this.state.selectedCoinType == value.coinType ? (
                                        <img src={require('../img/common/minus.png')} />
                                    ) : (
                                        <img src={require('../img/common/more.png')} />
                                    )}
                                </div>
                            </div>
                            
                            {this.state.selectedCoinType == value.coinType && (
                                    <TradeInfo selectedCoinType={this.state.selectedCoinType}/>
                                )}
                            </div>
                        );
                    })}
                </div>

            )
    }
}

const mapStateToProps = state => {
    const { coinList, loginInfo } = state;
    return { coinList, loginInfo };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators({ setCoinList, setCoinBalance, setCoinStaking, setCoinAddress }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyWallet));