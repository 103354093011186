import React, { Component } from 'react';

import Content from "./Content";
import { Spin, Modal } from "antd";
import { reactLocalStorage } from "reactjs-localstorage";
import { Route, Switch, withRouter } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { setCoinList, setCoinBalance, setCoinStaking, setCoinAddress, setCoinFee, setCoinCurrency, setCoinServerWallet, resetCoinList, login, logout } from '../actions';
import { connect } from "react-redux";
import Const from "../const";
import Language from '../lib/util/language';
import { httpGet, httpUrl, httpPost } from "../api/httpClient";
import { withAlert } from 'react-alert'


class Main extends Component {
  constructor(props) {
    super(props);
    this.string = Language.getString();
    this.state = {
      activeTab: 1,
      visible: false
    }
  }
  componentDidMount() {
    const userInfo = reactLocalStorage.getObject(Const.appCode + "#userinfo");
    if (userInfo && userInfo.idx) this.props.login(userInfo);
    const coinlist = reactLocalStorage.getObject(Const.appCode + "#coinlist");
    if (coinlist instanceof Array) this.props.setCoinList(coinlist);
    const coincurrency = reactLocalStorage.getObject(Const.appCode + "#coincurrency");
    if (coincurrency instanceof Array) this.props.setCoinCurrency(coincurrency);
    const coinfee = reactLocalStorage.getObject(Const.appCode + "#coinfee");
    if (coinfee instanceof Array) this.props.setCoinFee(coinfee);
    const coinbalance = reactLocalStorage.getObject(Const.appCode + "#coinbalance");
    if (coinbalance instanceof Array) this.props.setCoinBalance(coinbalance);
    const coinstaking = reactLocalStorage.getObject(Const.appCode + "#coinstaking");
    if (coinstaking instanceof Array) this.props.setCoinStaking(coinstaking);

    // if (coinlist instanceof Array) this.setState({visible: true})
    this.startUp();

    let pathname = this.props.location.pathname.split("/");
    if (pathname[1] == "mywallet") this.setState({ activeTab: 0 });
    if (pathname[1] == "currency") this.setState({ activeTab: 1 });
    if (pathname[1] == "exchange") this.setState({ activeTab: 2 });
    if (pathname[1] == "event") this.setState({ activeTab: 3 });
    

    // if (pathname[1] !== "signup" && pathname[1] !== "password" && pathname[1] !== "privacy") {
    //   if (!userInfo.idx && this.props.location.pathname !== "/") {

    //     this.props.history.push("/");
    //   }
    //   if (userInfo.idx && this.props.location.pathname === "/") {
    //     this.props.history.push("/mainpage");
    //   }
    // }
  }

  startUp = () => {
    this.requestSystemListBlockchain(true).then(() => {
      this.requestCurrency(true).then(() => {
          if (this.allLoaded()) this.gotoWallet();
      }).catch(e => { console.log("## requestCurrency error: " + e) });
      this.requestFee(0, true).then(() => { if (this.allLoaded()) this.gotoWallet(); }).catch(e => { console.log("## requestFee error: " + e) });
      this.requestCurrencyExchangeRate(true).then(() => { if (this.allLoaded()) this.gotoWallet(); }).catch(e => { console.log("## requestCurrencyExchangeRate error: " + e) });
    }).catch(e => { console.log("## requestSystemListBlockchain error: " + e) });
  }

  allLoaded = () => {
    if (this.state.currencyLoaded && this.state.feeLoaded && this.state.exchangeRateLoaded) return true;
    else return false;
  }

  gotoWallet = () => {
    this.setState({visible: true})
  }

  requestSystemListBlockchain = (hide) => {
    console.log("## Loading systemListBlockchain");
    return new Promise((resolve, reject) => {
      httpGet(httpUrl.systemListBlockchain, [global.language], {}, hide).then((result) => {
        result = result.data;
        console.log('## systemListBlockchain loaded');
        // console.log(JSON.stringify(result));

        result.forEach(obj => {
          obj.status = true;
          obj.address = '';
          obj.balance = 0;
          obj.balanceLoaded = false;
          obj.price = 0;
          obj.priceLoaded = false;
          obj.value = 0;
          obj.valueLoaded = false;
          obj.purchaseFee = 0;
          obj.sendInFee = 0;
          obj.sendExFee = 0;
          obj.tradeFee = 0;
          obj.feeLoaded = false;
          obj.serverWallet = '';
          obj.serverWalletLoaded = false;
          obj.change = 0.0;
          obj.market = 0.0;
          obj.trends = [];
          obj.stakeBalance = 0;
          obj.stakeLockAmount = 0;
        })

        this.props.setCoinList(result);
        reactLocalStorage.setObject(Const.appCode + "#coinlist", result);
        reactLocalStorage.setObject("coinList", result);
        resolve();

      }).catch((e) => {
        console.log('## systemListBlockchain load error: ' + e)
        reject(e);
      });
    })
  }

  requestCurrency = (hide) => {
    console.log("## Request currency");
    return new Promise((resolve, reject) => {
      httpGet(httpUrl.currencies, [], {}, hide).then((result) => {
        this.props.setCoinCurrency(result.data);
        reactLocalStorage.setObject(Const.appCode + "#coincurrency", result.data);
        this.state.coinCurrencyUpdateTime = new Date().toLocaleTimeString();
        this.state.currencyLoaded = true;
        resolve();

      }).catch(e => {
        console.log("## requestCurrency error: " + e);
        reject(e);
      });
    })
  }

  requestFee = (coinType, hide) => {
    console.log("##### Request fee")
    return new Promise((resolve, reject) => {
      httpGet(httpUrl.coinFee, [coinType], {}, hide).then((result) => {
        this.props.setCoinFee(result.data);
        reactLocalStorage.setObject(Const.appCode + "#coinfee", result.data);
        this.state.feeLoaded = true;
        resolve();

      }).catch(e => {
        console.log("## requestFee error: " + e);
        reject(e);
      });
    });
  }

  requestBalance = (coinType, hide) => {
    console.log("##### Request balance")
    return new Promise((resolve, reject) => {
      httpGet(httpUrl.balance, [0], {}, hide).then((result) => {
        this.props.setCoinBalance(result.data);
        reactLocalStorage.setObject(Const.appCode + "#coinbalance", result.data);
        this.state.balanceLoaded = true;

        httpGet(httpUrl.getStakingBalance, [], {}, hide).then((result) => {
          console.log('getStakingBalance=' + JSON.stringify(result))
          this.props.setCoinStaking(result.data.stakingBalances);
          reactLocalStorage.setObject(Const.appCode + "#coinstaking", result.data.stakingBalances);
          resolve();

        }).catch(e => {
          console.log("## getStakingBalance error: " + e)
          reject();
        });
      }).catch(e => {
        console.log("## requestBalance error: " + e)
        reject();
      });
    })
  }

  requestCurrencyExchangeRate = (hide) => {
    return new Promise((resolve, reject) => {
      this.state.exchangeRateLoaded = true;
      global.exchangeRate = [1, 1, 1, 1];
      resolve();
    })
  }
  logout = () => {
      const string = this.string;
      const { logout, history } = this.props;
      Modal.confirm({
          title: string.myinfoLogout,
          content: string.myinfoLogoutConfirm,
          okText: string.ok,
          cancelText: string.cancel,
          onOk() {
              logout();
              reactLocalStorage.remove(Const.appCode + "#userinfo");
              reactLocalStorage.remove(Const.appCode + "#coinbalance");
              reactLocalStorage.remove(Const.appCode + "#coinstaking");
              
              history.push('/login');
          },
          onCancel() {
          },
        });
  }
  render() {
    const string = this.string;
    return (
      <>      
        <div className="wrapperMain">
          <div className="mainContent">
            <div className="BT-HD-right-container">
              <div className="mainLogo"></div>
              {this.props.loginInfo.idx 
                ? <div className="logout" onClick={this.logout}>
                  <img src={require('../img/main/logOut.png')} alt="" />
                </div>
                : <div className="login" onClick={()=>this.props.history.push('/login')}>
                  <img src={require('../img/main/login.png')} alt="" />
                </div>
              }                     
              </div>
            <div className="container" style={{ textAlign: 'center', paddingBottom: '30px' }}>
              <ul className="tab">
                <li className={this.state.activeTab == 0 ? 'selBox' : 'nonSelBox'} onClick={() => {
                  if (!this.props.loginInfo.idx) {
                    this.props.alert.show(this.string.needLogin2);
                    return;
                  }
                  this.props.history.push('/mywallet');
                  this.setState({ activeTab: 0 });
                }}>{this.string.menuMywallet}</li>
                <li className={this.state.activeTab == 1 ? 'selBox' : 'nonSelBox'} onClick={() => {
                  this.props.history.push('/currency');
                  this.setState({ activeTab: 1 });
                }}>{this.string.menuCurrency}</li>
                <li className={this.state.activeTab == 2 ? 'selBox' : 'nonSelBox'} onClick={() => {
                  this.props.history.push('/exchange');
                  this.setState({ activeTab: 2 });
                }}>{this.string.menuExchange}</li>
                <li className={this.state.activeTab == 3 ? 'selBox' : 'nonSelBox'} onClick={() => {
                  this.props.history.push('/event');
                  this.setState({ activeTab: 3 });
                }}>{this.string.menuEvent}</li>
              </ul>
                {this.state.visible && (<Content />)}
              
            </div>
          </div>
        </div>
      </>
    );
  }
}



const mapStateToProps = (state) => {
  const { coinList, loginInfo } = state
  return { coinList, loginInfo }
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({ setCoinList, setCoinBalance, setCoinStaking, setCoinAddress, setCoinFee, setCoinCurrency, setCoinServerWallet, resetCoinList, login, logout }, dispatch)
);

export default withAlert()(withRouter(connect(mapStateToProps, mapDispatchToProps)(Main)));