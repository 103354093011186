import React, { useState } from 'react';
import { Form, Input, Checkbox, Spin, Modal, Select, Button } from 'antd';
import Language from '../lib/util/language';
import { httpGet, httpUrl, httpPost } from "../api/httpClient";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

class FindIdPw extends React.Component {
    string = Language.getString();
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            type: 0,
            name : "",
            phone : "",
            id: "",
            result : false,
            userId:"",
        }
    }
    handleSubmit = () => {
    if (this.state.type === 1 && (this.state.id === null | this.state.id === "")) {
        alert(this.string.inputEmail2)
        return;
    } 
    this.state.type === 0 ? (
        httpPost(httpUrl.userFindId, [], {
            name : this.state.name,
            phone : this.state.phone
        }).then((res)=>{
            console.log(res);
            if (!res.data) {
                Modal.error({
                    title: this.string.findId,
                    content: this.string.mnemonicError,
                });          
            } else {
                let str = res.data.id
                let strLength = str.toString().split('@')[0].length - 5;
                let isId = str.toString().replace(new RegExp('.(?=.{0,' + strLength + '}@)', 'g'), '*').replace(/.{5}$/, "******");             
                this.setState({     
                    result: true,          
                    userId: isId,
                })               
            }
        })
    ) : (
            httpPost(httpUrl.userFindPassword, [this.state.id], {}).then((res)=>{
                console.log(res);
                if (!res.data) {
                    Modal.error({
                        title: this.string.findId,
                        content: this.string.mnemonicError,
                    });          
                } else {
                    this.setState({
                        result: true,
                        userId: res,
                    })
                }
            })
        )
    }   
    onChangeInput = (e) => {
        const { value, id } = e;
        this.setState({ [id] : value })
    }
    closeModal = () => {
        this.setState({ result: false })
      }

    render() {
        const string = this.string;
        
        return(
            <div className="wrapper">
                <div className="logForm">        
                    <div className="logo" onClick={()=>this.props.history.push('/currency')}>
                        <img src={require('../img/loginPage/logo.png')} />                        
                    </div>
                    <div className="form">                                 
                        <div className='title-wrap2'>
                            <span 
                                className='loginTitle' 
                                onClick={()=>{
                                    this.props.history.push('/login');
                            }}>{string.myinfoLogin}</span>
                            <span className='logTitle'>{string.findIdPassword}</span>
                        </div> 
                        <div className='bar'/>   
                        <div className='findTypeBtn-container'>
                            <Button 
                                className={this.state.type == 0 && 'activeType'}
                                onClick={()=>this.setState({type:0, id:"", email:"",result:false})}>
                                    {string.findId}
                            </Button>
                            <Button 
                                className={this.state.type == 1 && 'activeType'}
                                onClick={()=>this.setState({type:1, id:"", email:"",result:false})}>
                                    {string.findPassword}
                            </Button>
                        </div> 

                        <Form onFinish={this.handleSubmit} ref={this.formRef}>
                        { this.state.type === 0 ? (  
                            <div className="signUpContent">                                                             
                            {this.state.result ? (
                            <div className='signUpContent finish'>                               
                                <p className='finishText1'>{string.findIdSucess}</p>   
                                <p className='finishText1'> ID : {this.state.userId}</p>                     
                             </div>
                            ):(
                                <>
                                <div className='formItem-box'>
                                    <p>{string.name} </p>                                
                                    <Form.Item name="name">                                       
                                        <Input
                                            className='signUp-input'
                                            placeholder={string.nameFail}           
                                            value={this.state.name}                                         
                                            onChange={(e)=>this.onChangeInput(e.target)}                                                                                                              
                                        />                                                           
                                    </Form.Item>                                   
                                </div>   
                                <div className='formItem-box'>
                                    <p>{string.phone} </p>                                
                                    <Form.Item name="phone">                                       
                                        <Input
                                            className='signUp-input'
                                            type='number'
                                            pattern="\d*"
                                            placeholder={string.phoneFail}           
                                            value={this.state.phone}                                         
                                            onChange={(e)=>this.onChangeInput(e.target)}                                                                                                              
                                        />                                                           
                                    </Form.Item>                                   
                                </div>  
                                </>
                            )}     
                            </div>              
                        ):(
                            <div className="signUpContent">
                                 {this.state.result ? (
                                    <div className='signUpContent finish'>
                                        <p className='finishText1'>{string.findPasswordEmailSent}</p>
                                    </div>
                                 ):(
                                <>
                                <div className='formItem-box'>
                                    <p>{string.id} </p>                                
                                    <Form.Item name="id">                                       
                                        <Input
                                            className='signUp-input'
                                            placeholder={string.idLengthFail}           
                                            value={this.state.id}                                         
                                            onChange={(e)=>this.onChangeInput(e.target)}                                                                                                              
                                        />                                                           
                                    </Form.Item>                                   
                                </div>       
                                <p className='findIdPw-sub'>{string.findPasswordDescription}</p> 
                                </>
                                )}

                            </div>
                        )}
                          {this.state.result ? (
                            <Link to='/login' className='box-container'>        
                                <button className="findBtn" htmltype="submit">
                                    {string.login}
                                </button>       
                            </Link>             
                          ):(
                            <div className='box-container'>        
                            <button className="findBtn" htmltype="submit">
                                {this.state.type === 0 ? string.findId : string.changePassword}
                            </button>       
                        </div>
                          )}     
                        </Form>    

                        
                    </div>
                </div>
            </div>
        )
    }
}  

export default withRouter(connect(null, null)(FindIdPw));  