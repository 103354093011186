
const formatDateNumber = num => {
  num += "";
  return num.length < 2 ? "0" + num : num;
};
const formatDateDay = date => {
  // if (date === null) return '-';
  if (!date) return null;
  let newDate = new Date((date+'').substring(0,19));
  return (
    newDate.getFullYear() +
    "-" +
    formatDateNumber(newDate.getMonth() + 1) +
    "-" +
    formatDateNumber(newDate.getDate())
  );
  // let day = date.slice(0, 10);
  // let second = date.slice(11, 16);
  // return day + " " + second;
};

const formatDate = date => {
  // if (date === null) return '-';
  if (!date) return null;
  let newDate = new Date((date+'').substring(0,19));
  return (
    newDate.getFullYear() +
    "-" +
    formatDateNumber(newDate.getMonth() + 1) +
    "-" +
    formatDateNumber(newDate.getDate()) +
    " " +
    formatDateNumber(newDate.getHours()) +
    ":" +
    formatDateNumber(newDate.getMinutes())
  );
  // let day = date.slice(0, 10);
  // let second = date.slice(11, 16);
  // return day + " " + second;
};

const formatDateSecond = date => {
  // if (date === null) return '-';
  if (!date) return null;
  let newDate = new Date((date+'').substring(0,19));
  // let day = date.slice(0, 10);
  // let second = date.slice(11, 16);
  // let result2 = day + " " + second;
  let result =
    newDate.getFullYear() +
    "-" +
    formatDateNumber(newDate.getMonth() + 1) +
    "-" +
    formatDateNumber(newDate.getDate()) +
    " " +
    formatDateNumber(newDate.getHours()) +
    ":" +
    formatDateNumber(newDate.getMinutes()) +
    ":" +
    formatDateNumber(newDate.getSeconds());
  // console.log(`prevResult: ${result} current:${result2}`);
  return result;
};
const formatDateHM = date => {
  // if (date === null) return '-';
  if (!date) return null;
  let newDate = new Date((date+'').substring(0,19));
  // let day = date.slice(0, 10);
  // let second = date.slice(11, 16);
  // let result2 = day + " " + second;
  let result =
    formatDateNumber(newDate.getHours()) +
    ":" +
    formatDateNumber(newDate.getMinutes())
  // console.log(`prevResult: ${result} current:${result2}`);
  return result;
};
export {
  formatDateDay,
  formatDate,
  formatDateSecond,
  formatDateHM,
};