export default {

  appTitle: 'OTC마켓',
  /*********메뉴**********/
  menuCurrency: '시세',
  menuMywallet: '내지갑',
  menuEvent: '이벤트',
  menuTrade: '거래',
  menuTradeSend: '보내기',
  menuTradeInfo: '거래내역',
  menuExchange: '거래',
  menuPayment: '결제',
  menuBuyback: '바이백',
  menuDApp: 'DApp',
  menuMyInfo: '내정보',
  menuExchangeInfo: '거래내역',

  /*********가입/로그인**********/
  login: '지갑 들어가기',
  autoLogin: '자동 로그인',
  saveLoginId: '아이디 저장',
  findId: '아이디 찾기',
  findPassword: '비밀번호 찾기',
  findIdPassword: 'ID/PW 찾기',
  signUp: '회원가입',
  phone: '휴대폰 번호',
  inputPhone: '전화번호 입력',
  certiCode: '인증번호',
  sendCertiCode: '인증번호 발송',
  checkCertiCode: '인증번호 확인',
  inputCertiCode: '인증번호 입력',
  inputRecommendCode: '추천인코드 입력 (선택)',
  // inputRecommendCodeComment: '추천인을 입력하시면 200 UUTT 에어드랍',

  mnemonicTitle: '니모닉 코드를 얻는 것은 지갑 자산 소유권을 갖는 것과 같습니다.',
  mnemonicContent1: '만약 당신의 휴대폰, 아이디, 비밀번호가 분실된 경우, 니모닉 코드는 당신의 지갑 자산을 복구할 수 있습니다.',
  mnemonicContent2: '종이와 펜을 사용하여 니모닉 코드 받아 적습니다.',
  mnemonicContent3: '인전한 곳에 잘 보관합니다.',
  mnemonicContent4: '메일, 사진 앨범, SNS등 온라인 환경에서 니모닉 코드를 저장 및 공유하지 마세요',
  readAgreement: '위에 사항을 잘 읽었습니다.',
  readViewAgreement: '내용을 확인했으면 확인체크 부탁드립니다.',

  signUpErrorTitle: '지갑 생성 실패',
  signUpErrorIdDuplication: '이미 등록된 이메일입니다. 이메일을 확인해 주시기 바랍니다.',
  signUpErrorInvalidEmailType: '이메일 형식이 잘못되었습니다. 다시 확인해 주시기 바랍니다.',
  signUpErrorMaxPhone: '전화번호당 등록가능한 계정 수를 초과하였습니다.',
  signUpErrorMessage: '지갑 생성에 실패하였습니다. 잠시 후 다시 시도해 주시기 바랍니다.',
  mnemonicDescription: '니모닉 코드를 얻는 것은 지갑 자산 소유권을 가지는 것과 같습니다.',
  mnemonicDescriptionDetail: [
    '아이디, 비밀번호가 분실된 경우 니모닉 코드를 이용하여 지갑 자산을 복구할 수 있습니다.',
    '종이와 펜을 사용하여 니모닉 코드를 작성하여 주시기 바랍니다.',
    '니모닉 코드는 안전한 곳에 보관하여 주시기 바랍니다.',
    '메일, 사진앨범, SNS 등 온라인 환경에 니모닉을 저장 및 공유하지 마시기 바랍니다.'
  ],
  iAgree: '약관에 동의합니다.',
  captureProhibited: '캡쳐 금지',
  captureProhibitedDescription: [
    '캡쳐하여 공유 및 저장하지 마시기 바랍니다.',
    '제3자의 악의적인 접근에 의해 자산 손실을 초래할 수 있습니다.'
  ],
  welcomeToWallet: 'OTC마켓에 오신 것을 환영합니다.',
  getStarted: '바로 시작하기',
  findPasswordEmailSent: '비밀번호 변경을 위한 이메일이 발송되었습니다. 이메일에서 링크를 클릭하여 비밀번호를 변경해 주시기 바랍니다.',


  /*********MNEMONIC**********/
  mnemonicGuideFindIdPw: [
    // '지갑 생성 시 발급된 복구단어를 차례대로 입력해 주시기 바랍니다.',
    '가입 시 등록한 휴대폰 번호로 인증을 진행해 주세요.',
    '아이디 찾기가 완료되었습니다. 비밀번호를 변경이 필요한 경우 아래에 비밀번호를 입력해 주시기 바랍니다.',
    '비밀번호 변경이 완료되었습니다. 로그인을 진행해 주시기 바랍니다.',
  ],
  mnemonicGuideFindSecPw: [
    // '지갑 생성 시 발급된 복구단어를 차례대로 입력해 주시기 바랍니다.',
    '가입 시 등록한 휴대폰 번호로 인증을 진행해 주세요.',
    '새로운 보안 비밀번호를 입력해 주시기 바랍니다.',
    '보안 비밀번호 변경이 완료되었습니다.',
  ],
  mnemonicGuideChangePw: [
    '현재 비밀번호와 새로운 비밀번호를 입력해 주시기 바랍니다.',
    '비밀번호 변경이 완료되었습니다.',
  ],
  mnemonicGuideChangeSecPw: [
    '현재 보안 비밀번호 4자리와 새로운 보안 비밀번호 4자리를 입력해 주시기 바랍니다.',
    '보안 비밀번호 변경이 완료되었습니다.',
  ],
  mnemonicGuideChangeOtp: [
    // '지갑 생성 시 발급된 복구단어를 차례대로 입력해 주시기 바랍니다.',
    '가입 시 등록한 휴대폰 번호로 인증을 진행해 주세요.',
  ],
  mnemonicError: '아이디 찾기에 실패하였습니다.',
  mnemonicErrorWrongSms: '문자인증에 실패하였습니다.',
  mnemonicErrorWrongUser: '해당하는 사용자가 없습니다.',
  mnemonicErrorWrongMnemonic: '인증정보가 잘못되었습니다.',
  mnemonicErrorFailFindSecPassword: '보안 비밀번호 찾기에 실패하였습니다.',
  changeOtpFailed: 'OTP 변경에 실패하였습니다.',

  /*********코인**********/
  coinBtc: { symbol: 'BTC', name: '비트코인' },
  coinEth: { symbol: 'ETH', name: '이더리움' },
  coinXrp: { symbol: 'XRP', name: '리플' },
  coinBch: { symbol: 'BCH', name: '비트코인캐시' },
  coinLtc: { symbol: 'LTC', name: '라이트코인' },
  coinEos: { symbol: 'EOS', name: '이오스' },
  coinTrx: { symbol: 'TRX', name: '트론' },
  coinVet: { symbol: 'VET', name: '비체인' },
  coinOmg: { symbol: 'OMG', name: '오미세고' },
  coinIcx: { symbol: 'ICX', name: '아이콘' },
  coinBnb: { symbol: 'BNB', name: '비앤비' },
  coinZil: { symbol: 'ZIL', name: '질리카' },
  coinZrx: { symbol: 'ZRX', name: '제로엑스' },
  coinAe: { symbol: 'AE', name: '애터니티' },
  coinRep: { symbol: 'REP', name: '어거' },
  coinGnt: { symbol: 'GNT', name: '골렘' },
  coinSnt: { symbol: 'SNT', name: '스테이터스네트워크토큰' },
  coinMco: { symbol: 'MCO', name: '모나코' },
  coinLoom: { symbol: 'LOOM', name: '룸네트워크' },
  coinKnc: { symbol: 'KNC', name: '카이버네트워크' },
  coinXtz: { symbol: 'XTZ', name: '테조스' },
  coinEthos: { symbol: 'ETHOS', name: '에쏘스' },
  coinPowr: { symbol: 'POWR', name: '파워렛저' },
  coinStorm: { symbol: 'STORM', name: '스톰' },
  coinPay: { symbol: 'PAY', name: '텐엑스페이토큰' },
  coinSalt: { symbol: 'SALT', name: '솔트' },
  coinCvc: { symbol: 'CVC', name: '시빅' },
  coinNpxs: { symbol: 'NPXS', name: '펀디엑스' },
  coinBat: { symbol: 'BAT', name: '베이직어텐션토큰' },
  coinHt: { symbol: 'HT', name: '후오비토큰' },
  coinBlb: { symbol: 'BLB', name: '블랍스' },
  coinCs: { symbol: 'CS', name: '크레딧' },
  coinGbp: { symbol: 'GBP', name: '겜블팡' },
  coinCrc: { symbol: 'CRC', name: '채린' },
  coinXis: { symbol: 'XIS', name: '자이러스' },
  coinFk: { symbol: 'FK', name: '에프케이코인' },
  coinMvl: { symbol: 'MVL', name: '엠블' },
  coinMd: { symbol: 'MD', name: '마다' },
  coinKc: { symbol: 'KC', name: '케나프' },
  coinSc: { symbol: 'SC', name: '썬코인' },
  coinTsn: { symbol: 'TSN', name: '티에스코인' },
  coinUutt: { symbol: 'UUTT', name: '유유티티' },

  /*********공통**********/
  unquoted: '비상장',
  address: '주소',
  destinationTag: 'Destination Tag',
  destinationTagShort: 'Dest. Tag',
  error: '오류',
  errorMessage: '오류가 발생하였습니다. 잠시 후 다시 시도해 주세요.',
  coin: '코인',
  myCoin: '보유 코인',
  coinType: '코인 종류',
  kind: '종',
  value: '금액',
  myValue: '보유 잔액',
  totalValue: '현재 총 금액',
  fee: '수수료',
  localCoinUnit: ['원', 'USD', 'CNY', 'JPY'],
  localCoin: '원',
  localCoinBig: '조',
  faster: '빠름',
  normal: '보통',
  prev: '이전',
  next: '다음',
  time: '시간',
  type: '구분',
  target: '대상',
  targetAddress: '보낼주소',
  sendAmount: '보낼수량',
  exchangeAmount: '교환수량',
  send: '보내기',
  sent: '출금',
  sending: '출금중',
  sendingAddress: '출금주소',
  receive: '받기',
  received: '입금',
  receiving: '입금중',
  receivedAddress: '입금주소',
  save: '저장',
  name: '이름',
  setting: '설정',
  security: '보안 센터',
  intro: '회사소개',
  cs: '고객센터',
  submit: '제출',

  failed: '실패',
  unuse: '미사용',
  use: '사용',
  ok: '확인',
  cancel: '취소',
  close: '닫기',
  back: '뒤로',
  copy: '주소복사',
  copy2: '복사',
  share: '공유',
  enterPrice: '금액입력',
  enterPriceTitle: '수량 입력하기',
  max: '최대',
  reset: '리셋',
  updated: '갱신',
  default: '기본',
  paste: '붙여넣기',
  captureQr: 'QR촬영',
  captureQrTitle: 'QR코드 촬영하기',
  captureQrDetail: 'QR코드를 촬영하여 코인을 보낼 대상 주소를 설정할 수 있습니다. QR코드가 정확히 촬영될 수 있도록 카메라를 움직여주시기 바랍니다. QR코드가 인식이 되면 촬영은 자동으로 종료됩니다.',
  serverFail: '요청을 실패하였습니다. 잠시 후 다시 시도해 주세요.',
  extraCount: '외 %s건',
  email: '이메일 확인',
  email2: '이메일',
  tradeCoin: '코인선택',
  needLogin: '로그인이 필요한 화면입니다.',
  find: '찾기',
  walletAddress: '지갑주소',
  start: '바로 시작하기',
  inputOption: '선택사항',
  memo: '메모',
  otp: 'OTP',
  detail: '상세정보',
  completed: '완료',
  oneMoreBackToExit: '한번 더 누르면 종료됩니다.',
  txId: 'Tx ID',

  /*********국가**********/
  country: '국가',
  korea: '대한민국',
  korean: '한국어',
  krw1: '원',
  krw2: '원화',
  china: '중국',
  // chinese: '중국어',
  chinese: '中文',
  cny1: '위안',
  cny2: '위안화',
  japan: '일본',
  // japanese: '일본어',
  japanese: '日本語',
  jpy1: '엔',
  jpy2: '엔화',
  usa: '미국',
  // english: '영어',
  english: 'English',
  usd1: '달러',
  usd2: '달러화',
  eur1: '유로',
  eur2: '유로화',
  setLanguage: '언어 설정',
  setCurrency: '통화 설정',

  /*********패스워드**********/
  password: '비밀번호', //'지갑 비밀번호 확인',
  passwordConfirm: '비밀번호 확인',
  inputPassword: '지갑 비밀번호',
  inputPasswordConfirm: '지갑 비밀번호 확인',
  inputPasswordDetail: '소지하고 계신 지갑의 비밀번호를 입력해 주세요.',
  inputPasswordRule: '영문자, 숫자, 특수문자를 조합하여 8 ~ 15자리 입력',
  inputCurrentPassword: '현재 지갑 비밀번호 입력',
  inputNewPassword: '새로운 지갑 비밀번호 입력',
  inputNewPasswordConfirm: '새로운 지갑 비밀번호 확인',
  inputCurrentSecPassword: '현재 보안 비밀번호 입력 (4자리 숫자)',
  inputNewSecPassword: '새로운 보안 비밀번호 입력 (4자리 숫자)',
  inputNewSecPasswordConfirm: '새로운 보안 비밀번호 확인 (4자리 숫자)',
  inputFingerDetail: '스마트폰에 등록된 지문을 인증해주세요.',
  secPassword: '보안 비밀번호',
  shortTermSecPassword: '보안비번',
  inputSecPassword: '보안 비밀번호 입력 (4자리 숫자)',
  secPasswordConfirm: '보안 비밀번호 확인',
  inputSecPasswordConfirm: '보안 비밀번호 확인 (4자리 숫자)',
  inputOtpCode: 'OTP 입력',
  loginFailedTitle: '로그인 실패',
  loginFailedWrongId: '잘못된 아이디입니다.',
  loginFailedWrongPassword: '잘못된 비밀번호 입니다.',
  loginFailedExpiredId: '사용이 중지된 아이디입니다.',
  loginFailedEmailConfirm: '이메일 승인이 되지 않았습니다. 이메일 승인 후 이용해 주시기 바랍니다.',
  loginFailedMessage: '로그인에 실패하였습니다. 잠시 후 다시 시도해 주시기 바랍니다.',
  fingerCheckExplain1: '지문 사용 등록하기',
  fingerCheckExplain2: '(다음부터 지문으로 로그인합니다.)',
  usefingerAuth: '지문인증 사용',
  fingerAuth: '지문인증',
  fingerRequire: '지문을 인식해 주세요',
  fingerFail: '지문 인증에 실패하였습니다.',
  fingerNoData: '인증정보가 없습니다. 다시 로그인 해 주세요',
  setupOtp: 'OTP 설정',
  changePassword: '비밀번호 변경',
  changeSecPassword: '보안 비밀번호 변경',
  findSecPassword: '보안 비밀번호 찾기',
  setupOtpDetail: '아래의 키를 사용하여 Google OTP를 설정해 주시기 바랍니다. 코인 송금 등 중요 기능 실행 시 OTP 인증이 필요합니다. 확인을 누르시면 OTP 키가 복사됩니다.',
  otpCopied: 'OTP 키가 복사되었습니다.',
  copyOtpKey: 'OTP키 복사하기',
  otpAlreadySet: 'OTP가 이미 설정되어있습니다.',
  invalidOtp: 'OTP 정보가 잘못되었습니다.',
  changeOtp: 'OTP 변경',
  changePasswordFailed: '비밀번호 변경에 실패하였습니다.',
  changePasswordFailedSamePassword: '기존 비밀번호와 새 비밀번호가 동일합니다.',
  changePasswordFailedInvalidPassword: '비밀번호가 틀렸습니다.',
  changeSecPasswordFailed: '보안 비밀번호 변경에 실패하였습니다.',
  changeSecPasswordFailedSamePassword: '기존 보안 비밀번호와 새 보안 비밀번호가 동일합니다.',
  changeSecPasswordFailedInvalidPassword: '보안 비밀번호가 틀렸습니다.',
  otpSetupDescription: 'OTP는 지갑 출금 시 보안을 강화하는 6자리 숫자키입니다.',
  downloadOtp1: '앱 스토어에서 Google OTP를 다운로드해 주세요.',
  downloadOtp2: '앱 스토어에서 Authy를 다운로드해 주세요.',
  downloadOtp3: '앱 스토어에서 Google OTP 혹은 Authy를 다운로드해 주세요.',
  startOtpSetup: '인증 시작',
  inputOtpCodeDescription: '인증코드를 입력해 주세요',
  submit: '제출',
  otpSetupFinished: 'OTP 설정이 완료되었습니다.',
  otpSetupFinishedDescription: '연동된 OTP 변경 후 72시간 동안은 출금이 금지됩니다.',
  otpSetupFinishedDescription1: '연동된 OTP 변경 후 ',
  otpSetupFinishedDescription2: '72시간 ',
  otpSetupFinishedDescription3: '동안은 출금이 금지됩니다.',

  /*********태깅**********/
  requestTagging: '',
  requestTaggingDetail: '',
  nfcOffMessage1: '',
  nfcOffMessage2: '',
  nfcNotSupported: '',

  /*********공지**********/
  // noticeCategory: ['', '공지', '이벤트', '뉴스'],
  noticeCategory: { NOTE: '공지', EVNT: '이벤트', NEWS: '뉴스' },
  eventCategory: { NOTE: '공지', EVNT: '이벤트', NEWS: '뉴스' },  

  /*********시세**********/
  changePercent: '등락',
  totalAmountValue: '시총',
  criteriaCoinmarketcap: '(코인마켓캡 기준)',
  whitePaper: '백서',
  website: '웹사이트',
  community: '커뮤니티',
  country: '국가',
  preparing: '내용을 준비 중입니다.',

  /*********내지갑**********/
  mywalletNoCoins: '보유하고 있는 코인이 없습니다.',
  mywalletRemoveCoin: '선택한 코인을 미사용으로 변경하시겠습니까?',
  mywalletAddressCopied: '주소가 복사되었습니다.',
  mywalletReceive: '아래 주소로 코인을 받으실 수 있습니다.주소를 복사하거나 QR코드를 촬영해 주세요',
  mywalletBlbSend: '보내기',
  mywalletBlbSendDetail: '블랍스 코인은 현재 출금이 불가능합니다.코인을 사용할 수 있는 서비스를 준비하고 있습니다. 자세한 내용은 향후 공지사항을 통해 확인하실 수 있습니다.',
  mywalletPossesionCoinCheck: '보유코인만 보기',
  mywalletCausion: '이 주소의 코인과 동일한 코인만 입금이 가능합니다. 다른 코인을 이 주소로 잘못 보내는 경우 코인을 찾을 수 없습니다.',
  mywalletCausionBtc: '비트코인(BTC),비트코인캐시(BCH),비트코인골드(BCG) 입출금시에는 각각 다른 암호화폐이므로 늘 유의해 주시길 바랍니다.',
  mywalletCausionEth: '이더리움(ETH),이더리움클래식(ETC) 입출금시에는 각각 다른 암호화폐이므로 늘 유의해 주시길 바랍니다.',
  mywalletCausionXrp: '리플은 코인 특성상 지갑에 20 XRP 이상을 보유한 경우에만 출금이 가능하며 항상 잔액을 20 XRP 이상으로 유지해야 합니다.',
  transactionInfo: '거래내역',
  inputAddress: '주소 입력',
  inputAmount: '수량 입력',
  depositCompleted: '입금완료',
  outerWallet: '외부지갑',
  memoOption: '메모(선택)',
  qrNoCoin: 'QR에 코인정보가 포함되어 있지 않습니다.',

  /*********거래**********/
  selectCoin: '코인을 선택해 주세요.',
  sendCoinInputAmount: '금액을 입력해주세요.',
  sendCoinInputAmountLow: '코인의 최소 출금 수량은 0.000001 입니다.',
  sendCoinInputFee: '수수료를 입력해주세요.',
  sendCoinInputAddress: '주소를 입력해주세요.',
  sendCoinInputAddress2: '주소(유효주소를 입력하시오.)',
  sendCoinLowBalance: '잔액이 부족합니다.',
  // sendCoinLowBalanceXrp: '(최소 20XRP 보유 필요)',
  sendCoinLowBalanceXrp: '',
  sendCoinLowFee: '수수료가 부족합니다.',
  sendCoinInputError: '송금정보 생성에 실패하였습니다.',
  sendCoinSuccess: '송금을 완료하였습니다.',
  sendCoinNeedConfirm: '유의사항을 확인후 체크해 주세요.',
  sendCoinConfirmDlgTtl: '보내기',
  sendCoinConfirmMessage: '아래와 같이 코인을 송금합니다. 한 번 송금을 하시면 취소가 불가능합니다. 송금하시겠습니까?',
  sendCoinWarnMessage: '요청한 이후 취소가 불가능합니다!',
  
  sendCoinCautionConfirmDescriptionEx: [
    '금액과 주소가 올바른지 다시 확인해주세요.',
    '코인 금액은 소수점 6자리를 기본으로 관리하며',
    '상황에 따라 0. 이하 금액은 오차가 발생할 수 있습니다.',
    '블록체인의 특성상 보내기를 한 후 취소할 수 없습니다.',
  ],

  sendCoinCautionConfirm: '송금 유의사항 확인',
  sendCoinCautionConfirm2: '유의사항에 동의합니다.',
  sendCoinCautionFee: '블록체인 전송을 위해 약 0.01 ETH 상당의 수수료가 발생합니다.',
  sendCoinCautionConfirmBtc: '비트코인(BTC),비트코인캐시(BCH),비트코인골드(BCG) 입출금시에는 각각 다른 암호화폐이므로 늘 유의해 주시길 바랍니다.',
  sendCoinCautionConfirmEth: '이더리움(ETH),이더리움클래식(ETC) 입출금시에는 각각 다른 암호화폐이므로 늘 유의해 주시길 바랍니다.',
  sendCoinCautionConfirmXrp: '리플(XRP)은 지갑에 반드시 20XRP이상을 보유해야합니다. 따라서 출금 시 보유 잔액 중 20XRP를 제한 나머지 금액만 출금할 수 있습니다.',
  sendCoinCautionConfirmErc20: '이더리움 토큰 전송 시 사용되는 이더 수수료는 네트워크 상황에 따라 설정한 값보다 많거나 적을 수 있습니다. 따라서 출금 시 원하는 수수료의 3배 만큼의 이더리움을 보유하고 있어야 합니다.',
  sendCoinCautionConfirmDescription: [
    '코인 금액을 소수점 6자리를 기본으로 관리하며 상황에 따라 0.0001 이하 금액은 오차가 발생할 수 있습니다.',
    '보내기 후 전송이 완료되기까지 1시간 이상의 시간이 소요될 수 있으며, 전송 상태는 거래내역에서 확인하실 수 있습니다.',
    '다른 거래소 지갑으로 입출금 하는 경우에는 해당 거래소 정책의 영향을 받을 수 있습니다.',
    '암호화폐의 특성상 보내기를 한 후 취소할 수 없습니다.',
    '보내기 전 주소를 꼭 확인해 주세요.',
    '이 코인과 동일한 코인의 주소로만 보내기가 가능합니다. 다른 코인의 주소로 잘못 보내는 경우 코인을 찾을 수 없습니다.',
    '블록체인의 과부하로 인한 입출금 지연 문제는 OTC마켓에서 처리가 불가능합니다.',
  ],
  sendCoinXrpUseTag: '태그사용',
  sendCoinFailedTitle: '코인 전송 실패',
  sendCoinFailed: '코인 전송에 실패하였습니다. 잠시 후 다시 시도해 주시기 바랍니다.',
  sendCoinFailedInvalidAddress: '보내시는 주소가 잘못되었습니다.',
  sendCoinFailedInsufficientBalance: '잔액이 부족합니다.',
  sendCoinFailedLocked: '코인에 LOCK 이 걸려있습니다.',
  sendCoinFailedInvalidOtp: 'OTP 정보가 잘못되었습니다.',
  sendCoinFailedInvalidSecPassword: '보안 비밀번호가 잘못되었습니다.',
  sendCoinFailedNoSecPassword: '보안 비밀번호 4자리를 입력해 주시기 바랍니다.',
  sendCoinFailedInvalidDesTag: '존재하지 않는 Destination Tag 입니다.',
  sendCoinFailedOtpRequired: 'OTP 없이 보낼 수 있는 일 한도 금액을 초과하였습니다.',
  sendCoinFailedLimitExceed: '일 한도 금액을 초과하였습니다.',
  sendCoinSuccessTitle: '코인 전송 요청 완료',
  includeAmount: '금액 반영',
  includedAmount: 'QR에 포함된 입금금액',
  includeAmountCaution: '금액이 반영된 QR코드는 타지갑에서는 호환되지 않습니다.',
  inputDestinationTag: 'Destination Tag 입력 (선택)',

  /*********거래내역**********/
  noCoins: '거래 정보가 없습니다.',

  /*********결제**********/
  makePayment: '결제하기',
  paymentCautionConfirm: '결제 유의사항 확인',
  paymentCautionConfirmDescription: [
    '코인 금액을 소수점 6자리를 기본으로 관리하며 상황에 따라 0.0001 이하 금액은 오차가 발생할 수 있습니다.',
    '결제 후 전송이 완료되기까지 1시간 이상의 시간이 소요될 수 있으며, 전송 상태는 거래내역에서 확인하실 수 있습니다.',
    '암호화폐의 특성상 결제를 한 후 취소할 수 없습니다.',
    '결제 전 주소와 금액을 꼭 확인해 주세요.',
    '블록체인의 과부하로 인한 입출금 지연 문제는 OTC마켓에서 처리가 불가능합니다.',
  ],
  cancelPayment: '결제를 취소하시겠습니까?',

  /*********교환**********/
  exchangeCoinInputAmount: '수량을 입력해주세요.',
  exchangeCoinLowBalance: '잔액이 부족합니다.',
  exchangeCautionConfirm: '교환 유의사항 확인',
  exchangeCoinLowBalanceXrp: '(최소 20XRP 보유 필요)',
  exchangeCoinNeedConfirm: '유의사항을 확인후 체크해 주세요.',
  exchangeCoinInputError: '교환정보 생성에 실패하였습니다.',
  exchangeCoinSuccess: '교환를 요청하였습니다.',
  exchangeCautionConfirmDescription: [
    '플랫코인과의 교환 비율에 의거하여 코인을 교환합니다.',
    'OTC마켓에서는 코인 금액을 소수점 6자리를 기본으로 관리하며 상황에 따라 0.0001 이하 금액은 오차가 발생할 수 있습니다.',
    '교환이 완료되기까지 1시간 이상의 시간이 소요될 수 있으며, 교환 상태는 거래내역에서 확인하실 수 있습니다.',
    '암호화폐의 특성상 교환을 한 후 취소할 수 없습니다.',
    '블록체인의 과부하로 인한 지연 문제는 OTC마켓에서 처리가 불가능합니다.',
  ],
  exchange: '거래',
  exchangeInfo: '거래내역',
  exchangeSendValue: '판매코인',
  exchangeReceiveValue: '받을코인',
  exchangeCoinConfirmDlgTtl: '거래하기',
  exchangeCoinConfirmMessage: '아래와 같이 코인을 판매등록합니다. 판매완료시에는 거래취소가 불가능합니다. 판매등록 하시겠습니까?',

  /*********주소록**********/
  manageAddressBook: '주소록 관리',
  addressBook: '주소록',
  noRegisteredAddress: '주소록에 등록된 내역이 없습니다.',
  addAddressSuccess: '주소록에 등록되었습니다.',
  deleteAddress: '주소록 삭제',
  deleteAddressDescription: '항목을 주소록에서 삭제하시겠습니까?',
  deleteAddressSuccess: '주소록에서 삭제되었습니다.',
  inputAddressbookMemo: '등록할 이름 입력',
  inputAddressbookAddress: '등록할 주소 입력',
  inputAddressbookDestinationTag: 'Destination Tag 입력 (선택)',
  needInputAddressbookMemo: '등록할 이름을 입력해 주시기 바랍니다.',
  needInputAddressbookAddress: '등록할 주소를 입력해 주시기 바랍니다.',
  registerAddress: '주소 등록',
  registerAddressFailed: '주소 등록에 실패하였습니다.',
  alreadyRegisterdAddress: '이미 등록된 주소입니다.',
  alreadyRegisterdAddress2: '이미 등록된 주소입니다. 등록된 이름을 수정하시겠습니까?',

  /*********초기설정**********/
  setupAreementConfirm: '이용약관 동의',
  setupShowAgreement: '약관보기',
  setupStep: [
    // '1단계',
    // '2단계',
    // '3단계',
    // '4단계',
    // '완료'
  ],
  setupStepTitle: [
    '지갑 초기 설정',
    '기본정보 입력',
    '보안 비밀번호 설정',
    // '복구단어 설정',
    '등록완료'
  ],
  setupStepGuide: [
    // '지갑 생성을 위한 계정 등록, 복구단어 선택 등을 진행합니다. 시작하기 위하여 약관을 확인하고 동의해 주시기 바랍니다.',
    // '이메일, 비밀번호를 입력해 주시기 바랍니다.',
    // '코인 거래 시 사용할 보안 비밀번호 숫자 4자리를 입력해 주시기 바랍니다.',
    // '보안을 위해 5개 이상의 단어를 변경해 주세요. 스마트폰 분실, 앱 삭제 등의 경우 지갑이 분실 될 수 있으니 반드시 메모해 두시기 바랍니다.24번째 단어는 자동 설정 됩니다.',
    // '지갑 생성이 완료되었습니다. 인증 메일이 이메일로 발송되었으니 인증 후 로그인해 주시기 바랍니다.',
  ],
  setupStepRecoverGuide: [
    // '지갑 생성을 위한 계정 등록, 복구단어 선택 등을 진행합니다. 시작하기 위하여 약관을 확인하고 동의해 주시기 바랍니다.',
    // '이메일, 비밀번호를 입력해 주시기 바랍니다.',
    // '코인 거래 시 사용할 보안 비밀번호 숫자 4자리를 입력해 주시기 바랍니다.',
    // '보안을 위해 5개 이상의 단어를 변경해 주세요. 스마트폰 분실, 앱 삭제 등의 경우 지갑이 분실 될 수 있으니 반드시 메모해 두시기 바랍니다.24번째 단어는 자동 설정 됩니다.',
    // '지갑 생성이 완료되었습니다. 인증 메일이 이메일로 발송되었으니 인증 후 로그인해 주시기 바랍니다.',
  ],
  mnemonicSelectionTitle: '보안을 위해 5개 이상의 단어를 변경해 주세요. 스마트폰 분실, 앱 삭제 등의 경우 지갑이 분실 될 수 있으니 반드시 메모해 두시기 바랍니다. 12번째 단어는 자동 설정 됩니다.',
  setupStepRecoverTitle: '복구단어 입력',

  setupCard: '지갑등록',
  setupCardNotSupported: '',

  needAgreement: '약관에 동의해주세요.',
  needViewAgreement: '약관보기를 클릭하여 약관을 확인해주세요.',
  needChangeWord: '5개 이상 단어를 변경해 주세요.',
  needInputWord: '24개 단어를 모두 입력해주세요.',
  needValidWord: '단어가 규칙에 맞지 않습니다.',
  passwordConfirmFail: '입력하신 2개의 비밀번호가 서로 일치하지 않습니다. 다시 한번 확인해주세요.',
  passwordLengthFail: '비밀번호를 입력해 주세요.',
  passwordStrength: '비밀번호는 영문, 숫자, 특수문자를 조합하여 8자~15자로 만들어 주시기 바랍니다.',
  passwordStrength0: '비밀번호를 입력해 주세요.',
  passwordStrength1: '비밀번호가 약합니다.',
  passwordStrength2: '비밀번호가 보통입니다.',
  passwordStrength3: '비밀번호가 강합니다.',
  emailLengthFail: '이메일을 입력해주세요.',
  emailStrength: '이메일 형식이 잘못되었습니다.',
  secPasswordStrength: '보안비밀번호를 4자리 숫자로 입력해 주시기 바랍니다.',
  secPasswordConfirmFail: '입력하신 2개의 보안비밀번호가 서로 일치하지 않습니다. 다시 한번 확인해주세요.',

  setupNewCard: '신규생성',
  setupRecoverCard: '복구',

  phoneFail: '휴대전화 번호를 정확히 입력해주세요.',
  certiCodeFail: '인증번호를 입력해주세요.',
  certiCodeWrong: '인증번호를 잘못 입력하셨습니다.',
  certiCodeSendFail: '인증번호 발송에 실패하였습니다.',
  certiCodeNotVerified: '전화번호 인증이 필요합니다.',

  setupFinish: '에 오신 것을 축하합니다.',

  needLogout: [{ content: '' }],

  /*********공통서비스**********/
  purchase: '',
  bootError: '서버접속 실패',
  bootErrorDetail: '죄송합니다. 현재 서버접속이 원활하지 않습니다. 잠시 뒤 다시 시도해주세요.',
  appUpdate: '앱 업데이트',
  appUpdateDetail: '앱 필수 업데이트가 있습니다. 업데이트 후 서비스를 이용해 주세요.',
  goStore: '스토어 이동',
  serverDown: '서버점검',
  serverDownDetail: '서버 점검 중입니다.',
  waitLoading: '잠시만 기다려주세요.',
  waitLoadingDetail: '이 화면이 수초 이상 지속된다면 앱을 재시작해 주시기 바랍니다. 그래도 문제가 지속된다면 서버에 문제가 있을 수 있습니다.',

  /*********내정보**********/
  myinfoSerialNum: '',
  myinfoCopyCard: '카드복사',
  myinfoBackupWordQuery: '복구단어 조회',
  myinfoBackupWordQueryDetail: '스마트폰 교체나 앱을 삭제하여 지갑이 없어진 경우 복구단어를 사용하여 지갑을 복구할 수 있습니다. 아직 단어를 기록하지 않았다면 지금 반드시 메모해 두시기 바랍니다.',
  myinfoBackupWord: '복구단어',
  myinfoLogin: '로그인',
  myinfoLoginDetail: '실행중입니다.잠시만 기다려주세요',
  myinfoLoginChange: '',
  myinfoLogout: '로그아웃',
  myinfoLogoutByTimeout: '장기간 미사용으로 로그아웃 되었습니다.',
  myinfoAppVer: '앱 버전',
  myinfoHome: '홈페이지',
  myinfoInquiry: '문의하기',
  myinfoInquiry1on1: '1:1 문의',
  myinfoSupportCenter: '고객센터',
  myinfoSupportCenterValue: '',
  myinfoCorporateNumber: '사업자등록',
  myinfoCorporateNumberValue: '509-86-01382',
  myinfoNotice: '공지사항',
  myinfoNoticeCategory: '구분',
  myinfoNoticeTitle: '제목',
  myinfoNoticeDate: '등록일',
  myinfoEvent: '이벤트',
  myinfoEventCategory: '구분',
  myinfoEventTitle: '제목',
  myinfoEventDate: '등록일',
  myinfoPolicyAndGuide: '약관 및 가이드',
  myinfoGuide: '이용 가이드',
  myinfoPolicy: '이용약관',
  myinfoFaq: '자주묻는질문',
  myinfoLogoutConfirm: '로그아웃 하시겠습니까?',
  myinfoCopy: '',
  myinfoCopyTag: '',
  myinfoCopyTagExplanation: '',
  myinfoCopyTagComplete: '',
  myinfoCopyTagNotSupported: '',
  myinfoIdentify: '본인인증',
  myinfoIdentifyEmail: '메일인증하기',
  myinfoIdentifyEmailComplete: '메일인증완료',
  email: '이메일',
  inputEmail: '이메일 입력',
  inputEmailDetail: '본인 확인을 위한 이메일 주소를 입력해주세요.',
  sendEmailComplete: '본인인증을 위한 이메일이 발송되었습니다.',
  sendEmailFail: '이메일이 발송에 실패하였습니다.',
  myRecommendCode: '나의 추천코드',
  recommendCodeCopied: '추천코드가 복사되었습니다.',
  myinfoTwoSecurity: '이중 인증',
  myinfoSettingNick: '닉네임 설정',
  myinfoHidePrice: '잔액 숨기기',
  myinfoSettingLan: '언어 설정',
  myinfoIsCurrency: '통화표시',
  myinfoTwoSecurityKey: '이중 인증키',
  myinfoChangePassword: '비밀번호 수정',
  myinfoChangeSecurityPassword: '보안 비밀번호 수정',
  myinfoTwoSecurityInfo: '이중인증 키는 지갑에서 출금시,',
  myinfoTwoSecurityInfo2: '보안강화 시키는 6자리 키입니다.',
  myinfoTwoSecurityInfo3: '앱 스토어에서 Google OTP혹은',
  myinfoTwoSecurityInfo4: 'Authy 다운로드 해주세요.',
  myinfoInputAuthCode: '인증코드를 입력해주세요.',
  myinfoOtpComplete: 'OTP 설정 완료 됐습니다.',
  myinfoOtpInfo: '연동된 OTP변경 후',
  myinfoOtpInfo2: '동안은 출금이 금지됩니다.',
  startAuth: '인증 시작',
  myinfoCopyKey: '키복사',

  /*********충전**********/
  chargeCoin: 'OTC마켓충전',
  chargeCoinDescription1: '무통장입금을 통해 OTC마켓를 충전합니다. 아래 입력란에 금액을 입력하고 충전을 신청한 후 계좌이체를 진행해주세요.',
  chargeCoinDescription2: '충전신청이 완료되었습니다.',
  chargeCoinDescription3: '금액과 입금자명을 아래와 동일하게 이체해야 하며, 잘못 이체한 경우 입금처리에 다소 시간이 소요될 수 있습니다.',
  chargeCoinDescription4: '1시간 이내에 입금하지 않는 경우 자동으로 신청이 취소됩니다. 여러 건을 입금하는 경우 매 건별로 충전신청을 먼저 한 후 이체해야 합니다.',
  chargeCoinDescription5: '입금계좌, 충전금액, 입금자명을 터치하면 복사할 수 있습니다.',
  chargeCoinCopyBank: '입금계좌가 복사되었습니다.',
  chargeCoinCopyAmount: '충전금액이 복사되었습니다.',
  chargeCoinCopyName: '입금자명이 복사되었습니다.',
  chargeKrwAmount: '충전금액',
  chargeRequest: '충전신청',
  chargeName: '입금자명',
  chargeBank: '입금계좌',
  chargeRequestMaxLimit: '신청은 동시에 5건까지 가능합니다.',
  chargeList: '진행중인 충전 신청 목록',

  /*********커스텀 문구**********/
  manualInput: '수동 입력',
  manualPurchase: '결제정보 붙혀넣기',
  manualPurchaseFail: '클립보드에 결제정보가 없습니다.',

  /****** 국가 ******/
  selectCountry: '국가를 선택하세요',
  country: '국가',
  countryCode: ['KO', 'CN', 'US', 'JP',
    'AR', 'AM', 'AW', 'AU', 'AT', 'AZ', 'BH', 'BD', 'BY', 'BE',
    'BZ', 'BJ', 'BT', 'BO', 'BA', 'BW', 'BR', 'IO', 'BN', 'BG',
    'BF', 'BI', 'KH', 'CM', 'CA', 'CV', 'CF', 'TD', 'CL', 'CX',
    'CC', 'CO', 'KM', 'CK', 'CR', 'HR', 'CU', 'CW', 'CY', 'CZ',
    'CD', 'DK', 'DJ', 'TL', 'EC', 'EG', 'SV', 'GQ', 'ER', 'EE',
    'ET', 'FK', 'FO', 'FJ', 'FI', 'FR', 'PF', 'GA', 'GM', 'GE',
    'DE', 'GH', 'GI', 'GR', 'GL', 'GT', 'GN', 'GW', 'GY', 'HT',
    'HN', 'HK', 'HU', 'IS', 'IN', 'ID', 'IR', 'IQ', 'IE', 'IL',
    'IT', 'CI', 'JO', 'KZ', 'KE', 'KI', 'XK', 'KW', 'KG', 'LA',
    'LV', 'LB', 'LS', 'LR', 'LY', 'LI', 'LT', 'LU', 'MO', 'MK',
    'MG', 'MW', 'MY', 'MV', 'ML', 'MT', 'MH', 'MR', 'MU', 'YT',
    'MX', 'FM', 'MD', 'MC', 'MN', 'ME', 'MA', 'MZ', 'MM', 'NA',
    'NR', 'NP', 'NL', 'AN', 'NC', 'NZ', 'NI', 'NE', 'NG', 'NU',
    'KP', 'NO', 'OM', 'PK', 'PW', 'PS', 'PA', 'PG', 'PY', 'PE',
    'PH', 'PN', 'PL', 'PT', 'QA', 'CG', 'RE', 'RO', 'RU', 'RW',
    'BL', 'SH', 'MF', 'PM', 'WS', 'SM', 'ST', 'SA', 'SN', 'RS',
    'SC', 'SL', 'SG', 'SK', 'SI', 'SB', 'SO', 'ZA', 'SS', 'ES',
    'LK', 'SD', 'SR', 'SJ', 'SZ', 'SE', 'CH', 'SY', 'TW', 'TJ',
    'TZ', 'TH', 'TG', 'TK', 'TO', 'TN', 'TR', 'TM', 'TV', 'UG',
    'UA', 'AE', 'GB', 'UY', 'UZ', 'VU', 'VA', 'VE', 'VN', 'WF',
    'EH', 'YE', 'ZM', 'ZW',
  ],
  countryTelCode: ['82-', '86-', '1-', '81-',
    '54-', '374-', '297-', '61-', '43-', '994-', '973-', '880-', '375-', '32-',
    '501-', '229-', '975-', '591-', '387-', '267-', '55-', '246-', '673-', '359-',
    '226-', '257-', '855-', '237-', '1-', '238-', '236-', '235-', '56-', '61-',
    '61-', '57-', '269-', '682-', '506-', '385-', '53-', '599-', '357-', '420-',
    '243-', '45-', '253-', '670-', '593-', '20-', '503-', '240-', '291-', '372-',
    '251-', '500-', '298-', '679-', '358-', '33-', '689-', '241-', '220-', '995-',
    '49-', '233-', '350-', '30-', '299-', '502-', '224-', '245-', '592-', '509-',
    '504-', '852-', '36-', '354-', '91-', '62-', '98-', '964-', '353-', '972-',
    '39-', '225-', '962-', '7-', '254-', '686-', '383-', '965-', '996-', '856-',
    '371-', '961-', '266-', '231-', '218-', '423-', '370-', '352-', '853-', '389-',
    '261-', '265-', '60-', '960-', '223-', '356-', '692-', '222-', '230-', '262-',
    '52-', '691-', '373-', '377-', '976-', '382-', '212-', '258-', '95-', '264-',
    '674-', '977-', '31-', '599-', '687-', '64-', '505-', '227-', '234-', '683-',
    '850-', '47-', '968-', '92-', '680-', '970-', '507-', '675-', '595-', '51-',
    '63-', '64-', '48-', '351-', '974-', '242-', '262-', '40-', '7-', '250-',
    '590-', '290-', '590-', '508-', '685-', '378-', '239-', '966-', '221-', '381-',
    '248-', '232-', '65-', '421-', '386-', '677-', '252-', '27-', '211-', '34-',
    '94-', '249-', '597-', '47-', '268-', '46-', '41-', '963-', '886-', '992-',
    '255-', '66-', '228-', '690-', '676-', '216-', '90-', '993-', '688-', '256-',
    '380-', '971-', '44-', '598-', '998-', '678-', '379-', '58-', '84-', '681-',
    '212-', '967-', '260-', '263-',
  ],
  countryName: {
    KO: '대한민국', CN: '중국',  US: '미국', JP: '일본',
    AR: 'Argentina', AM: 'Afghanistan', AW: 'Albania', AU: 'Algeria', AT: 'American Samoa',
    AZ: 'Andorra', BH: 'Anguilla', BD: 'Antarctica', BY: 'Belarus', BE: 'Belgium',
    BZ: 'Belize', BJ: 'Benin', BT: 'Bhutan', BO: 'Bolivia', BA: 'Bosnia and Herzegovina',
    BW: 'Botswana', BR: 'Brazil', IO: 'British Indian Ocean Territory', BN: 'Brunei', BG: 'Bulgaria',
    BF: 'Burkina Faso', BI: 'Burundi', KH: 'Cambodia', CM: 'Cameroon', CA: 'Canada',
    CV: 'Cape Verde', CF: 'Central African Republic', TD: 'Chad', CL: 'Chile', CX: 'Christmas Island',
    CC: 'Cocos Islands', CO: 'Colombia', KM: 'Comoros', CK: 'Cook Islands', CR: 'Costa Rica',
    HR: 'Croatia', CU: 'Cuba', CW: 'Curacao', CY: 'Cyprus', CZ: 'Czech Republic',
    CD: 'Democratic Republic of the Congo', DK: 'Denmark', DJ: 'Djibouti', TL: 'East Timor', EC: 'Ecuador',
    EG: 'Egypt', SV: 'El Salvador', GQ: 'Equatorial Guinea', ER: 'Eritrea', EE: 'Estonia',
    ET: 'Ethiopia', FK: 'Falkland Islands', FO: 'Faroe Islands', FJ: 'Fiji', FI: 'Finland',
    FR: 'France', PF: 'French Polynesia', GA: 'Gabon', GM: 'Gambia', GE: 'Georgia',
    DE: 'Germany', GH: 'Ghana', GI: 'Gibraltar', GR: 'Greece', GL: 'Greenland',
    GT: 'Guatemala', GN: 'Guinea', GW: 'Guinea-Bissau', GY: 'Guyana', HT: 'Haiti',
    HN: 'Honduras', HK: 'Hong Kong', HU: 'Hungary', IS: 'Iceland', IN: 'India',
    ID: 'Indonesia', IR: 'Iran', IQ: 'Iraq', IE: 'Ireland', IL: 'Israel',
    IT: 'Italy', CI: 'Ivory Coast', JO: 'Jordan', KZ: 'Kazakhstan', KE: 'Kenya',
    KI: 'Kiribati', XK: 'Kosovo', KW: 'Kuwait', KG: 'Kyrgyzstan', LA: 'Laos',
    LV: 'Latvia', LB: 'Lebanon', LS: 'Lesotho', LR: 'Liberia', LY: 'Libya',
    LI: 'Liechtenstein', LT: 'Lithuania', LU: 'Luxembourg', MO: 'Macau', MK: 'Macedonia',
    MG: 'Madagascar', MW: 'Malawi', MY: 'Malaysia', MV: 'Maldives', ML: 'Mali',
    MT: 'Malta', MH: 'Marshall Islands', MR: 'Mauritania', MU: 'Mauritius', YT: 'Mayotte',
    MX: 'Mexico', FM: 'Micronesia', MD: 'Moldova', MC: 'Monaco', MN: 'Mongolia',
    ME: 'Montenegro', MA: 'Morocco', MZ: 'Mozambique', MM: 'Myanmar', NA: 'Namibia',
    NR: 'Nauru', NP: 'Nepal', NL: 'Netherlands', AN: 'Netherlands Antilles', NC: 'New Caledonia',
    NZ: 'New Zealand', NI: 'Nicaragua', NE: 'Niger', NG: 'Nigeria', NU: 'Niue',
    KP: 'North Korea', NO: 'Norway', OM: 'Oman', PK: 'Pakistan', PW: 'Palau',
    PS: 'Palestine', PA: 'Panama', PG: 'Papua New Guinea', PY: 'Paraguay', PE: 'Peru',
    PH: 'Philippines', PN: 'Pitcairn', PL: 'Poland', PT: 'Portugal', QA: 'Qatar',
    CG: 'Republic of the Congo', RE: 'Reunion', RO: 'Romania', RU: 'Russia', RW: 'Rwanda',
    BL: 'Saint Barthelemy', SH: 'Saint Helena', MF: 'Saint Martin', PM: 'Saint Pierre and Miquelon', WS: 'Samoa',
    SM: 'San Marino', ST: 'Sao Tome and Principe', SA: 'Saudi Arabia', SN: 'Senegal', RS: 'Serbia',
    SC: 'Seychelles', SL: 'Sierra Leone', SG: 'Singapore', SK: 'Slovakia', SI: 'Slovenia',
    SB: 'Solomon Islands', SO: 'Somalia', ZA: 'South Africa', SS: 'South Sudan', ES: 'Spain',
    LK: 'Sri Lanka', SD: 'Sudan', SR: 'Suriname', SJ: 'Svalbard and Jan Mayen', SZ: 'Swaziland',
    SE: 'Sweden', CH: 'Switzerland', SY: 'Syria', TW: 'Taiwan', TJ: 'Tajikistan',
    TZ: 'Tanzania', TH: 'Thailand', TG: 'Togo', TK: 'Tokelau', TO: 'Tonga',
    TN: 'Tunisia', TR: 'Turkey', TM: 'Turkmenistan', TV: 'Tuvalu', UG: 'Uganda',
    UA: 'Ukraine', AE: 'United Arab Emirates', GB: 'United Kingdom', UY: 'Uruguay', UZ: 'Uzbekistan',
    VU: 'Vanuatu', VA: 'Vatican', VE: 'Venezuela', VN: 'Vietnam', WF: 'Wallis and Futuna',
    EH: 'Western Sahara', YE: 'Yemen', ZM: 'Zambia', ZW: 'Zimbabwe',
  },

  /*********약관 등**********/
  policyContent: [
    { title: '제1장 총칙', content: '' },
    {
      title: '제1조 (목적)',
      content:
        '이 약관은 주식회사 OTC마켓(이하: 회사)이 제공하는 앱 및 지갑에서 제공하는 서비스(이하: 서비스)의 이용조건 및 절차에 관한 회사와 사용자 간의 권리 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.\n',
    },
    {
      title: '제2조 (약관의 명시, 설명과 개정)',
      content:[
        '① 이 약관의 내용은 회사의 서비스 등 기타의 방법으로 사용자에게 공지하고, 사용자가 이 약관에 동의함으로써 효력을 발생합니다.\n',
        '② 회사는 “약관의 규제에 관한 법률”, “정보통신망 이용촉진 및 정보보호 등에 관한 법률” 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.\n',
        '③ 회사가 약관을 개정할 경우에는 적용일자 및 개정 사유를 명시하여 현행 약관과 함께 회사 사이트의 초기화면이나 팝업 화면 또는 공지사항으로 그 적용일자 7일 이전부터 공지합니다. 다만, 사용자에게 불리하거나 중대한 내용의 변경의 경우에는 적용일자 30일 이전에 사용자에게 알립니다.\n',
        '④ 회사가 전항에 따라 개정약관을 공지하면서 사용자에게 7일간의 기간 내에 의사 표시를 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게 공지하였음에도 사용자가 명시적으로 거부의 의사표시를 하지 아니한 경우 사용자가 개정약관에 동의한 것으로 봅니다.\n',
        '⑤ 사용자가 개정약관의 적용에 동의하지 않는다는 명시적 의사를 표명한 경우 회사는 개정 약관의 내용을 적용할 수 없으며, 이 경우 사용자는 이용계약을 해지할 수 있습니다. 다만, 기존 약관을 적용할 수 없는 특별한 사정이 있는 경우에는 회사는 이용계약을 해지할 수 있습니다.\n',
      ]
    },
    {
      title: '제3조 (약관 외 준칙)',
      content:
        '회사는 서비스에 대하여 이 약관 외에 별도의 공지사항, 개별 약관, 개별 동의 사항, 이용안내, 운영정책, 세부지침 등(이하 통틀어 “세부지침”이라 합니다)을 정하여 이를 사용자에게 알리거나 동의를 받을 수 있으며, 세부지침 중 사용자의 동의를 받은 내용이 이 약관과 상충할 경우에는 “세부지침”이 우선하여 적용됩니다. 이 약관에서 정하지 아니한 사항이나 해석에 대해서는 “세부지침” 및 관련 법령 또는 상관례에 따릅니다.\n',
    },
    {
      title: '제4조 (용어의 정의)',
      content:[
        '①	이 약관에서 사용하는 용어의 정의는 다음과 같습니다.\n',
        '1.암호화폐: 비트코인(BTC), 이더리움(ETH) 등 블록체인에서 전자적으로 존재하는 화폐를 말합니다.\n',
        '2.사용자: 이 약관을 승인하고 회사가 제공하는 지갑을 등록한 이용자를 말합니다.\n',
        '3.앱: 암호화폐를 저장하거나 송수신 할 수 있는 스마트폰 온라인 전용 프로그램을 말합니다.\n',
        '4.지갑: 스마트폰에 암호화폐를 저장 할 수 있는 앱을 말합니다.\n',
        '5.비밀번호: 사용자와 지갑간의 동일성 확인과 암호화폐 보안을 위하여 사용자가 설정한 문자의 조합을 말합니다.\n',
        '6.복구단어: 지갑 분실시 복구할 수 있는 12개의 암호화된 단어를 말합니다.\n',
      ]
    },
    {
      title: '제2장 서비스 이용 신청 및 승낙 (사용자가입 및 해지)',
      content: '',
    },
    {
      title: '제5조 (이용계약의 성립)',
      content:[
        '①	이용자는 회사가 제공하는 앱에 지갑 생성 시 이 약관에 동의한다는 의사표시를 함으로서 지갑 사용을 신청합니다.\n',
        '②	이용 계약은 지갑을 등록한 단위로 체결합니다. 이용계약이 성립되면, 이용 신청자는 지갑 사용자로 등록됩니다.\n'
      ]
    },
    {
      title: '제6조 (이용신청)',
      content: '이용신청은 회사가 제공하는 앱에 지갑 등록시 자동으로 이용신청이 승인됩니다.\n',
    },
    {
      title: '제7조 (사용자정보 사용에 대한 동의 및 이용신청의 승낙)',
      content:[
        '① 사용자정보 사용에 대한 동의\n',
        '1.회사는 사용자의 개인정보를 본 이용계약의 이행과 본 이용계약상의 서비스제공을 위한 목적으로 이용합니다.\n',
        '2.사용자가 회사 및 회사와 제휴한 서비스들을 편리하게 이용할 수 있도록 하기 위해 사용자 정보는 회사와 제휴한 업체에 제공될 수 있습니다. 단, 회사는 사용자 정보 제공 이전에 제휴 업체, 제공 목적, 제공할 사용자 정보의 내용 등을 사전에 공지하고 사용자의 동의를 얻어야 합니다.\n',
        '3.사용자는 사용자 정보 수정을 통해 언제든지 개인 정보에 대한 열람 및 수정을 할 수 있습니다.\n',
        '4.사용자가 이용신청서에 사용자정보를 기재하고, 회사에 본 약관에 따라 이용신청을 하는 것은 회사가 본 약관에 따라 이용신청서에 기재된 사용자정보를 수집, 이용 및 제공하는 것에 동의하는 것으로 간주됩니다.\n',
      ]
    },
    {
      title: '제8조 (이용계약의 중지 및 해지)',
      content:[
        '①이용계약 및 앱 사용의 해지는 사용자가 스마트폰에서 앱을 삭제함으로써 해지가 가능합니다.\n',
        '② 회사는 가입신청자의 신청에 대하여 아래 각 호에 해당하는 사유가 있는 경우에는 승낙을 하지 않을 수 있으며, 가입 이후에도 아래 각 호의 사유가 확인될 경우에는 승낙을 취소하거나 이용계약을 해지할 수 있습니다.\n',
        '1. 본 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우\n',
        '2. 허위 또는 잘못된 정보를 기재 또는 제공하거나, 회사가 제시하는 내용을 기재하지 않은 경우\n',
        '3. 가입신청자의 귀책사유로 인하여 승인이 불가능하거나 기타 본 약관에서 규정한 제반 사항을 위반하며 신청한 경우\n',
        '4. 부정한 용도 또는 별개의 영업 목적으로 서비스를 이용하고자 하는 경우\n',
        '5. 관련법령에 위배되거나 사회의 안녕질서 혹은 미풍양속을 저해할 수 있는 목적으로 신청한 경우\n',
        '6. 가입신청자의 나이가 만 19세 미만인 경우\n',
        '③ 회사는 회원이 제공한 정보가 사실과 일치하는 지 여부를 확인하기 위하여 법령에 의하여 허용된 범위에서 전문기관을 통한 실명확인 또는 본인인증을 요청할 수 있습니다.\n',
      ]
    },
    { title: '제3장 회사의 의무', content: '' },
    {
      title: '제9조 사용자 개인정보와 서비스에 대한 회사의 의무',
      content:[
        '① 회사는 관련 법령과 본 약관을 준수하며, 계속적이고 안정적으로 서비스를 제공하기 위하여 최선을 다하여 노력합니다.\n',
        '② 회사는 사용자가 안전하게 서비스를 이용할 수 있도록 개인정보보호를 위해 보안시스템을 갖출 수 있으며, 개인정보처리방침을 공시하고 준수합니다.\n',
        '③ 회사는 업무와 관련하여 사용자의 사전 동의 없이 사용자 전체 또는 일부의 개인정보 등을 특정 사용자의 정보를 구별할 수 없는 통계자료로 작성하여 이를 사용할 수 있고, 이를 위하여 사용자는 컴퓨터에 쿠키를 전송할 수 있습니다. 다만, 이 경우 사용자는 쿠키의 송신을 거부하거나 쿠키의 송신에 대하여 경고하도록 사용하는 기기의 설정을 변경할 수 있으나, 이 경우 쿠키의 설정 변경으로 인해 회사의 귀책사유 없이 서비스 이용이 변경될 수 있습니다.\n',
        '④ 회사는 서비스 이용과 관련하여 사용자로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 처리하여야 하며, 서비스 내 게시판, E-mail 등을 통하여 사용자에게 처리과정 및 결과를 전달할 수 있습니다.\n',
        '⑤ 회사는 정보통신망법, 위치정보보호법, 통신비밀보호법, 전기통신사업법 등 서비스의 운영, 유지와 관련 있는 법규를 준수합니다.\n',
      ]
    },
    { title: '제4장 사용자의 의무', content: '' },
    {
      title: '제10조 (사용자 지갑과 비밀번호 관리에 대한 사용자의 의무)',
      content:[
        '① 지갑과 비밀번호에 관한 모든 관리책임은 사용자에게 있습니다. 사용자가 등록한 지갑과 비밀번호의 관리소홀, 부정사용에 의하여 발생하는 모든 결과에 대한 책임은 사용자에게 있으며, 회사는 이에 대한 책임을 지지 않습니다. 다만, 회사의 고의로 인한 경우에는 그러하지 아니합니다.\n',
        '② 12개의 복구 단어는 반드시 종이에 기입 해 놓아야 하며 지갑을 분실하여 복구 할때 12개의 복구 단어가 필요합니다. 회사는 이 정보를 가지고 있지 않기 때문에 복구단어가 분실하는 경우에 복구가 불가능한데 이때 모든 책임은 사용자에게 있으며 회사는 이에 대한 책임을 지지 않습니다. 또한 본인의 실수로 인해 12개의 복구 단어가 유출되어 입은 손실도 모든 책임은 사용자에게 있으며 회사는 이에 대한 책임을 지지 않습니다.\n',
      ]
    },
    {
      title: '제11조 (정보의 제공)',
      content:[
        '1. 회사는 유∙무선전화, 전자우편, 문자서비스(LMS/SMS), SNS 등의 방법으로 다음과 같은 서비스에 대해서 이용편의를 위한 이용안내 및 상품에 관한 정보를 제공할 수 있습니다. 이 경우 사용자는 언제든지 수신을 거부할 수 있습니다.\n',
        '① 이벤트 및 행사관련 등의 서비스\n',
        '② 기타 회사가 수시로 결정하여 사용자에게 제공하는 서비스\n',
        '2. 사용자는 서비스를 통하여 언제든지 본인의 개인정보를 열람하고 수정 또는 수정 요청할 수 있습니다. 사용자는 회원가입 신청 시 기재한 사항이 변경되었을 경우 서비스에서 직접 수정하거나, 고객센터를 통하여 회사에 변경 사항을 통지하여야 합니다. 사용자는 전항의 변경사항을 회사에 통지하지 않아 발생한 불이익에 대하여 회사는 책임을 지지 않습니다.\n',
      ]
    },
    { title: '제5장 서비스 이용 총칙', content: '' },
    {
      title: '제12조 (서비스의 종류)',
      content:[
        '① 회사에서 제공하는 서비스에는 암호화폐를 저장하는 지갑기능, 다른 사람에게 암호화폐를 송수신 할수 있는 기능 등이 있습니다. 단, 사용자가 제공하는 암호화폐 지갑 주소의 정확성에 대한 책임은 사용자에게 있으며, 회사는 사용자가 잘못 기재한 암호화폐 지갑 주소로 암호화폐를 보내는 것에 대해서 책임을 지지 않습니다.\n',
        '② 회사가 제공하는 서비스의 종류는 회사의 사정에 의하여 수시로 변경될 수 있으며, 제공되는 서비스에 대한 저작권 및 지식재산권은 회사에 귀속됩니다.\n',
        '③ 회사는 서비스와 관련하여 사용자에게 회사가 정한 이용조건에 따라 계정, 지갑, 서비스 등을 이용할 수 있는 이용권한만을 부여하며, 사용자는 이를 활용한 유사 서비스 제공 및 상업적 활동을 할 수 없습니다.\n',
      ]
    },
    {
      title: '제13조 (서비스 내용의 공지 및 변경)',
      content:[
        '① 회사는 서비스의 종류에 따라 각 서비스의 특성, 절차 및 방법에 대한 사항을 웹사이트를 통하여 공지하며, 사용자는 회사가 공지한 각 서비스에 관한 사항을 이해하고 서비스를 이용해야 합니다.\n',
        '② 회사는 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제공하고 있는 개별 서비스의 전부 또는 일부를 변경할 수 있습니다. 이 경우 회사는 최소 7일전에 해당 내용을 이용자에게 알립니다.\n',
      ]
    },
    {
      title: '제14조 (서비스의 유지 및 중지)',
      content:[
        '① 서비스의 이용은 회사의 업무상 또는 기술상 특별한 지장이 없는 한 1일 24시간을 원칙으로 합니다. 다만 정기 점검 등의 필요로 회사가 사전 혹은 사후에 고지하여 정한 날이나 시간은 그러하지 않습니다.\n',
        '② 회사는 서비스를 일정범위로 분할하여 각 범위 별로 이용가능 시간을 별도로 정할 수 있습니다. 이 경우 그 내용을 사전 혹은 사후에 공지합니다.\n',
        '③ 회사는 다음 각 호에 해당하는 경우 서비스 제공을 중지할 수 있습니다. 이 경우 회사는 불가피한 경우가 아닌 한 서비스 제공 중지를 사전 고지합니다.\n',
        '1. 서비스용 설비의 보수 등 공사로 인한 부득이한 경우\n',
        '2. 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지했을 경우\n',
        '3. 회사가 직접 제공하는 서비스가 아닌 제휴업체 등의 제3자를 이용하여 제공하는 서비스의 경우 제휴 업체 등의 제3자가 서비스를 중지했을 경우\n',
        '4. 기타 불가항력적 사유가 있는 경우\n',
        '④ 회사는 국가비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 때에는 서비스의 전부 또는 일부를 제한하거나 정지할 수 있습니다.\n',
      ]
    },
    {
      title: '제 15 조 이용제한',
      content:[
        '① 회사는 다음 각 호에 해당하는 경우 회원의 서비스 이용 및 접속을 제한할 수 있습니다.\n',
        '1. 비밀번호 및 보안비밀번호 연속 오류의 경우\n',
        '2. 해킹 및 사고가 발생한 경우\n',
        '3. 명의 도용으로 의심되는 경우\n',
        '4. 국가기관이 관련 법령에 따라 서비스 제한을 요청하는 경우\n',
        '5. 자금세탁, 불공정거래, 범죄행위 등에 관여하고 있거나 관여하고 있다고 합리적으로 의심되는 경우\n',
        '6. 회원으로 가입된 이용자가 만 19세 미만으로 확인된 경우\n',
        '7. 기타 각호에 준하는 사유가 발생하거나 그러한 사유 발생의 방지를 위한 조치가 필요한 경우\n',
        '② 회사는 다음 각 호에 해당하는 경우 회원의 입금 및 출금 이용을 제한할 수 있습니다.\n',
        '1. 회원으로 가입된 이용자가 만 19세 미만으로 확인된 경우\n',
        '2. 해킹 및 사기 사고가 발생하였거나 발생한 것으로 의심되는 경우\n',
        '3. 명의 도용으로 의심되는 경우\n',
        '4. 국가기관이 관련 법령에 따라 서비스 제한을 요청하는 경우\n',
        '5. 자금세탁, 불공정거래, 범죄행위 등에 관여하고 있거나 관여하고 있다고 합리적으로 의심되는 경우\n',
        '6. 회원 본인의 요청이 있는 경우\n',
        '7. 암호화폐가 송금되었으나 회원 계정에 반영되지 않은 경우 또는 실제 입출금 요청과 다르게 입출금이 이루어진 경우\n',
        '8. 기타 각호에 준하는 사유에 해당하는 사유가 발생하거나 그러한 사유 발생의 방지를 위한 조치가 필요한 경우\n',
        '③ 본 조에 따라 서비스 이용을 제한하거나 이용계약을 해지하는 경우에는 회사는 E-mail, SMS, App Push 등으로 회원에게 통지합니다.\n',
        '④ 회원은 본 조에 따른 이용제한 등에 대해 회사가 정한 절차에 따라 이의신청을 할 수 있습니다. 이 때 이의가 정당하다고 회사가 인정하는 경우 회사는 즉시 서비스의 이용을 재개합니다.\n',
      ]
    },
    {
      title: '제 16 조 (이용계약 해지)',
      content:[
        '① 사용자는 언제든지 고객센터를 통하여 이용계약 해지를 신청할 수 있으며, 회사는 관련 법령이 정하는 바에 따라 이를 즉시 처리하여야 합니다.\n',
        '② 회사는 회원에게 다음과 같은 사유가 발생할 경우 시간을 정하여 서비스 이용을 제한함과 동시에 시정요구를 할 수 있습니다. 시정요구에도 불구하고 상당한 기간 내에 시정되지 않거나 반복적으로 동일한 위반행위를 하는 경우에는 이용계약을 해지할 수 있습니다.\n',
        '1. 본 약관 제10조에 규정된 사용자의 의무를 위반하거나 제15조에 규정된 이용제한 사유에 해당하는 경우\n',
        '2. 저작권법을 위반한 불법 프로그램의 제공 및 운영 방해, 정보통신망 이용촉진 및 정보보호 등에 관한 법률을 위반한 불법 통신 및 해킹, 악성프로그램의 배포, 접속 권한 초과 행위 등과 같이 관련 법령을 위반한 경우\n',
        '3. 회사가 제공하는 서비스의 원활한 진행을 방해하는 행위를 하거나 시도한 경우\n',
        '4. 그 밖에 각 호에 준하는 사유로서 본 계약을 유지할 수 없는 사유가 발생하는 경우\n',
        '③ 전항에 따른 이용계약 해지 시 서비스 이용을 통해 획득한 모든 혜택이 소멸되며 회사는 이에 대해 별도로 보상하지 않습니다.\n',
        '④ 본 조에 따라 서비스 이용계약을 해지하는 경우에는 회사는 사용자에게 E-mail, SMS, App Push 등으로 통지합니다.\n',
        '⑤ 이용계약 해지가 완료되는 경우 관련 법령 및 개인정보처리방침에 따라 회사가 보유하여야 하는 정보를 제외한 사용자의 모든 정보가 삭제됩니다.\n',
        '⑥ 제2항에 따라 회사가 이용계약을 해지하는 경우 회사는 사용자의 이의신청 접수 및 처리 등을 위하여 일정 기간 동안 사용자의 정보를 보관할 수 있으며, 해당 기간이 경과한 후에 사용자의 정보(단, 입출금 내역은 제외)를 삭제합니다.\n',
      ]
    },
    {
      title: '제 17 조 (정보의 제공 및 광고의 게재)',
      content:[
        '① 회사는 서비스를 운영함에 있어서 서비스 관련 각종 정보를 서비스 화면에 게재하거나 E-mail, SMS, App Push 등의 방법으로 사용자에게 제공할 수 있습니다.\n',
        '② 회사는 서비스를 운영함에 있어 회사 또는 제휴사의 각종 광고를 서비스 화면에 게재하거나 사용자의 동의를 얻어 E-mail, SMS, App Push 등의 방법으로 사용자에게 제공할 수 있습니다',
      ]
    },
    { title: '제5장 개인정보 보호', content: '' },
    {
      title: '제18조 (개인정보의 보호)',
      content:[
        '회사는 관계 법령이 정하는 바에 따라 사용자의 개인정보를 보호하기 위해 노력합니다. 사용자의 개인정보는 서비스의 원활한 제공을 위하여 사용자가 동의한 목적과 범위 내에서만 이용됩니다. 회사는 법령에 의하거나 사용자가 별도로 동의하지 아니하는 한, 사용자의 개인정보를 제3자에게 제공하지 아니하며, 이에 대한 자세한 사항은 개인정보 처리방침에서 정합니다.\n',
      ]
    },
    { title: '제6장 손해배상 및 면책조항', content: '' },
    {
      title: '제19조 (회사의 면책사항 및 손해배상)',
      content:[
        '① 회사는 서비스와 관련하여 이 약관에 명시되지 않은 어떠한 사항에 대하여 보증을 하지 않습니다. 또한, 회사는 회사가 발행하거나 지급을 보증하지 아니한 암호화폐의 가치를 보증하지 않습니다.\n',
        '② 회사는 천재지변, 디도스(DDos) 공격, IDC장애, 서비스 접속의 폭등으로 인한 서버 다운, 기간통신사업자의 회선 장애 등 기타 불가항력적인 사유로 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다. 다만, 회사의 고의 또는 중과실에 의한 경우에는 그러하지 아니합니다.\n',
        '③ 회사는 블록체인의 문제, 암호화폐 발행 관리 시스템 자체의 하자 또는 기술적 문제, 통신서비스 업체의 불량, 정기적인 서버점검 등으로 인하여 불가피하게 장애가 발생하였을 경우에 책임을 지지 않습니다. 다만, 회사의 고의 또는 중과실에 의한 경우에는 그러하지 아니합니다.\n',
        '④ 회사는 사용자의 귀책사유로 인한 서비스 이용 장애나 그 결과에 대하여는 책임을 지지 않습니다. 다만, 사용자에게 정당한 사유가 있는 경우에는 그러하지 아니합니다.\n',
        '⑤ 회사는 사용자간 또는 사용자과 제3자 상호간에 서비스를 매개로 하여 거래 등을 한 경우에는 책임이 면제됩니다. 다만, 회사의 고의 또는 중과실에 의한 경우에는 그러하지 아니합니다.\n',
        '⑥ 회사는 무료로 제공되는 서비스 이용과 관련하여 관련법에 특별한 규정이 없는 한 책임을 지지 않습니다.\n',
        '⑦ 회사는 안정적인 서비스 제공을 위해 정기적, 비정기적 또는 긴급한 서버 점검을 실시할 수 있습니다.\n',
        '⑧ 누구든지 서비스 오류, 전산장애 또는 기타 사유로 인하여 제3자 소유의 암호화폐를 권한 없이 취득하거나 전송 받게 될 경우, 회사는 당사자에게 사전 통지 후 해당 암호화폐를 회수하거나 원상회복 시키는 등 필요한 조치를 취할 수 있습니다.\n',
      ]
    },
    {
      title: '제20조 (관할법원 및 준거법)',
      content:[
        '① 본 서비스 이용 약관은 세인트빈센트 법률에 따라 규율되고 해석되며, 회사의 요금체계 등 서비스 이용과 관련하여 회사와 사용자 간에 발생한 분쟁으로 소송이 제기되는 경우 세인트빈센트 법률이 적용됩니다.\n',
        '② 서비스 이용과 관련하여 회사와 사용자 간에 발생한 소송의 경우 법령에 정한 절차에 따른 법원을 관할 법원으로 합니다.\n',
      ]
    },
    { title: '부칙', content: '본 약관은 2020년 1월 1일부터 적용됩니다.' },
  ],  

  /*********오류코드**********/
  E10000: '네트워크 접속 불가',
  E10001: '알수없는 서버 오류',
  E10002: '잘못된 요청정보',
  E10003: '권한없음',
  E10004: '블록체인 정보요청 오류',
  E30001: '코인 주소 미생성 오류',

  /*********기타**********/
  needLogin2: '로그인이 필요합니다.',
  hideBalance: '잔액 숨기기',

  /**********번역필요 **********/
  loginFailedResendEmail: '인증메일 재전송',

  emailResent: '인증메일이 재발송되었습니다.',
  emailResendFailed: '인증메일 재발송에 실패하였습니다.',
  invalidEmail: '이미 인증된 이메일입니다.',
  emailAlreadyVerified: '잘못된 이메일입니다.',

  certiCodeExpired: '인증 기한이 초과되었습니다. 인증번호를 다시 전송하시기 바랍니다.',

  serverRequestError: '서버 요청 실패',
  serverRequestErrorDescription: '서버 요청에 실패하였습니다.',

  duplCheck: '중복확인',

  emailDuplCheckError: '이메일 중복확인에 실패하였습니다.',
  emailDuplCheckErrorEmailDupl: '이미 등록된 이메일입니다.',
  emailDuplCheckErrorInvalidEmail: '이메일 형식이 잘못되었습니다.',
  needEmailDuplCheck: '이메일 중복 확인이 필요합니다.',

  setupFinish2: '인증 메일이 발송되었습니다.\n60분 안에 인증을 완료해 주시기 바랍니다.',

  sendCoinFailedOtpRequired: '일일 보내기 한도를 초과하였습니다. OTP를 설정하여 한도를 상향시켜 주시기 바랍니다.',

  useBioAuth: '생체인증 사용',
  bioAuth: '생체인증',
  bioRequire: '생체를 인식해 주세요',
  bioFail: '생체 인증에 실패하였습니다.',

  feeRequestFailed: '수수료 조회에 실패하였습니다.',
  exemption: '면제(회원간 전송)',

  sendCoinSuccessNeedApproval: '송금을 요청이 완료되었으며 관리자 승인 후 코인이 전송됩니다.',

  setNickname: '닉네임 설정',
  setNicknameDescryption: '닉네임을 설정해 주세요',
  enterNickname: '닉네임 입력',
  enterNicknameValidation: '닉네임을 1자 이상 8자 이하로 입력해 주시기 바랍니다.',
  setNicknameSucceed: '닉네임 설정이 완료되었습니다.',
  setNicknameFailed: '닉네임 설정에 실패하였습니다.',

  helloNickname: '안녕하세요, XX님',
  helloWithoutNickname: '안녕하세요?',

  hideBalance: '잔액 숨기기',
  hideBalanceSucceed1: '잔액 숨기기가 설정되었습니다.',
  hideBalanceSucceed2: '잔액 숨기기가 해제되었습니다.',
  hideBalanceFailed: '잔액 숨기기 설정에 실패하였습니다.',

  supportTeam: '고객센터',
  supportEmailCopied: '고객센터 이메일이 복사되었습니다.',

  asset: '자산현황',
  sendCoinFailedLockedDetail: '- 첫입금 : 72시간\n- OTP 설정/변경 : 72시간\n- 보안비밀번호 변경 : 72시간',

  memberTrade: '회원간', // 회원간 입금, 회원간 출금
  externalTrade: '외부',  // 외부 입금, 외부 출금

  securitySettings: '보안 설정',

  mnemonicValidationTitle: '니모닉코드 확인',
  mnemonicValidationDescryption: '니모닉코드를 올바른 순서대로 선택하십시오',
  mnemonicValidationFailed: '니모닉코드의 순서가 올바르지 않습니다. 다시 확인해 주시기 바랍니다.',

  /////////////////////////////////////////////////////////////////////////////

  exchangeCoinConfirmMessageWarn: '(거래 비율은 거래 시점의 시세로 처리되기 때문에 화면상 수량과 다소 오차가 발생할 수 있습니다.)',
  generateQrMenu: '결제받기',
  amount: '수량',
  shopName: '상호',
  serialNumber: '시리얼번호',
  generate: '발행하기', // QR 발행하기
  purchaseAmount: '결제금액',
  lastPamentList: '최근 결제 내역',
  paymentCancelInsufficientUserBalance: '사용자의 잔액이 부족하여 취소할 수 없습니다.',
  paymentCancelInsufficientOrganizationBalance: '가맹점의 잔액이 부족하여 취소할 수 없습니다.',
  paymentCancelInvalidPaymentIdx: '잘못된 결제정보입니다.',
  paymentCancelNotOrganization: '취소할 수 있는 가맹점이 아닙니다.',
  paymentCancelFail: '알수없는 오류로 취소에 실패하였습니다.',
  paymentCancelSuccess: '취소처리가 완료되었습니다.',

  qrNoSerial: '가맹점 정보가 없습니다.',

  purchaseSucceed: '결제가 완료되었습니다.',
  purchaseFailed: '결제에 실패하였습니다. 다시 시도해 주시기 바랍니다.',

  InvalidFranchise: '가맹점 정보가 잘못되었습니다.',
  InvalidCoinType: '코인 종류가 잘못되었습니다.',

  exchangeFailedTitle: '거래 실패',
  exchangeFailed: '거래에 실패하였습니다.',

  setBankAccount: '계좌 설정',
  setBankAccountDetail: '계좌번호를 등록해 주세요.',
  inputBankAccount: '계좌번호 입력',
  selectBankAccount: '계좌 선택',
  bankCodeValidation: '은행을 선택해 주세요.',
  accountValidation: '계좌번호를 입력해 주세요.',
  setAccountSucceed: '계좌 입력이 완료되었습니다.',
  setAccountFailed: '계좌 입력에 실패하였습니다.',

  purchaseAmountValidation: '금액을 입력해 주세요.',

  serverRequestErrorDescription2: '앱을 다시 시작해 주세요.',
  showAddressDetail: 'Destination Tag까지 정확히 입력되어야 입금처리가 됩니다.',
  inputOtpValidation: 'OTP를 입력해 주세요.',

  id: '이메일',
  inputId: 'Email',
  loginFailedInvalidLoginInfo: '로그인 정보가 잘못되었습니다.',
  idLengthFail: 'Email을 입력해주세요.',

  step: '단계',

  setupStepGuidePolicy: '지갑 생성을 위한 계정 등록, 보안비밀번호 입력 등을 진행합니다. 시작하기 위하여 약관을 확인하고 동의해 주시기 바랍니다.',

  bankName: [
    '경남은행',
    '광주은행',
    'KB국민은행',
    'IBK기업은행',
    '농협은행',
    '대구은행',
    '부산은행',
    '산업은행',
    '새마을금고',
    '수협은행',
    '신한은행',
    '신협은행',
    'SC은행',
    '우리은행',
    '우체국',
    '제주은행',
    '카카오뱅크',
    'k뱅크',
    'KEB하나은행',
    'HSBC은행',
    '뱅크오브아메리카',
    '도이치은행',
  ],
  bankCode: [
    'BANK_KN',
    'BANK_KJ',
    'BANK_KB',
    'BANK_IBK',
    'BANK_NH',
    'BANK_DGB',
    'BANK_BUSAN',
    'BANK_KDB',
    'BANK_MG',
    'BANK_SH',
    'BANK_SHINHAN',
    'BANK_CU',
    'BANK_SC',
    'BANK_WOORI',
    'BANK_POST',
    'BANK_JEJU',
    'BANK_KAKAO',
    'BANK_K',
    'BANK_KEB',
    'BANK_HSBC',
    'BANK_AMERICA',
    'BANK_DB',
  ],
  payment: '결제',
  paymentList: '결제내역',
  sendReceive: '입출금내역',
  exchangeTarget: '거래대상',
  exchangeTargetAddress: '거래대상주소',
  exchangeRatio: '거래비율',
  franchiseName: '가맹점명',
  payback: '페이백',

  noNotice: '등록된 공지사항이 없습니다.',
  noEvent: '등록된 이벤트가 없습니다',
  notAvailableRecommender: '추천인을 찾을 수 없습니다.',
  sendCoinFailedOrganization: '가맹점 계정으로는 코인을 보낼 수 없습니다.',

  adjustAccount: '정산',

  nameFail: '이름을 정확히 입력해주세요.',

  paymentInfo: '결제 정보',
  paymentSucceedInfoArrived: '결제 완료 내역이 도착하였습니다.',
  paymentCanceledInfoArrived: '결제 취소 내역이 도착하였습니다.',
  payer: '결제자',

  //////////////////////////////////////////v1.1///////////////////////////////////////////////////////
  languageSetting: '언어설정',
  languageSettingModified: '언어설정이 변경되었습니다. 앱을 다시 시작해 주세요.',

  eventTempTitle: '타이거월렛에서는 다양한 이벤트를 준비하고 있습니다.',
  eventTempSub1: '회원가입 및 회원추천을 통한 에어드랍',
  eventTempSub2: '코인 스테이킹을 통한 자산운용',
  eventTempSub3: '인기 코인의 특가 교환 이벤트',
  eventTempSub4: '온오프라인 결제 이벤트',
  eventTempSub5: '연락처 친구초대 이벤트 등',

  findPasswordDescription: '가입한 이메일을 입력하면 비밀번호를 변경할 수 있는 링크가 이메일로 전송됩니다.',
  findPasswordEmailSentSuccess: '비밀번호 변경을 위한 이메일이 발송되었습니다.',
  
  /*********이벤트**********/
  eventTitle: '이벤트',
  eventListBtn: '나의 이벤트 내역',

  onProgress: '진행중',
  more: '더보기',
  noEvent: '등록된 이벤트가 없습니다.',

  
  /*********이벤트 상세**********/
  eventDetTitle: '이벤트 내역',
  paidComplete: '지급 완료',
  eventNew:'신규 회원가입 보상 이벤트',


  /*********교환*********/
  register: '등록자',
  registDate: '등록일',
  doApply: '구매하기',
  doCancel: '취소하기',
  exchangeMyList: '나의 내역',
  exchangeRegist: '판매 등록',
  viewAll: '전체보기',
  exchangeSelectCoin: '선택하기',

  enterAmount: '수량 입력',

  exchangeSameCoinFail: '같은 코인끼리는 거래할 수 없습니다.',

  exchangeSendCoin: '보내는 코인',
  exchangeReceiveCoin: '받는 코인',
  exchangeCoinInputAmount: '코인을 입력해주세요',

  exchangePolicy01: '- 코인 판매 시 판매 금액에 수수료를 합산한 금액이 보유하고 있는 잔고에서 차감됩니다.',
  exchangePolicy02: '- 판매 등록을 취소하는 경우 차감된 금액에서 취소수수료 만큼을 제외한 금액이 환불됩니다.',
  exchangePolicy03: '- 한번 판매 등록을 하면 매칭되거나 취소될 때까지 대기합니다.',

  exchangeRegistNoBalance: '판매할 수 있는 잔고가 부족합니다.',
  exchangeRegistFail: '실패하였습니다. 다시 시도해주세요.',

  exchangeCancel: '거래취소',
  exchangeCancelMessage: '판매를 취소하면 취소 수수료가 발생합니다. 취소하시겠습니까?',
  exchangeCancelFee: '취소수수료',
  exchangeDone: '거래완료',
  
  exchangeApply: '구매하기',
  exchangeApplyMessage: '한번 구매하면 취소할 수 없습니다. 구매 하시겠습니까?',
  exchangeApplyNoBalance: '구매할 수 있는 잔고가 부족합니다.',
  exchangeInvaild: '이미 거래가 되었거나 취소된 건입니다.',
  exchangeSelfFail: '자기자신과 거래할 수 없습니다.',
  exchangeRegistDate: '등록일시',

  exchangeStatusPending: '거래대기중',
  exchangeStatusCancel: '취소완료',
  exchangeStatusMatch: '거래완료',

  exchangeMyRegist: '판매목록',
  exchangeMyApply: '구매목록',
  exchangeBuyCoin: '구매코인',
  exchangeSentCoin: '보낸코인',
  
  pushSetting: '푸시설정',

  recommendTextWithRewardAdditionRecommend: '%s님이 고객님에게 [%s 월렛 앱]을 추천하셨습니다! \n1. 앱 가입시 %s %s코인을 드립니다.\n2. 앱 가입시 추천인 코드를 입력하시면 %s %s 추가보상 혜택을 드립니다!\n3. 친구초대 추천 보상 - 친구 초대를 통해 친구를 1명 초대할때마다 %s %s 코인을 추가로 얻을 수 있습니다.\n친구와 함께 %s 플랫폼의 혜택을 받아가세요!\n\n추천인 코드 : %s\n\nOTC마켓에 리스팅 되는 수많은 코인들을 에어드랍 받아보세요~\n%s',
  recommendTextWithRewardRecommend: '%s님이 고객님에게 [%s 월렛 앱]을 추천하셨습니다! \n1. 앱 가입시 %s %s코인을 드립니다.\n2. 친구초대 추천 보상 - 친구 초대를 통해 친구를 1명 초대할때마다 %s %s 코인을 추가로 얻을 수 있습니다.\n친구와 함께 %s 플랫폼의 혜택을 받아가세요!\n\n추천인 코드 : %s\n\nOTC마켓에 리스팅 되는 수많은 코인들을 에어드랍 받아보세요~\n%s',
  recommendTextWithReward: '%s님이 고객님에게 [%s 월렛 앱]을 추천하셨습니다! \n앱 가입시 %s %s코인을 드립니다.\n친구와 함께 %s 플랫폼의 혜택을 받아가세요!\n\n추천인 코드 : %s\n\nOTC마켓에 리스팅 되는 수많은 코인들을 에어드랍 받아보세요~\n%s',
  recommendText: '%s님이 고객님에게 [%s 월렛 앱]을 추천하셨습니다! \n친구와 함께 %s 플랫폼의 혜택을 받아가세요!\n\n추천인 코드 : %s\n\nOTC마켓에 리스팅 되는 수많은 코인들을 에어드랍 받아보세요~\n%s',
  recommendTextCopied: '추천문구가 복사되었습니다.', 

  staking: '스테이킹',
  stakingInterest: '스테이킹 이자',
  stakingInterestInterval: '매일',
  unstaking: '언스테이킹',
  stakingDescription1: '스테이킹 하는 경우 매일 %s%의 이자가 %s로 지급됩니다.',
  stakingDescription2: '스테이킹 하는 경우 %s일 동안 언스테이킹(출금)이 불가합니다.',
  stakingDescription3: '스테이킹을 해제합니다. 해제 한 수량에 대해서는 더이상 이자가 지급되지 않습니다.',
  stakingAvailableAmount: '가능수랑',
  stakingApplyAmount: '신청수랑',
  stakingInvalidAmount: '신청 가능한 수량을 입력해 주세요',
  stakingConfirm: '%s %s 코인을 스테이킹 하시겠습니까?',
  stakingReleaseConfirm: '%s %s 코인을 언스테이킹 하시겠습니까?',
  stakingResultSuccess: '스테이킹이 완료되었습니다.',
  stakingResultFail: '스테이킹에 실패하였습니다. 잔액이 부족하거나 스테이킹이 불가한 코인입니다.',
  stakingReleaseResultSuccess: '언스테이킹이 완료되었습니다.',
  stakingReleaseResultFail: '언스테이킹에 실패하였습니다. 잔액이 부족하거나 언스테이킹이 불가한 상태입니다.',
  stakingNoBalance: '스테이킹을 해제할 수 있는 수량이 없습니다.',

  exchangeStatus: '거래상태',
  search: '검색',

  // 추가사항
  termText: '지갑 생성을 위한 계정 등록, 보안 비밀번호 입력 등을 진행합니다. 시작하기 위하여 아래의 약관을 확인하고 동의해주시기 바랍니다.',
  differentPw: '비밀번호가 서로 다릅니다. 다시 확인해주세요.',
  idOk:'사용가능합니다.',
  findIdSucess:'아이디 찾기가 완료되었습니다.',
  inputEmail2:'이메일을 입력해주세요.',
};
