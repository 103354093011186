
import util from 'util';

class StringUtil {
    static trimFloatInput(val) {
        if (val == null || val == undefined || val.length == 0) return '';
        let flag = false, idx = 0;
        for (let i=0;i<val.length;i++) {
            let code = val.charCodeAt(i);
            if (i==0 && code==48) val = val.substring(1, val.length)
            if (code == 46) {
                if (flag) {
                    val = (i > 0 ? val.substring(0, i) : '') + (i < val.length-1 ? val.substring(i+1, val.length) : '')
                    i--;
                    continue;
                }
                else flag = true;
            }
            else if (code < 48 || code > 57) {
                val = (i > 0 ? val.substring(0, i) : '') + (i < val.length-1 ? val.substring(i+1, val.length) : '')
                i--;
                continue;
            }
        }
        if (val.length == 0) return '0';
        if (val.charCodeAt(0)==46) val='0'+val;
        return val;
    }
    static trimDecimalInput(val) {
        if (val == null || val == undefined || val.length == 0) return '';
        let flag = false, idx = 0;
        for (let i=0;i<val.length;i++) {
            let code = val.charCodeAt(i);
            if (i==0 && code==48) val = val.substring(1, val.length)
            else if (code < 48 || code > 57) {
                val = (i > 0 ? val.substring(0, i) : '') + (i < val.length-1 ? val.substring(i+1, val.length) : '')
                i--;
                continue;
            }
        }
        if (val.length == 0) return '0';
        return val;
    }
    static stringFormat = (value, params) => {
      if (params != null) params.forEach(param => {
        value = util.format(value, param);
      });
      return value;
    };
}
export default StringUtil;