import { Form, Input, Checkbox, Button, Modal } from 'antd';
import React from 'react';
import "../css/signUp.scss";
import "../css/main.scss";

import { httpGet, httpUrl, httpPost } from "../api/httpClient";
import crypto, { timingSafeEqual } from 'crypto';
import { connect } from "react-redux";
import { Route, withRouter } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { login, logout } from '../actions';
import { formatBalance } from "../util/coinUtil";
import  MyWallet from './MyWallet';
import  Currency from './Currency';
import  CoinMc from './CoinMc';
import  Transaction from './Transaction';
import  Event from './Event';
import Language from '../lib/util/language';
import Toast from '../lib/util/toast';
import { withAlert } from 'react-alert'
import Const from "../const";
import { reactLocalStorage } from "reactjs-localstorage";

const FormItem = Form.Item;


class MainPage extends React.Component {
    constructor(props) {
        super(props);
        this.string = Language.getString();
        this.state = {
            blockchains: [],
            balances: [],
            activeTab : 3,
        };
    }


    componentDidMount() {
        // this.requestSystemListBlockchain();
        // this.requestBalance();
    }

    // requestBalance = () => {
    //     console.log("##### Request balance")
    //     httpGet(httpUrl.balance, [0], {}).then((result) => {
    //         result = result.data;
    //         console.log(result)
    //         this.setState({ balances: result })
    //     }).catch(e => {
    //         console.log("## requestBalance error: " + e)
    //     });
    // }

    // requestSystemListBlockchain = () => {
    //     console.log("## Loading systemListBlockchain");
    //     httpGet(httpUrl.systemListBlockchain, [global.language], {}).then((result) => {
    //         result = result.data;
    //         console.log('## systemListBlockchain loaded');
    //         this.setState({ blockchains: result })
    //     }).catch((e) => {
    //         console.log('## systemListBlockchain load error: ' + e)
    //     });
    // }

    logout = () => {
        const string = this.string;
        const { logout, history } = this.props;
        Modal.confirm({
            title: string.myinfoLogout,
            content: string.myinfoLogoutConfirm,
            okText: string.ok,
            cancelText: string.cancel,
            onOk() {
                logout();
                reactLocalStorage.remove(Const.appCode + "#userinfo");
                reactLocalStorage.remove(Const.appCode + "#coinbalance");
                reactLocalStorage.remove(Const.appCode + "#coinstaking");
                
                history.push('/');
            },
            onCancel() {
            },
          });
    }

    state = {
        activeTab : 0
    }

    customAlert = () => {
        this.props.alert.show('준비중입니다..')
    }

    clickHandler = (id) => {
        this.setState({activeTab : id})
    }

    render() {
        console.log(this.props.userinfo)
        
        return (
            <div className="wrapperMain">
                <div className="mainContent">

                        {/* <div className="flag"></div>
                        <div className="country"></div> */}
                        <div className="mainLogo"></div>
                        <div className="logout" onClick={this.logout}></div>
                        {/* <div className="profile"></div> */}
                 
                    <div className="container" style={{ textAlign: 'center', paddingBottom: '30px' }}>
                        <ul className="tab">
                            <li className={this.state.activeTab == 0 ? 'selBox' : 'nonSelBox'} onClick={() => this.clickHandler(0)}>{this.string.menuMywallet}</li>
                            <li className={this.state.activeTab == 1 ? 'selBox' : 'nonSelBox'} onClick={() => this.clickHandler(1)}>{this.string.menuCurrency}</li>
                            <li className={this.state.activeTab == 2 ? 'selBox' : 'nonSelBox'} onClick={() => this.clickHandler(2)}>{this.string.menuExchange}</li>
                            <li className={this.state.activeTab == 3 ? 'selBox' : 'nonSelBox'} onClick={() => this.clickHandler(3)}>{this.string.menuEvent}</li>


                        {/* {this.state.activeTab == 0 && (
                            <li className="selBox" onClick={() => this.clickHandler(0)}>                            
                                <img className="tabText" src={require('../img/main/myWalletTextSel.png')}/>
                            </li>
                        ) || (
                            <li className="nonSelBox" onClick={() => this.clickHandler(0)}>   
                                <img className="tabText" src={require('../img/main/myWalletText.png')}/>
                            </li>    
                        )
                        }
                            <li className="nonSelBox" onClick={() => this.clickHandler(1)}>
                                <img className="tabText" src={require('../img/main/coinMcText.png')}/>
                            </li>

                        {this.state.activeTab == 2 && (
                            <li className="selBox" onClick={() => this.clickHandler(2)}>
                                <img className="tabText" src={require('../img/main/transactionTextSel.png')}/>
                            </li>
                        ) || (
                            <li className="nonSelBox" onClick={() => this.clickHandler(2)}>
                                <img className="tabText" src={require('../img/main/transactionText.png')}/>
                            </li>
                        )
                        }
                            <li className="nonSelBox" onClick={() => this.clickHandler(3)}>
                                <img className="tabText" src={require('../img/main/eventText.png')}/>
                            </li> */}
                        </ul>
                           
                        { this.state.activeTab == 0 && <MyWallet /> }
                        { this.state.activeTab == 1 && <Currency /> }
                        { this.state.activeTab == 2 && <Transaction customAlert={this.customAlert}/> }
                        { this.state.activeTab == 3 && <Event /> }
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => (
    bindActionCreators({ login, logout }, dispatch)
  );
export default withAlert()(withRouter(connect(null, mapDispatchToProps)(MainPage)));