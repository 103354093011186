import React, {Component} from 'react';
import "../../css/transaction.scss";
import "../../css/transaction_m.scss";

export class Modal extends Component {
    render() {
        // 열기, 닫기, 모달 헤더 텍스트를 부모로부터 받아옴
        const { open, close } = this.props;

        return (
            <div className={ open ? 'openModal modal': 'modal' }>
                { open ? (  
                    <section>
                        <div className="modalHeader">
                            <button className="exitBtn" onClick={close}></button>
                        </div>
                        <main>
                            {this.props.children}
                        </main>
                    </section>
                ) : null }
            </div>
        )
    }
}