import { httpGet, httpUrl, httpPost } from "../api/httpClient";
import { reactLocalStorage } from "reactjs-localstorage";

const formatBalance = (value, decimal, place) => {
    if (decimal > 9) decimal = 9;
    value = (value + "").replace(/,/g,'');
    let valuePre = value.length > decimal ?  value.substr(0, value.length - decimal).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
    let valuePost = "";
    if (value.length < decimal) {
        for (let i=0;i<decimal-value.length;i++) valuePost += "0";
        valuePost += value;
    }
    else {
        valuePost = value.substr(value.length - decimal, value.length);
    }
    if (valuePost.length > place) valuePost = valuePost.substr(0,place);

    return valuePre + (valuePost == "" ? "" : "." + valuePost)
};
export {
    formatBalance
};

// console.log("gomgom");

// update coin decimal
/* export const getCoinDecimal = (params = {}) => {
    return dispatch => {
      httpGet(httpUrl.getCoinDecimal, [params.language], {})
        .then(res => {
          reactLocalStorage.setObject("coinList", res.data);
          dispatch(coinStart(true));
          dispatch(coinSuccess(res.data));
          console.log("yes");
        })
        .catch(error => {
          console.log("no");
          dispatch(coinRejected(error));
        });
    };
  }; */